import React from "react"

import I18n from "common/services/i18n/I18n"

import "./captcha.less"

const Captcha: React.FunctionComponent = () => (
    <div
        className="captchaContainer paragraph--weak"
        dangerouslySetInnerHTML={{
            __html: I18n.getString("common.captcha.policy"),
        }}
    />
)

export default Captcha
