/* eslint typescript-sort-keys/string-enum: "error" */
enum IconList {
    disp_hide_sm = "disp_hide_sm",
    disp_look_sm = "disp_look_sm",
    disp_shrink_lg = "disp_shrink_lg",
    disp_sort_sm = "disp_sort_sm",
    func_basket_md = "func_basket_md",
    func_basket_sm = "func_basket_sm",
    func_camera_sm = "func_camera_sm",
    func_checkbox_checked_md = "func_checkbox_checked_md",
    func_checkbox_checked_sm = "func_checkbox_checked_sm",
    func_checkbox_indeterminate_md = "func_checkbox_indeterminate_md",
    func_checkbox_indeterminate_sm = "func_checkbox_indeterminate_sm",
    func_checkbox_unchecked_md = "func_checkbox_unchecked_md",
    func_checkbox_unchecked_sm = "func_checkbox_unchecked_sm",
    func_clear_search_sm = "func_clear_search_sm",
    func_close_md = "func_close_md",
    func_close_sm = "func_close_sm",
    func_contact_us_lg = "func_contact_us_lg",
    func_copy_lg = "func_copy_lg",
    func_download_md = "func_download_md",
    func_download_sm = "func_download_sm",
    func_edit_md = "func_edit_md",
    func_edit_sm = "func_edit_sm",
    func_erase_md = "func_erase_md",
    func_forbidden_sm = "func_forbidden_sm",
    func_hang_up_lg = "func_hang_up_lg",
    func_history_sm = "func_history_sm",
    func_home_lg = "func_home_lg",
    func_home_sm = "func_home_sm",
    func_lock_lg = "func_lock_lg",
    func_lock_md = "func_lock_md",
    func_lock_sm = "func_lock_sm",
    func_open_lg = "func_open_lg",
    func_print_sm = "func_print_sm",
    func_qr_lg = "func_qr_lg",
    func_qr_sm = "func_qr_sm",
    func_radio_not_selected_md = "func_radio_not_selected_md",
    func_radio_selected_md = "func_radio_selected_md",
    func_reply_sm = "func_reply_sm",
    func_rotate_left_sm = "func_rotate_left_sm",
    func_rotate_right_sm = "func_rotate_right_sm",
    func_select_sm = "func_select_sm",
    func_send_sm = "func_send_sm",
    func_settings_lg = "func_settings_lg",
    func_share_lg = "func_share_lg",
    func_share_md = "func_share_md",
    func_share_sm = "func_share_sm",
    func_toggle_false_md = "func_toggle_false_md",
    func_toggle_true_md = "func_toggle_true_md",
    func_trash_md = "func_trash_md",
    func_trash_sm = "func_trash_sm",
    func_video_chat_md = "func_video_chat_md",
    func_video_chat_sm = "func_video_chat_sm",
    info_email_lg = "info_email_lg",
    info_email_sm = "info_email_sm",
    info_embed_lg = "info_embed_lg",
    info_error_lg = "info_error_lg",
    info_error_sm = "info_error_sm",
    info_everybody_sm = "info_everybody_sm",
    info_gmail_sm = "info_gmail_sm",
    info_group_lg = "info_group_lg",
    info_group_sm = "info_group_sm",
    info_help_lg = "info_help_lg",
    info_info_lg = "info_info_lg",
    info_info_md = "info_info_md",
    info_info_sm = "info_info_sm",
    info_list_sm = "info_list_sm",
    info_loader_lg = "info_loader_lg",
    info_loader_md = "info_loader_md",
    info_loader_sm = "info_loader_sm",
    info_media_alias_sm = "info_media_alias_sm",
    info_media_animation_sm = "info_media_animation_sm",
    info_media_audio_sm = "info_media_audio_sm",
    info_media_document_sm = "info_media_document_sm",
    info_media_file_sm = "info_media_file_sm",
    info_media_image_sm = "info_media_image_sm",
    info_media_link_sm = "info_media_link_sm",
    info_media_sm = "info_media_sm",
    info_media_video_sm = "info_media_video_sm",
    info_microsoft_sm = "info_microsoft_sm",
    info_nfc_lg = "info_nfc_lg",
    info_nfc_sm = "info_nfc_sm",
    info_presentation_sm = "info_presentation_sm",
    info_social_facebook_lg = "info_social_facebook_lg",
    info_social_facebook_sm = "info_social_facebook_sm",
    info_social_linkedin_lg = "info_social_linkedin_lg",
    info_social_linkedin_sm = "info_social_linkedin_sm",
    info_social_twitter_lg = "info_social_twitter_lg",
    info_social_twitter_sm = "info_social_twitter_sm",
    info_social_whatsapp_lg = "info_social_whatsapp_lg",
    info_social_whatsapp_sm = "info_social_whatsapp_sm",
    info_spreadsheet_sm = "info_spreadsheet_sm",
    info_statistics_lg = "info_statistics_lg",
    info_success_lg = "info_success_lg",
    info_success_md = "info_success_md",
    info_success_sm = "info_success_sm",
    info_text_document_sm = "info_text_document_sm",
    info_text_message_lg = "info_text_message_lg",
    info_text_message_sm = "info_text_message_sm",
    info_url_lg = "info_url_lg",
    info_url_sm = "info_url_sm",
    info_user_sm = "info_user_sm",
    info_warning_lg = "info_warning_lg",
    info_warning_sm = "info_warning_sm",
    navi_avatar_lg = "navi_avatar_lg",
    navi_avatar_sm = "navi_avatar_sm",
    navi_back_sm = "navi_back_sm",
    navi_down_sm = "navi_down_sm",
    navi_drag_sm = "navi_drag_sm",
    navi_folder_lg = "navi_folder_lg",
    navi_folder_private_sm = "navi_folder_private_sm",
    navi_folder_sm = "navi_folder_sm",
    navi_minus_sm = "navi_minus_sm",
    navi_more_md = "navi_more_md",
    navi_more_sm = "navi_more_sm",
    navi_next_sm = "navi_next_sm",
    navi_plus_sm = "navi_plus_sm",
    navi_previous_lg = "navi_previous_lg",
    navi_previous_sm = "navi_previous_sm",
    navi_search_sm = "navi_search_sm",
    navi_sidebar_close_sm = "navi_sidebar_close_sm",
    navi_up_sm = "navi_up_sm",
}

export default IconList
