class CaptchaFacade {
    constructor() {
        this.resolveInitialized = null
        this.initializationFailed = false

        this.initialized = new Promise((resolve, reject) => {
            this.resolveInitialized = resolve
            this.rejectInitialized = reject
        })
    }

    // do not wait captcha initialize during app bootstrap to save at least 500ms of total loading time
    // this can be done asynchronously https://developers.google.com/recaptcha/docs/loading?hl=en
    // check time between recaptcha/api.js request and recaptcha/api2/reload
    // grecaptcha.execute() resolve after this last request.
    initialize(publicKey) {
        this.publicKey = publicKey
        window.captchaCallback = () => {
            window.grecaptcha.execute(publicKey, { action: "homepage" }).then(
                () => {
                    this.resolveInitialized()
                },
                () => {
                    this.initializationFailed = true
                    this.rejectInitialized()
                },
            )
        }

        this.loadFile(
            `https://www.google.com/recaptcha/api.js?onload=captchaCallback&render=${publicKey}`,
            "script",
            null,
            false,
        )
    }

    loadFile(url) {
        const htmlEl = document.createElement("script")
        htmlEl.setAttribute("src", url)
        document.head.appendChild(htmlEl)
    }

    execute(actionName = "") {
        if (this.initializationFailed) {
            return Promise.reject("Captcha failed to intitialized")
        } else {
            return this.initialized.then(() => {
                return new Promise((resolve, reject) => {
                    window.grecaptcha
                        .execute(this.publicKey, { action: actionName })
                        .then(resolve, reject)
                })
            })
        }
    }
}

export default new CaptchaFacade()
