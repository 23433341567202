import React, { useRef, useState } from "react"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"
import Captcha from "common/components/formV2/other/captcha/Captcha"
import PanelsFooter from "common/components/modal/panels/components/footer/PanelsFooter"
import I18n from "common/services/i18n/I18n"
import { classNames } from "common/utils/JSX"

import "./form.less"

interface IFormProps {
    onSubmit?(): void
    withSubmitButton?: boolean
    submitButtonLabel?: string
    submitButtonIconId?: string
    submitButtonDisabled?: boolean
    secondarySubmitButtonLabel?: string
    secondarySubmitButtonDisabled?: boolean
    onSecondaryButton?(): void
    showCaptcha?: boolean
    withPanelFooter?: boolean
    hasCustomErrors?: boolean
    forceShowError?: boolean
    submitLoading?: boolean
    submitButtonTag?: BUTTON_TAG
}

const Form: React.FunctionComponent<IFormProps> = ({
    withSubmitButton,
    submitButtonLabel,
    submitButtonIconId,
    submitButtonDisabled,
    onSubmit,
    showCaptcha,
    children,
    onSecondaryButton,
    secondarySubmitButtonLabel,
    secondarySubmitButtonDisabled,
    withPanelFooter,
    hasCustomErrors,
    forceShowError,
    submitButtonTag,
    submitLoading,
}) => {
    const formRef = useRef<HTMLFormElement>(null)
    const [showError, setShowError] = useState(!!forceShowError)
    const submit = (e) => {
        e.preventDefault()

        if (hasCustomErrors) return

        return onSubmit?.()
    }

    const clickOnMainButton = () => setShowError(true)

    return (
        <form
            ref={formRef}
            {...classNames("commonForm", { "commonForm--showError": showError })}
            onSubmit={submit}
        >
            {children}

            {showCaptcha && <Captcha />}

            {withPanelFooter ? (
                <PanelsFooter
                    main={{
                        dataTestId: "commonSubmitButton",
                        label: submitButtonLabel,
                        isSubmit: true,
                        onClick: clickOnMainButton,
                        disabled: submitButtonDisabled,
                        iconId: submitButtonIconId,
                        tag: submitButtonTag,
                        loading: submitLoading,
                    }}
                    secondary={
                        onSecondaryButton
                            ? {
                                  label: secondarySubmitButtonLabel,
                                  onClick: onSecondaryButton,
                                  disabled: secondarySubmitButtonDisabled,
                              }
                            : undefined
                    }
                />
            ) : (
                <div
                    {...classNames("commonForm__buttons", {
                        "commonForm__buttons--hidden": !withSubmitButton && !onSecondaryButton,
                    })}
                >
                    {onSecondaryButton && (
                        <Button
                            label={secondarySubmitButtonLabel}
                            onClick={onSecondaryButton}
                            actionType={ACTION_TYPE.secondary}
                            disabled={secondarySubmitButtonDisabled}
                        />
                    )}
                    <div
                        {...classNames("commonForm__button commonForm__submitButton", {
                            "commonForm__submitButton--hidden": !withSubmitButton,
                        })}
                    >
                        <Button
                            label={submitButtonLabel ?? I18n.getString("common.global.validate")}
                            actionType={ACTION_TYPE.primary}
                            tabIndex={!withSubmitButton ? -1 : undefined}
                            isSubmit
                            onClick={clickOnMainButton}
                            disabled={submitButtonDisabled}
                            iconId={submitButtonIconId}
                            loading={submitLoading}
                            tag={submitButtonTag ?? BUTTON_TAG.success}
                        />
                    </div>
                </div>
            )}
        </form>
    )
}

export default Form
