import React from "react"

import { classNames } from "common/utils/JSX"

import "./SvgLoader.less"

const SVGLoader: React.FunctionComponent<{ className?: string }> = ({ className = "" }) => {
    return (
        <div {...classNames("common-svg-loader-container", className)} data-testid="loader">
            <svg className="spinner" viewBox="0 0 100 100">
                <circle className="spinner__background" cx="50" cy="50" r="35" />
                <path
                    className="spinner__foreground"
                    d="M50,15 C30.6700338,15 15,30.6700338 15,50"
                />
            </svg>
        </div>
    )
}

export default SVGLoader
