import React, { useEffect } from "react"
import _ from "lodash"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { IExistingSelfManagedHub, ILibraryThemeInfo } from "@/services/api/PortalAPI"
import { I18n } from "@/services/i18n/I18n"
import IconList from "@/styles/IconList"

import ActionCard from "common/components/actionCard/ActionCard"
import Button, { ACTION_TYPE } from "common/components/button/Button"

import "./hubsListCard.less"

interface IHubsListCardProps {
    hubs: IExistingSelfManagedHub[]
    goToHubCreation(): void
}

const HubThumbnail: React.FunctionComponent<ILibraryThemeInfo> = ({ primaryColor, logoUrl }) => (
    <div className="hubsListCard__hubThumbnail" style={{ backgroundColor: primaryColor }}>
        <div
            className="hubsListCard__hubThumbnailImg"
            style={{ backgroundImage: `url(${logoUrl})` }}
        />
    </div>
)

const HubsListCard: React.FunctionComponent<IHubsListCardProps> = ({ hubs, goToHubCreation }) => {
    useEffect(() => {
        hubs.length === 1 && window.location.replace(hubs[0].libraryUrl)
    }, [])

    return hubs.length > 1 ? (
        <PortalCard mainTitle={I18n.getString("card.hubsList.title")} isMaxWidth>
            <div className="hubsListCard">
                <div className="hubsListCard__hubsList">
                    {_.map(hubs, (hub) => (
                        <ActionCard
                            className="hubsListCard__hubItem"
                            key={hub.libraryId}
                            label={hub.libraryLabel}
                            iconId={IconList.navi_next_sm}
                            onClick={() => window.open(hub.libraryUrl, "_blank")}
                            thumbnail={<HubThumbnail {...hub.libraryThemeInfo} />}
                        />
                    ))}
                </div>

                <span className="hubsListCard__separatorText label--normal">
                    {I18n.getString("card.hubsList.separatorText")}
                </span>

                <Button
                    label={I18n.getString("card.hubsList.button")}
                    actionType={ACTION_TYPE.secondary}
                    onClick={goToHubCreation}
                />
            </div>
        </PortalCard>
    ) : null
}

export default HubsListCard
