import React from "react"

import { IAccessibleAttributes } from "common/components/menu/components/menuItem/MenuItemTypes"
import { classNames } from "common/utils/JSX"

import "./menuItem.less"

interface IMenuItemProps {
    className?: string
    onClick?(e?: React.MouseEvent): void
    highlighted?: boolean
    dataTestId?: string
    onMouseEnter?: React.MouseEventHandler
    onMouseLeave?: React.MouseEventHandler
    withHover?: boolean
    accessibleAttributes?: IAccessibleAttributes
    disabled?: boolean
}

const MenuItem: React.FunctionComponent<IMenuItemProps> = ({
    children,
    className,
    onClick,
    highlighted,
    dataTestId,
    onMouseEnter,
    onMouseLeave,
    withHover,
    accessibleAttributes,
    disabled,
}) => (
    <div
        data-testid={dataTestId}
        {...classNames("menuItem", className, {
            "menuItem--withHover": withHover,
            "menuItem--highlighted": highlighted,
            "menuItem--disabled": disabled,
        })}
        onClick={(e) => !disabled && onClick?.(e)}
        onKeyDown={(e) => {
            if (
                accessibleAttributes &&
                (e.key === "Enter" || e.key === " ") &&
                onClick &&
                !disabled
            ) {
                e.preventDefault()
                onClick()
            }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...accessibleAttributes}
    >
        {children}
    </div>
)

export default MenuItem
