import _ from "lodash"

import StandalonePageRenderer from "common/components/standalonePage/StandalonePageRenderer"
import {
    ERROR_CODES,
    IErrorPageRendererProps,
} from "common/screens/errorPage/ErrorPageRendererTypes"
import I18n from "common/services/i18n/I18n"

const DEFAULT_I18N = {
    pageName: "Ubstream",
    title: "Internal error",
    message: "An error has occurred on our side.\nPlease try again later",
    button: "Try again",
}

export default {
    render: (container: HTMLElement, options?: IErrorPageRendererProps) => {
        const {
            code: requestedCode = undefined,
            $diagnoses = [],
            login = () => {},
            logout = () => {},
            goBack = () => {},
            disableButtons = false,
        } = options || {}

        const code =
            requestedCode && _.includes(_.values(ERROR_CODES), requestedCode)
                ? requestedCode
                : ERROR_CODES.INTERNAL_ERROR

        const getString = (key: string, withoutCode?: boolean): string => {
            const commonKey = `common.errorPage${withoutCode ? "" : `.${code}`}.${key}`
            const string = I18n.isLoaded() ? I18n.getString(commonKey) : DEFAULT_I18N[key]
            return string.replace(/\n/g, "<br>")
        }

        const $diagnose = _.findLast(
            $diagnoses,
            ($diagnose) => !!$diagnose.$title && !!$diagnose.$message,
        )

        const pageName = getString("pageName", true)
        const title = $diagnose ? $diagnose.$title : getString("title")
        const message = $diagnose?.$message
            ? $diagnose.$message.replace(/\. /g, ".<br>")
            : getString("message")
        const button = getString("button")

        const errorCallbacks = {
            [ERROR_CODES.ACCESS_DENIED]: logout,
            [ERROR_CODES.NOT_FOUND]: goBack,
            [ERROR_CODES.REDIRECTION_ERROR]: goBack,
            [ERROR_CODES.INTERNAL_ERROR]: () => window.location.reload(),
            [ERROR_CODES.BLOCKED_HUB_NO_CONNECTED]: login,
            [ERROR_CODES.BLOCKED_HUB_CONNECTED]: logout,
        }

        return StandalonePageRenderer.render(container, {
            pageName,
            title,
            message,
            button: disableButtons
                ? undefined
                : {
                      label: button,
                      onClick: errorCallbacks[code],
                  },
        })
    },
}
