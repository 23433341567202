import * as Hash from "query-string"

export default {
    read(key) {
        return Hash.parse(window.location.hash)[key]
    },
    write(key, value) {
        if (_.isUndefined(value)) {
            const hash = Hash.parse(window.location.hash)
            delete hash[key]

            window.location.hash = `#${Hash.stringify(hash)}`
        } else {
            const hash = Hash.parse(window.location.hash)
            hash[key] = value

            window.location.hash = `#${Hash.stringify(hash, { encode: false })}`
        }
    },
    clear() {
        window.location.hash = ""
    },
}
