import React from "react"

import {
    IContextMenuContent,
    IContextMenuItem,
    IContextMenuOnActionFunc,
} from "common/components/contextMenu/ContextMenuTypes"
import SubMenu from "common/components/contextMenu/SubMenu"
import MenuTitle from "common/components/menu/components/menuTitle/MenuTitle"
import { BasicMenuItem, MenuSeparator } from "common/components/menu/Menu"

const lengthSubMenu = (item: IContextMenuItem): number => {
    if (!item || !item.subMenu || (item.subMenu && item.subMenu.length === 0)) {
        return 0
    }

    let sizeSubMenu = 0
    item.subMenu.forEach((subMenuItem) => {
        if (subMenuItem && subMenuItem.action) {
            if (subMenuItem.subMenu && subMenuItem.subMenu.length > 0) {
                sizeSubMenu += lengthSubMenu(subMenuItem)
            } else {
                sizeSubMenu += 1
            }
        }
    })

    return sizeSubMenu
}

export const transformItemToJsx = (
    item: IContextMenuItem,
    onAction: IContextMenuOnActionFunc,
    index: number,
): React.ReactNode => {
    if (item === null) {
        return <MenuSeparator key={`separator_${index}`} />
    }

    const { action, subMenu, title } = item

    if (title) {
        return <MenuTitle key={title} title={title} />
    }

    const sizeSubMenu = lengthSubMenu(item)
    if ((subMenu && sizeSubMenu === 0) || !action) {
        return null
    }

    if (subMenu) {
        if (sizeSubMenu > 1 || action.allowSingleSubMenu) {
            return (
                <SubMenu
                    key={`${action.id}_${index}`}
                    iconId={action.icons?.md ?? action.icon}
                    label={action.label}
                    id={`${action.id}`}
                    disabled={action.disabled}
                >
                    {subMenu.map((subItem, i) => transformItemToJsx(subItem, onAction, i))}
                </SubMenu>
            )
        }
        const aloneSubMenu = subMenu.find((subItem) => subItem && subItem.action)
        return aloneSubMenu && aloneSubMenu.action ? (
            <BasicMenuItem
                key={`${aloneSubMenu.action.id}_${index}`}
                label={aloneSubMenu.action.label}
                mainIcon={aloneSubMenu.action.icons?.md ?? aloneSubMenu.action.icon}
                rightElements={aloneSubMenu.action.rightElements}
                onClick={() => onAction(aloneSubMenu.action!.id)}
                disabled={aloneSubMenu.action.disabled}
            />
        ) : null
    }

    return action.render ? (
        action.render()
    ) : (
        <BasicMenuItem
            {...action}
            key={`${action.id}_${index}`}
            mainIcon={action.icons?.md ?? action.icon}
            rightElements={action.rightElements}
            onClick={() => (action.onClick ? action.onClick() : onAction(action.id))}
            button={action.button}
        />
    )
}

export const transformContentToJsx = ({ items, onAction }: IContextMenuContent): React.ReactNode =>
    items.map((item, index) => transformItemToJsx(item, onAction, index))
