import React, { useEffect } from "react"

const preventScroll = (container: Element, e: WheelEvent | React.WheelEvent) => {
    const maxScroll = container.scrollHeight - container.clientHeight
    if (
        maxScroll > 0 &&
        ((container.scrollTop === maxScroll && e.deltaY > 0) ||
            (container.scrollTop === 0 && e.deltaY < 0))
    ) {
        e.preventDefault()
    }
}

export const usePreventGlobalScroll = (selector: string | undefined) => {
    useEffect(() => {
        const container = selector ? document.querySelector(selector) : null
        if (container) {
            const handler = (e: WheelEvent) => preventScroll(container, e)
            container.addEventListener("wheel", handler)
            return () => container.removeEventListener("wheel", handler)
        }
    }, [selector])
}
