import React from "react"

import ContextMenu, { IContextMenuProps } from "common/components/contextMenu/ContextMenu"

const ContextMenuContainer: React.ForwardRefRenderFunction<
    ContextMenu,
    React.PropsWithChildren<IContextMenuProps>
> = (props, ref) => <ContextMenu ref={ref} {...props} />

export default React.forwardRef(ContextMenuContainer)
