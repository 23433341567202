import React, { useRef } from "react"
import _ from "lodash"

import Badge, { BADGE_TYPES } from "common/components/badge/Badge"
import Button, { ACTION_TYPE } from "common/components/button/Button"
import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import {
    IAccessibleAttributes,
    IMenuItemButton,
    IRightElement,
} from "common/components/menu/components/menuItem/MenuItemTypes"
import { classNames } from "common/utils/JSX"

import MenuItem from "./MenuItem"

export interface IRightElementProps {
    type?: BADGE_TYPES
    text?: string
    iconId?: string
}

export interface IBasicMenuItemProps {
    label?: string | React.ReactElement
    labelIcon?: string
    button?: IMenuItemButton
    mainIcon?: string | React.ReactElement
    rightElements?: IRightElement[]
    className?: string
    onClick?(targetIsSecondaryIcon: boolean): void
    highlighted?: boolean
    selected?: boolean
    dataTestId?: string
    onMouseEnter?: React.MouseEventHandler
    onMouseLeave?: React.MouseEventHandler
    disabled?: boolean
    notClickable?: boolean
    dot?: boolean
    accessibleAttributes?: IAccessibleAttributes
}

export const RightBadge: React.FunctionComponent<IRightElementProps> = ({ type, text }) =>
    type && text ? <Badge className="menuItem__badge" type={type} text={text} /> : null

export const RightIcon: React.FunctionComponent<IRightElementProps> = ({ iconId }) =>
    iconId ? (
        <div className="menuItem__secondaryIconContainer">
            <Icon id={iconId} iconClassName="menuItem__icon" />
        </div>
    ) : null

export const RightText: React.FunctionComponent<IRightElementProps> = ({ text }) =>
    text ? <div className="menuItem__rightText label--normal">{text}</div> : null

const _showIcon = (icon: string | React.ReactElement) =>
    typeof icon === "string" ? <Icon id={icon} iconClassName="menuItem__icon" /> : icon

const BasicMenuItem: React.FunctionComponent<IBasicMenuItemProps> = ({
    label,
    labelIcon,
    mainIcon, // (24px) can be an id or a React component / DOM element
    rightElements,
    className,
    onClick,
    highlighted,
    selected,
    dataTestId,
    onMouseEnter,
    onMouseLeave,
    disabled,
    notClickable,
    dot,
    accessibleAttributes,
    button,
}) => {
    const rightElementsRef = useRef<HTMLDivElement>(null)
    const onMenuItemClick = (e?: React.MouseEvent) => {
        if (!onClick) {
            return
        }

        const target = e?.nativeEvent.target as HTMLElement | undefined
        if (
            target &&
            rightElementsRef.current &&
            (target === rightElementsRef.current || rightElementsRef.current.contains(target))
        ) {
            onClick(true)
        } else {
            onClick(false)
        }
    }

    const hasRightElements = rightElements && rightElements.length > 0

    return (
        <MenuItem
            {...{
                className,
                highlighted,
                dataTestId,
                onClick: !notClickable && !disabled ? onMenuItemClick : undefined,
                onMouseEnter,
                onMouseLeave,
                withHover: !notClickable && !disabled,
                accessibleAttributes: _.assign(
                    {
                        role: "button",
                        tabIndex: 0,
                        "aria-pressed": highlighted,
                    },
                    accessibleAttributes,
                ),
            }}
            {...classNames(className, {
                "menuItem--disabled": disabled,
                "menuItem--notClickable": notClickable,
            })}
        >
            {mainIcon && (
                <div className="menuItem__mainIconContainer">
                    {_showIcon(mainIcon)}
                    {dot && <div className="menuItem__iconDot" />}
                </div>
            )}

            {label && (
                <div className="menuItem__labelContainer">
                    <div className="menuItem__labelWrapper">
                        <span className="menuItem__label label--normal">{label}</span>
                        {labelIcon && (
                            <div className="menuItem__labelIcon">
                                <Icon id={labelIcon} />
                            </div>
                        )}
                    </div>
                    {disabled && button && (
                        <div className="menuItem__buttonContainer">
                            {
                                <Button
                                    iconId={button.iconId}
                                    ariaAttributes={button.ariaAttributes}
                                    onClick={button.onClick}
                                    actionType={ACTION_TYPE.third}
                                />
                            }
                        </div>
                    )}
                </div>
            )}

            {(selected || hasRightElements) && (
                <div ref={rightElementsRef} className="menuItem__rightElementsContainer">
                    {selected && <RightIcon iconId={IconList.func_select_sm} />}
                    {!selected &&
                        hasRightElements &&
                        rightElements.map(({ component: Component, props }, index) => (
                            <Component key={`${Component.name}${index}`} {...props} />
                        ))}
                </div>
            )}
        </MenuItem>
    )
}

export default BasicMenuItem
