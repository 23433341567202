import React from "react"

import { I18n } from "@/services/i18n/I18n"

import "./goToAlreadySubscribeOffer.less"

interface IGoToAlreadySubscribeOfferProps {
    urlAlreadySubscribeOffer: string
}

const GoToAlreadySubscribeOffer: React.FunctionComponent<IGoToAlreadySubscribeOfferProps> = ({
    urlAlreadySubscribeOffer,
}) => (
    <div className={"alreadySubscribeOffer"}>
        <p className={"alreadySubscribeOffer__info"}>
            {I18n.getString("card.offers.alreadySubscribeOffer")}
        </p>
        <a className={"alreadySubscribeOffer__link"} href={urlAlreadySubscribeOffer}>
            {I18n.getString("global.goToMyHub")}
        </a>
    </div>
)

export default GoToAlreadySubscribeOffer
