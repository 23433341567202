import React from "react"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { IStepperProps } from "@/components/stepper/Stepper"
import { AbstractCard } from "@/screens/cards/AbstractCard"
import { I18n } from "@/services/i18n/I18n"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"

import "./successCard.less"

interface ICreateHubCardProps {
    stepperProps: IStepperProps
    hubUrl: string
    goToHub: () => void
}

export class SuccessCard extends AbstractCard<ICreateHubCardProps, {}> {
    static get title(): string {
        return I18n.getString("card.successHub.stepper.title")
    }

    render() {
        const { stepperProps, hubUrl, goToHub } = this.props

        return (
            <PortalCard stepperProps={stepperProps} isMaxWidth>
                <div className="successCard">
                    <div className="successCard__title">
                        <h2 className="title--strong">{I18n.getString("card.successHub.title")}</h2>
                        <p className="successCard__subTitle subtitle--weak">
                            {I18n.getString("card.successHub.subTitle")}
                        </p>
                    </div>

                    <div className="successCard__content">
                        <p className="paragraph--weak">
                            {I18n.getString("card.successHub.availableAt")}
                        </p>
                        <p className="successCard__hubUrl label--strongest">{hubUrl}</p>
                    </div>

                    <div className="successCard__footer">
                        <p className="successCard__buttonLabel paragraph--weak">
                            {I18n.getString("card.successHub.addMedias")}
                        </p>
                        <Button
                            onClick={goToHub}
                            label={I18n.getString("global.goToMyHub")}
                            actionType={ACTION_TYPE.primary}
                            tag={BUTTON_TAG.success}
                            tabIndex={2}
                        />
                    </div>
                </div>
            </PortalCard>
        )
    }
}
