import React, { useEffect, useRef, useState } from "react"

export const useIsMountedState = (): boolean => {
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])

    return mounted
}

export const useIsUnmountedRef = (): React.MutableRefObject<boolean> => {
    const unmountedRef = useRef(false)

    useEffect(() => {
        return () => {
            unmountedRef.current = true
        }
    }, [])

    return unmountedRef
}

export const useDelayedEffect = (
    delayedAction: () => void,
    delayMs: number,
    dependencies?: React.DependencyList,
    runningCondition: boolean = true,
) => {
    useEffect(() => {
        if (runningCondition) {
            const t = window.setTimeout(delayedAction, delayMs)
            return () => window.clearTimeout(t)
        }
    }, dependencies)
}
