import _ from "lodash"

import { IModalProperties } from "common/components/modal/ModalTypes"
import { IUserProfileCooked } from "common/data/userProfile/UserProfileTypes"
import { ICommonState } from "common/state/store/DefaultState"
import { IAction } from "common/state/StoreTypes"

export const reduceShowModal = (
    state: ICommonState,
    { modalType, modalProps, modalOptions }: IAction<IModalProperties>,
): ICommonState => {
    const modalParams = [{ id: _.uniqueId(), modalType, modalProps, modalOptions }]
    const modals = modalOptions.replaceActiveModal
        ? _.concat(_.dropRight(state.modals), modalParams)
        : _.concat(state.modals, modalParams)
    return {
        ...state,
        modals: modals,
        coverLayers: modalOptions.replaceActiveModal
            ? Math.max(state.coverLayers, 1)
            : state.coverLayers + 1,
    }
}

export const reduceCloseActiveModal = (
    state: ICommonState,
    { modalId }: IAction<{ modalId: string }>,
): ICommonState => {
    const modals = state.modals.map((modal, index) => {
        if (modal.id === modalId) {
            return _.assign({}, modal, {
                isClosing: true,
            })
        }

        return modal
    })
    return {
        ...state,
        modals,
    }
}

export const reduceRemoveModal = (
    state: ICommonState,
    { modalId }: IAction<{ modalId: string }>,
): ICommonState => {
    const modals = state.modals.filter((m) => m.id !== modalId)
    const coverLayers = Math.max(state.coverLayers - 1, 0)
    return {
        ...state,
        modals: modals,
        coverLayers: coverLayers,
    }
}

export const reduceShowAsyncImportLoader = (
    state: ICommonState,
    _action: IAction,
): ICommonState => {
    return {
        ...state,
        asyncImportLoader: true,
    }
}

export const reduceHideAsyncImportLoader = (
    state: ICommonState,
    _action: IAction,
): ICommonState => {
    return {
        ...state,
        asyncImportLoader: false,
    }
}

export const reduceShowCover = (state: ICommonState, action: { show: boolean }): ICommonState => {
    const coverLayers = action.show ? state.coverLayers + 1 : Math.max(state.coverLayers - 1, 0)
    return {
        ...state,
        coverLayers: coverLayers,
    }
}

export const reduceUpdateActiveModal = (
    state: ICommonState,
    { newProps }: IAction<{ newProps: object }>,
): ICommonState => {
    const modalsLastIndex = state.modals.length - 1
    const modals = _.map(state.modals, (modal, index) => {
        if (index === modalsLastIndex) {
            return _.assign({}, modal, { modalProps: newProps })
        }

        return modal
    })
    return {
        ...state,
        modals,
    }
}

export const reduceSetUserProfile = (
    state: ICommonState,
    { userProfile }: IAction<{ userProfile: IUserProfileCooked }>,
): ICommonState => ({
    ...state,
    userProfile,
})
