import React, { useLayoutEffect, useState } from "react"
import { connect } from "react-redux"

import ProtectedImg from "common/components/protectedImg/ProtectedImg"
import ProtectedImgCache from "common/components/protectedImg/ProtectedImgCache"
import EngineFacade from "common/services/engine/EngineFacade"
import I18n from "common/services/i18n/I18n"
import { ICommonState } from "common/state/store/DefaultState"
import { SYSTEM_USER_IDS } from "common/types/AuthzTypes"
import { classNames, preventDrag } from "common/utils/JSX"
import Url from "common/utils/Url"

import "./profilePicture.less"

export enum PROFILE_PICTURE_SIZE {
    sm = "sm",
    md = "md",
    mg = "mg",
    lg = "lg",
    xl = "xl",
}

export interface IProfilePictureOwnProps {
    userId?: string
    customSrc?: string
    size?: PROFILE_PICTURE_SIZE
    withBorder?: true
    pictureId?: string
}

export interface IProfilePictureInjectedProps {
    currentUserProfilePictureSrc?: string
}

export type IProfilePictureProps = IProfilePictureOwnProps & IProfilePictureInjectedProps

// should not be used if the image src if built from an userId because the url is always the same
const imageCache = new ProtectedImgCache()

export const getImageSrc = ({
    userId,
    customSrc,
    currentUserProfilePictureSrc,
    pictureId,
}: IProfilePictureProps): string => {
    const imageSrc = userId
        ? Url.join(
              EngineFacade.rootUserProfileUrl || EngineFacade.rootLibraryApiUrl,
              "userprofile",
              userId,
              "picture",
              pictureId ? pictureId : "",
          )
        : customSrc || currentUserProfilePictureSrc || ""

    if (currentUserProfilePictureSrc && currentUserProfilePictureSrc.indexOf(imageSrc) === 0) {
        return currentUserProfilePictureSrc
    } else {
        return imageSrc
    }
}

const ProfilePicture: React.FunctionComponent<IProfilePictureProps> = ({
    userId,
    currentUserProfilePictureSrc,
    customSrc,
    size = PROFILE_PICTURE_SIZE.md,
    withBorder = false,
    pictureId,
}) => {
    const imageSrc = getImageSrc({ userId, customSrc, currentUserProfilePictureSrc, pictureId })
    const [imageLoaded, setImageLoaded] = useState(false)

    useLayoutEffect(() => {
        setImageLoaded(false)
    }, [userId, customSrc, currentUserProfilePictureSrc])

    if (!imageSrc) {
        return null
    }

    const preventProfilePictureRequest = userId && SYSTEM_USER_IDS.includes(userId)

    return (
        <div
            {...classNames("profilePicture", `profilePicture--${size}`, {
                "profilePicture--withBorder": withBorder,
            })}
            {...(preventDrag as any)}
        >
            {(!imageLoaded || preventProfilePictureRequest) && (
                <img
                    className="profilePicture__image"
                    src={Url.join(EngineFacade.baseEngineUrl, "assets/misc/profile-picture.svg")}
                    alt={I18n.getString("common.profilePicture.alt")}
                />
            )}
            {!preventProfilePictureRequest && (
                <ProtectedImg
                    className="profilePicture__image"
                    src={imageSrc}
                    alt={I18n.getString("common.profilePicture.alt")}
                    options={{ cache: !userId ? imageCache : undefined }}
                    callback={() => setImageLoaded(true)}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state: ICommonState): IProfilePictureInjectedProps => ({
    currentUserProfilePictureSrc: state.userProfile?.pictureUrl,
})

export default connect(mapStateToProps)(ProfilePicture)
