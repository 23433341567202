import { CommonI18n, ILoadFileCallback } from "common/services/i18n/I18n"

class I18n extends CommonI18n {
    public load(requestLang: string): Promise<string> {
        const getFileCallbackForCommon: ILoadFileCallback = async (locale) => {
            return (await import(`common/services/i18n/langs/common_${locale}.json`)).default
        }

        const getProjectFileCallback = async (locale) => {
            return (await import(/* webpackChunkName: "i18n/[request]" */ `./langs/${locale}.json`))
                .default
        }

        return super.load(requestLang, getFileCallbackForCommon, getProjectFileCallback)
    }
}

const I18nSingleton = new I18n()

export { I18nSingleton as I18n }
