import React from "react"
import { connect } from "react-redux"

import { UserCard } from "@/screens/cards/userCard/UserCard"

import { IUserProfileCooked } from "common/data/userProfile/UserProfileTypes"
import { ICommonState } from "common/state/store/DefaultState"

interface IUserCardContainerStateProps {
    userProfile: IUserProfileCooked
}

interface IUserCardContainerProps {
    continueWithAccount: () => void
    useAnotherAccount: () => void
}

const UserCardContainer: React.FunctionComponent<
    IUserCardContainerStateProps & IUserCardContainerProps
> = ({ userProfile, continueWithAccount, useAnotherAccount }) => {
    return (
        <UserCard
            userProfile={userProfile}
            continueWithAccount={continueWithAccount}
            useAnotherAccount={useAnotherAccount}
        />
    )
}

const mapStateToProps = (state: ICommonState): IUserCardContainerStateProps => {
    return {
        userProfile: state.userProfile!,
    }
}

const connectedComponent = connect(mapStateToProps)(UserCardContainer)
export default connectedComponent as unknown as React.FunctionComponent<IUserCardContainerProps>
