const retrieveEnvValue = <T>(webpackDefinedValueReader: () => T, defaultValue: T): T => {
    try {
        return webpackDefinedValueReader()
    } catch (e) {
        if (e instanceof ReferenceError) {
            return defaultValue
        }
        throw e
    }
}

// variable inject with webpack.DefinePlugin must be declared here

declare const LOCAL: boolean
const _LOCAL = retrieveEnvValue(() => LOCAL, false)
export { _LOCAL as LOCAL }

declare const DEVELOPMENT: boolean
const _DEVELOPMENT = retrieveEnvValue(() => DEVELOPMENT, false)
export { _DEVELOPMENT as DEVELOPMENT }
