import IconList from "common/components/icon/IconList"

export enum ENTITY_TYPE {
    MEDIA = "media",
    SELECTION = "selection",
    VIEW = "view",
    LIBRARY_COLLECTION = "library-collection",
    LIBRARY = "library",
    BASKET = "basket",
    PRODUCT_SHEET = "productSheet",
    RECOMPOSED_BEEVIRTUA = "recomposedBeevirtua",
    ZONE = "zone",
    PERIODICAL = "periodical",
    ORGANISATION = "organisation",
    ROOM = "room",
    PRINTER = "printer",
}

export enum COMMON_DOCUMENT_TYPE {
    UNKNOWN = "unknown",
}

export enum BEEVIRTUA_TYPE {
    PDF = "pdf",
    WORD = "word",
    SLIDE = "slide",
    CELL = "cell",
}

export enum OFFICE_TYPE {
    WORD = "word",
    SLIDE = "slide",
    CELL = "cell",
}

export enum MEDIA_TYPE {
    BEEVIRTUA = "beevirtua",
    OFFICE = "office",
    IMAGE = "image",
    LINK = "link",
    VIDEO = "video",
    AUDIO = "audio",
    ANIMATION = "animation",
    FILE = "file",
}

export enum UBOFFICE_EDITOR_PROVIDER {
    COLLABORA = "Collabora",
    ONLYOFFICE = "OnlyOffice",
}

export enum UBOFFICE_EDITOR_NAME {
    COLLABORA = "collaboraEditor",
    ONLYOFFICE = "onlyofficeEditor",
}

export type DOCUMENT_TYPE = BEEVIRTUA_TYPE | OFFICE_TYPE | COMMON_DOCUMENT_TYPE
export const DOCUMENT_TYPE = { BEEVIRTUA_TYPE, OFFICE_TYPE, COMMON_DOCUMENT_TYPE }

export type ENTITIES = ENTITY_TYPE | MEDIA_TYPE | DOCUMENT_TYPE
export const ENTITIES = {
    ...ENTITY_TYPE,
    ...MEDIA_TYPE,
    ...DOCUMENT_TYPE.OFFICE_TYPE,
    ...DOCUMENT_TYPE.BEEVIRTUA_TYPE,
    ...DOCUMENT_TYPE.COMMON_DOCUMENT_TYPE,
}

export const ENTITIES_ICONS: { [key in ENTITIES]: IconList } = {
    [ENTITY_TYPE.BASKET]: IconList.info_list_sm,
    [ENTITY_TYPE.LIBRARY]: IconList.func_home_sm,
    [ENTITY_TYPE.LIBRARY_COLLECTION]: IconList.navi_folder_sm,
    [ENTITY_TYPE.MEDIA]: IconList.info_media_sm,
    [ENTITY_TYPE.PRODUCT_SHEET]: IconList.func_basket_sm,
    [ENTITY_TYPE.ROOM]: IconList.func_video_chat_sm,
    [ENTITY_TYPE.VIEW]: IconList.navi_folder_sm,
    [ENTITY_TYPE.PRINTER]: IconList.func_print_sm,
    [MEDIA_TYPE.ANIMATION]: IconList.info_media_animation_sm,
    [MEDIA_TYPE.AUDIO]: IconList.info_media_audio_sm,
    [MEDIA_TYPE.BEEVIRTUA]: IconList.info_media_document_sm,
    [MEDIA_TYPE.FILE]: IconList.info_media_file_sm,
    [MEDIA_TYPE.IMAGE]: IconList.info_media_image_sm,
    [MEDIA_TYPE.LINK]: IconList.info_media_link_sm,
    [MEDIA_TYPE.OFFICE]: IconList.info_media_file_sm,
    [MEDIA_TYPE.VIDEO]: IconList.info_media_video_sm,
    [OFFICE_TYPE.WORD]: IconList.info_text_document_sm,
    [OFFICE_TYPE.CELL]: IconList.info_spreadsheet_sm,
    [OFFICE_TYPE.SLIDE]: IconList.info_presentation_sm,

    [COMMON_DOCUMENT_TYPE.UNKNOWN]: IconList.info_media_document_sm,
    [BEEVIRTUA_TYPE.PDF]: IconList.info_media_document_sm,
    [ENTITY_TYPE.RECOMPOSED_BEEVIRTUA]: IconList.info_media_document_sm,
    [ENTITY_TYPE.SELECTION]: IconList.info_media_document_sm,
    [ENTITY_TYPE.ZONE]: IconList.info_media_document_sm,
    [ENTITY_TYPE.PERIODICAL]: IconList.info_media_document_sm,
    [ENTITY_TYPE.ORGANISATION]: IconList.info_media_document_sm,
}

export const getEntityToDisplay = (
    entity?: ENTITIES,
    documentType?: DOCUMENT_TYPE,
): ENTITIES | undefined => (entity === MEDIA_TYPE.OFFICE && documentType ? documentType : entity)

export const getTypeToDisplay = (
    mediaType: MEDIA_TYPE,
    documentType?: DOCUMENT_TYPE,
): MEDIA_TYPE | DOCUMENT_TYPE =>
    mediaType === MEDIA_TYPE.OFFICE && documentType ? documentType : mediaType

export const OFFICE_PANEL_MODAL_NAME: string = "officePanel"
