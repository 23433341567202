import _ from "lodash"

import PortalConfig from "@/services/config/PortalConfig"

import EngineFacade from "common/services/engine/EngineFacade"
import Sse, { ISseStandardEvent, SSE_EVENTS, SSE_STATUS } from "common/services/sse/Sse"
import { IOffer, ISelectedOption, OFFER_PLANS } from "common/types/OfferTypes"
import { AuthenticatedHttp } from "common/utils/Http"
import Url from "common/utils/Url"

interface ICreateHubBodySubscriptions {
    plan: string
    quantity: number
}

interface ICreateHubBodyReferrerLink {
    origin: "referral_link"
    sourceLibId: string
    value?: string
}

interface ICreateHubBodyReferrerFree {
    origin: "free_text"
    value: string
}

type ICreateHubBodyReferrer = ICreateHubBodyReferrerLink | ICreateHubBodyReferrerFree

interface ICreateHubBody {
    subscriptions: ICreateHubBodySubscriptions[]
    alias: string
    referrer?: ICreateHubBodyReferrer
}

interface ICreateHubSyncResponse {
    feedbackId: string
}

interface ICreateHubAsyncResponse {
    libraryUrl: string
}

export interface IIsBeeUserResponse {
    isBeeUser: boolean
}

export interface ILibraryThemeInfo {
    logoUrl: string
    primaryColor: string
}

export interface IExistingSelfManagedHub {
    libraryId: string
    libraryUrl: string
    libraryLabel: string
    libraryThemeInfo: ILibraryThemeInfo
    offer: OFFER_PLANS | null
}

type ICreateHubSseEvent = ISseStandardEvent<ICreateHubAsyncResponse>

export const getOffers = async (): Promise<IOffer[]> => {
    const url = Url.join(PortalConfig.apiUrl, "offers")
    const offerList: IOffer[] = await AuthenticatedHttp.get(url)
    for (const offer of offerList) {
        offer.img = await getUrlImageByPlan(offer.plan)
    }
    return offerList
}

export const getExistingLibraries = (): Promise<IExistingSelfManagedHub[]> => {
    const url = Url.join(PortalConfig.apiUrl, "existing-libraries")
    return AuthenticatedHttp.get(url)
}

export const createHub = async (
    name: string,
    selectedOfferPlan: string,
    selectedOfferOptions?: ISelectedOption[],
    referrerValues?: { referrerLink?: string; referrerFree?: string },
): Promise<ICreateHubAsyncResponse> => {
    const url = Url.join(PortalConfig.apiUrl, "create-library")
    const subscriptions: ICreateHubBodySubscriptions[] = [
        {
            plan: selectedOfferPlan,
            quantity: 1,
        },
    ]

    _.forEach(selectedOfferOptions, (option) => {
        option.quantity > 0 && subscriptions.push(option)
    })

    let referrer: ICreateHubBodyReferrer | undefined
    if (referrerValues?.referrerLink) {
        referrer = {
            origin: "referral_link",
            sourceLibId: referrerValues.referrerLink,
            value: referrerValues.referrerFree,
        }
    } else if (referrerValues?.referrerFree) {
        referrer = {
            origin: "free_text",
            value: referrerValues.referrerFree,
        }
    }

    const body: ICreateHubBody = { subscriptions: subscriptions, alias: name, referrer }

    const { feedbackId } = await AuthenticatedHttp.post<ICreateHubSyncResponse>(url, body)

    return new Sse<ICreateHubAsyncResponse>({
        feedbackId,
        onEvent: (event: ICreateHubSseEvent, resolve, reject) => {
            if (event.type === SSE_EVENTS.EVENT_CREATE_SELF_MANAGED_ORG) {
                if (event.data.status === SSE_STATUS.END) {
                    resolve({ libraryUrl: event.data.libraryUrl })
                } else if (event.data.status === SSE_STATUS.ERROR) {
                    reject(event.data.$diagnoses)
                }
            }
        },
    }).open()
}

export const createEmployeeHub = async (): Promise<ICreateHubAsyncResponse> => {
    const url = Url.join(PortalConfig.apiUrl, "create-library", "employee")
    const { libraryUrl } = await AuthenticatedHttp.post(url)

    return { libraryUrl }
}

export const isBeeUser = async (): Promise<IIsBeeUserResponse> => {
    const url = Url.join(PortalConfig.apiUrl, "is-bee-user")
    const { isBeeUser } = await AuthenticatedHttp.get(url)

    return { isBeeUser }
}

export const getUrlImageByPlan = async (plan: OFFER_PLANS): Promise<string | undefined> => {
    let imageFile = ""
    switch (plan) {
        case OFFER_PLANS.free:
            imageFile = "myub"
            break
        case OFFER_PLANS.starter:
            imageFile = "myubplus"
            break
        case OFFER_PLANS.personal:
            imageFile = "professional"
            break
        case OFFER_PLANS.standard:
            imageFile = "team"
            break
        case OFFER_PLANS.advanced:
            imageFile = "advanced"
            break
    }

    const url = Url.join(
        EngineFacade.baseEngineUrl,
        "assets",
        "illustrations",
        "portal",
        `${imageFile}.jpg`,
    )

    return new Promise((resolve) => {
        const img = new Image()
        img.onload = () => resolve(url)
        img.onerror = () => resolve(undefined)

        img.src = url
    })
}
