import Hash from "./interfaces/Hash"
import LocalStorage from "./interfaces/LocalStorage"
import SessionStorage from "./interfaces/SessionStorage"

export const PERSISTENCE_TYPES = {
    HASH: "HASH",
    SESSION_STORAGE: "SESSION_STORAGE",
    LOCAL_STORAGE: "LOCAL_STORAGE",
}

export const PERSISTENCE_INTERFACES = {
    [PERSISTENCE_TYPES.HASH]: Hash,
    [PERSISTENCE_TYPES.LOCAL_STORAGE]: LocalStorage,
    [PERSISTENCE_TYPES.SESSION_STORAGE]: SessionStorage,
}
