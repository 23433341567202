import React, { useEffect, useRef, useState } from "react"
import _ from "lodash"

import DropDown, {
    HORIZONTAL_DIRECTION,
} from "common/components/contextMenu/components/dropDown/DropDown"
import SubMenu from "common/components/contextMenu/SubMenu"
import { IMenuItemButton } from "common/components/menu/components/menuItem/MenuItemTypes"
import { BasicMenuItem, InteractiveMenuItem, MenuContainer } from "common/components/menu/Menu"

import "./dropDownContextMenu.less"
interface IDropDownContextMenuProps {
    id: string
    onClose(): void
    opened: boolean
    alignedHorizontally?: boolean
    adaptSizeToTarget?: boolean
    className?: string
    position?: {
        x: number
        y: number
    }
    forceWidthInPixel?: number // allow container to be the master of it width
    container?: Element
    dropdownClassName?: string
    onScroll?: React.UIEventHandler
    maxHeight?: number
    divEventCatcherEnabled?: boolean
    horizontalDirectionPriority?: HORIZONTAL_DIRECTION
}

const DropDownContextMenu: React.FunctionComponent<IDropDownContextMenuProps> = ({
    opened,
    container,
    adaptSizeToTarget,
    alignedHorizontally,
    children,
    className,
    forceWidthInPixel,
    id,
    onClose,
    position,
    dropdownClassName,
    onScroll,
    maxHeight,
    divEventCatcherEnabled,
    horizontalDirectionPriority,
}) => {
    const closeSubMenuTimeout = useRef<number | undefined>()
    const ignoreEnter = useRef<boolean>()
    const openSubMenuTimeout = useRef<number | undefined>()
    const canCloseSubMenuOnClick = useRef<boolean>(false)

    const [openedMenuPath, setOpenedMenuPath] = useState<(string | null)[]>([])
    const [subMenuDirection, setSubMenuDirection] = useState<HORIZONTAL_DIRECTION | undefined>(
        undefined,
    ) // when a menu opens, we want the submenus to open in the same direction (if possible)

    useEffect(() => {
        opened && setOpenedMenuPath([])
    }, [opened])

    useEffect(() => {
        const openSubMenuTimeoutRef = openSubMenuTimeout.current
        return () => window.clearTimeout(openSubMenuTimeoutRef)
    }, [openSubMenuTimeout])

    useEffect(() => {
        canCloseSubMenuOnClick.current = false
        //Wait a bit after the end of the transition to authorize closing subMenu
        const timeout = window.setTimeout(() => (canCloseSubMenuOnClick.current = true), 250)
        return () => window.clearTimeout(timeout)
    }, [openedMenuPath])

    const _setMenuPathLvl = (lvl: number, id: string | null, toggle?: true) => {
        const newPath = _.slice(openedMenuPath, 0, lvl + 1)
        newPath[lvl] = newPath[lvl] === id && toggle ? null : id
        !_.isEqual(newPath, openedMenuPath) && setOpenedMenuPath(newPath)
    }

    const _getClonedMenuItem = (node: React.ReactElement, lvl: number, index?: number) => {
        // override menu items click to automatically close the menu
        // use a noClose flag on menu items to avoid that behaviour
        const menuItemClickHandler =
            (originalHandler: Function, noClose: boolean) =>
            (...args: any) => {
                // window.setTimeout needed to avoid conflict between menu closing and feature opening
                originalHandler && window.setTimeout(() => originalHandler(...args))
                !noClose && onClose()
            }

        const menuItemEnterHandler = (originalHandler) => () => {
            originalHandler && originalHandler()
            window.clearTimeout(closeSubMenuTimeout.current)
            closeSubMenuTimeout.current = window.setTimeout(() => {
                const newOpenedMenuPath = _.slice(openedMenuPath, 0, lvl)
                !_.isEqual(newOpenedMenuPath, openedMenuPath) &&
                    setOpenedMenuPath(newOpenedMenuPath)
            }, 300)
        }

        const buttonItem: IMenuItemButton = node.props.button
            ? {
                  ...node.props.button,
                  onClick: menuItemClickHandler(node.props.button.onClick, node.props.noClose),
              }
            : undefined

        return React.cloneElement(node, {
            key: node.key || `lvl_${lvl}_${index}`,
            onClick: menuItemClickHandler(node.props.onClick, node.props.noClose),
            onMouseEnter: menuItemEnterHandler(node.props.onMouseEnter),
            button: buttonItem,
        })
    }

    const _getModifiedChild = (child: React.ReactElement, lvl: number, index?: number) => {
        if (!child) {
            return null
        }

        if (child.type === BasicMenuItem || child.type === InteractiveMenuItem) {
            return _getClonedMenuItem(child, lvl, index)
        }

        if (child.type === SubMenu) {
            // Warning: problems could happen because of this generated id
            // solution => set an id when declaring a sub menu in a context menu
            const subLevelId = child.props.id
                ? `${id}_subLevel_${child.props.id}`
                : `${id}_subLevel_${lvl}_${index}`

            // triggered before onMouseEnter
            const onTouchStart = () => {
                ignoreEnter.current = true
                _setMenuPathLvl(lvl, subLevelId, true)
            }

            const subMenuOpened = _.includes(openedMenuPath, subLevelId)

            const onMouseEnter = () => {
                if (ignoreEnter.current) {
                    ignoreEnter.current = false
                    return
                }
                window.clearTimeout(closeSubMenuTimeout.current)
                openSubMenuTimeout.current = window.setTimeout(() => {
                    if (!subMenuOpened) {
                        _setMenuPathLvl(lvl, null)
                        openSubMenuTimeout.current = window.setTimeout(
                            () => _setMenuPathLvl(lvl, subLevelId),
                            100,
                        )
                    }
                }, 200)
            }

            const onMouseLeave = () => window.clearTimeout(openSubMenuTimeout.current)

            const onMouseClick = () => {
                if (subMenuOpened) {
                    canCloseSubMenuOnClick.current && _setMenuPathLvl(lvl, null)
                } else {
                    _setMenuPathLvl(lvl, subLevelId)
                }
            }
            return (
                <SubMenu
                    {...child.props}
                    id={subLevelId}
                    key={subLevelId}
                    _horizontalDirectionPriority={subMenuDirection}
                    _onTouchStart={onTouchStart}
                    _onMouseEnter={onMouseEnter}
                    _onMouseLeave={onMouseLeave}
                    _onClick={onMouseClick}
                    _opened={subMenuOpened}
                >
                    {_.map(
                        React.Children.map(child.props.children, (c) =>
                            _getModifiedChild(c, lvl + 1),
                        ),
                    )}
                </SubMenu>
            )
        }

        return child
    }

    return opened ? (
        <div
            className={
                divEventCatcherEnabled
                    ? "dropDown__eventCatcher--on"
                    : "dropDown__eventCatcher--off"
            }
        >
            <DropDown
                targetElementId={id}
                onPositionChange={setSubMenuDirection}
                className={dropdownClassName}
                onClose={onClose}
                {...{
                    container,
                    position,
                    alignedHorizontally,
                    adaptSizeToTarget,
                    forceWidthInPixel,
                    onScroll,
                    maxHeight,
                    horizontalDirectionPriority,
                }}
            >
                <MenuContainer className={className}>
                    {React.Children.map(children, (child, index) =>
                        _getModifiedChild(child as React.ReactElement, 0, index),
                    )}
                </MenuContainer>
            </DropDown>
        </div>
    ) : null
}

export default DropDownContextMenu
