import React from "react"

import IconList from "common/components/icon/IconList"
import { getRightIcon } from "common/components/menu/components/menuItem/BasicItemHelper"
import { BasicMenuItem, CustomMenuItem, MenuContainer } from "common/components/menu/Menu"

import DropDown, { HORIZONTAL_DIRECTION } from "./components/dropDown/DropDown"

export interface ISubMenuProps {
    label?: string
    iconId?: string | React.ReactElement
    id?: string // an uniq sub menu identifier. Generated automatically if not specified.
    customRender?: React.ReactElement
    forceWidthInPixel?: number // allow container to be the master of it width
    disabled?: boolean

    _horizontalDirectionPriority?: HORIZONTAL_DIRECTION
    _opened?: boolean
    _onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
    _onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
    _onTouchStart?: React.TouchEventHandler<HTMLDivElement>
    _onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void
}

const SubMenu: React.FunctionComponent<ISubMenuProps> = ({
    id = "",
    label,
    iconId,
    children,
    customRender,
    forceWidthInPixel,
    disabled,

    // following props are private, do not use
    _horizontalDirectionPriority,
    _opened,
    _onMouseEnter,
    _onMouseLeave,
    _onTouchStart,
    _onClick,
}) => {
    return (
        <>
            <div
                id={id}
                onMouseEnter={!disabled ? _onMouseEnter : undefined}
                onMouseLeave={!disabled ? _onMouseLeave : undefined}
                onTouchStart={!disabled ? _onTouchStart : undefined}
                onClick={!disabled ? _onClick : undefined}
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                        !disabled && _onClick?.(e)
                    }
                }}
            >
                {customRender ? (
                    <CustomMenuItem highlighted={_opened} withHover>
                        {customRender}
                    </CustomMenuItem>
                ) : (
                    <BasicMenuItem
                        label={label}
                        mainIcon={iconId}
                        rightElements={[getRightIcon(IconList.navi_next_sm)]}
                        highlighted={_opened}
                        disabled={disabled}
                    />
                )}
            </div>

            {_opened && (
                <DropDown
                    targetElementId={id}
                    horizontalDirectionPriority={_horizontalDirectionPriority}
                    alignedHorizontally
                    forceWidthInPixel={forceWidthInPixel}
                    isSubMenu={true}
                >
                    <MenuContainer>{children}</MenuContainer>
                </DropDown>
            )}
        </>
    )
}

export default SubMenu
