import React from "react"

import DialogBoxCheckbox from "common/components/dialogBox/components/checkbox/DialogBoxCheckbox"
import DialogBoxParagraphs from "common/components/dialogBox/components/paragraphs/DialogBoxParagraphs"
import { IDialogBoxButton } from "common/components/dialogBox/DialogBox"
import I18n from "common/services/i18n/I18n"
import { hideActiveModal } from "common/state/actions/ModalActions"
import { IDispatch } from "common/state/StoreTypes"

export const getMessageInParagraph = (message: string | string[]): React.ReactNode => {
    let paragraph

    if (typeof message === "string") {
        paragraph = [{ text: message }]
    } else {
        paragraph = message.map((m) => ({ text: m }))
    }

    return <DialogBoxParagraphs paragraph={paragraph} />
}

export const getDialogBoxCheckbox = (dialogId: string): React.ReactNode => {
    return <DialogBoxCheckbox dialogId={dialogId} />
}

export const wrapDoActionAndHideDialogBox = (
    dispatch: IDispatch,
    button: IDialogBoxButton,
    secondaryButton: boolean = true,
) => ({
    mainButton: {
        ...button,
        action: () => {
            button.action()
            void dispatch(hideActiveModal())
        },
    },
    ...(secondaryButton && {
        secondaryButton: {
            label: I18n.getString("common.global.cancel"),
            action: () => dispatch(hideActiveModal()),
        },
    }),
})

export const getCloseButton = (dispatch: IDispatch): IDialogBoxButton => ({
    label: I18n.getString("common.global.close"),
    action: () => dispatch(hideActiveModal()),
})
