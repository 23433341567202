import React from "react"

import { IOther } from "common/components/formV2/FormTypes"
import Wrapper from "common/components/formV2/other/wrapper/Wrapper"
import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import { classNames } from "common/utils/JSX"

import "./toggle.less"

export interface IToggleProps extends IOther {
    toggled: boolean
    onChange?(checked: boolean): void
    loading?: boolean
}

const Toggle: React.FunctionComponent<IToggleProps> = ({
    toggled,
    onChange,
    label,
    ariaLabel,
    disabled,
    focusDisabled,
    withMargin,
    loading,
}) => {
    const toggledDisabled = loading || disabled
    const _toggle = () => !toggledDisabled && onChange?.(!toggled)

    return (
        <Wrapper disabled={toggledDisabled} withMargin={withMargin}>
            <div
                {...classNames("toggle", {
                    "toggle--toggled": toggled,
                })}
                role="checkbox"
                aria-checked={toggled}
                aria-disabled={toggledDisabled}
                aria-label={ariaLabel}
                aria-hidden={focusDisabled}
                tabIndex={disabled || focusDisabled ? -1 : 0}
                onClick={_toggle}
                onKeyDown={(e) => e.key === " " && _toggle()}
            >
                {label && <label className="label--normal">{label}</label>}
                {loading ? (
                    <div className="toggle__loader">
                        <Icon
                            id={IconList.info_loader_sm}
                            iconClassName={"toggle__loaderIcon common-icon--medium"}
                        />
                    </div>
                ) : (
                    <Icon
                        iconClassName={"toggle__icon common-icon--medium"}
                        id={toggled ? IconList.func_toggle_true_md : IconList.func_toggle_false_md}
                    />
                )}
            </div>
        </Wrapper>
    )
}

export default Toggle
