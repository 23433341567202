import React from "react"
import { connect } from "react-redux"
import _ from "lodash"

import { getMessageInParagraph } from "common/components/dialogBox/DialogBoxHelper"
import { DIALOG_BOX_ICON_BY_TYPE } from "common/components/dialogBox/DialogBoxTypes"
import ErrorBoundary from "common/components/errorBoundary/ErrorBoundary"
import SvgLoader from "common/components/loader/SvgLoader"
import ModalContainer from "common/components/modal/modalContainer/ModalContainer"
import { IModalContainerProps } from "common/components/modal/modalContainer/ModalContainerTypes"
import { IModal } from "common/components/modal/ModalTypes"
import { MODAL_TRANSITION_DURATION_IN_MS } from "common/constants/ANIMATION_TIME"
import { DEVELOPMENT } from "common/constants/Env"
import I18n from "common/services/i18n/I18n"
import { hideActiveModal, showModal } from "common/state/actions/ModalActions"
import { ICommonState } from "common/state/store/DefaultState"
import { IDispatch } from "common/state/StoreTypes"
import AsyncImport from "common/utils/AsyncImport"
import Dom from "common/utils/Dom"
import { classNames } from "common/utils/JSX"

import { StatusPaneError } from "../panels/components/status/StatusPane"

import { COMMON_COMPONENTS_DEFINITION } from "./ModalRouterConfig"
import {
    IAnyModalInjectProps,
    IModalComponentDefinitions,
    IModalConfig,
    IShouldConfirmCallback,
} from "./ModalRouterTypes"

import "./modalRouter.less"

interface IModalRouterCallbacksFromRedux {
    hideActiveModal(): void
    showConfirmDialog(onConfirmCallback: () => void, message?: string): void
}

interface IModalRouterPropsFromRedux {
    modals: IModal[]
    coverLayers: number
    asyncImportLoader: boolean
}

type IModalRouterProps = IModalRouterPropsFromRedux & IModalRouterCallbacksFromRedux

interface IModalRouterState {
    expandedModalsIds: string[]
    availableComponents: Record<string, React.ComponentType<object>>
}

type IContainer = React.FunctionComponent<IModalContainerProps>

type IOnCloseCallbacks = Record<
    string,
    { shouldConfirmCallback?: IShouldConfirmCallback; onEffectiveCloseCallback?: () => void }
>

class ModalRouter extends React.Component<IModalRouterProps, IModalRouterState> {
    private readonly modalsRef = React.createRef<HTMLDivElement>()
    private readonly pendingImports: string[] = []
    private readonly onCloseCallbacks: IOnCloseCallbacks = {}

    private static COMPONENTS_DEFINITION = COMMON_COMPONENTS_DEFINITION
    public static addComponentDefinitions = (componentDefs: IModalComponentDefinitions): void => {
        Object.assign(ModalRouter.COMPONENTS_DEFINITION, componentDefs)
    }

    private static DEFAULT_CONTAINER = ModalContainer
    public static overrideDefaultModalContainer = (Container: IContainer): void => {
        ModalRouter.DEFAULT_CONTAINER = Container
    }

    constructor(props) {
        super(props)

        this.state = {
            expandedModalsIds: [],
            availableComponents: {},
        }
    }

    closeModal(error = false): void {
        const activeModal = _.last(this.props.modals)

        if (activeModal) {
            const { expandedModalsIds } = this.state

            if (activeModal.isClosing) {
                return
            }

            // method to effectively close the active modal
            const doCloseModal = (): void => {
                this.props.hideActiveModal()

                const { onEffectiveCloseCallback } = this.onCloseCallbacks[activeModal.id] || {}

                // clean all stuff related to the modal
                delete this.onCloseCallbacks[activeModal.id]
                if (expandedModalsIds.includes(activeModal.id)) {
                    const newExpandedModalsIds = _.difference(expandedModalsIds, [activeModal.id])
                    this.setState({ expandedModalsIds: newExpandedModalsIds })
                }

                // call the on close callback defined in modalOptions
                onEffectiveCloseCallback?.()
                activeModal.modalOptions.onCloseCallback?.()
            }

            // prevent close confirmation in case of error boundary
            if (error) {
                doCloseModal()
                return
            }

            const { shouldConfirmCallback } = this.onCloseCallbacks[activeModal.id] || {}

            if (shouldConfirmCallback) {
                const response = shouldConfirmCallback(doCloseModal)
                // true or string => show confirm dialog
                if (response) {
                    this.props.showConfirmDialog(
                        doCloseModal,
                        typeof response === "string" ? response : undefined,
                    )
                }
                // false => close without confirm dialog
                else if (response === false) {
                    doCloseModal()
                }
                // null => default behaviour is overridden by the modal
            } else {
                doCloseModal()
            }
        }
    }

    expandModal(id: string): void {
        const { expandedModalsIds } = this.state
        this.setState({ expandedModalsIds: _.xor(expandedModalsIds, [id]) })
    }

    renderModal(modal: IModal, isTopModal: boolean): React.ReactNode {
        const { availableComponents, expandedModalsIds } = this.state

        const { id, modalType, modalProps, isClosing } = modal

        const componentDefinition = ModalRouter.COMPONENTS_DEFINITION[modalType]
        const Component =
            componentDefinition && (componentDefinition.component || availableComponents[modalType])

        if (!Component) {
            return null
        }

        const injectedProps: IAnyModalInjectProps = {
            onClose: () => this.closeModal(),
            setOnCloseCallbacks: (shouldConfirmCallback, onEffectiveCloseCallback) => {
                this.onCloseCallbacks[id] = {
                    shouldConfirmCallback,
                    onEffectiveCloseCallback,
                }
            },
            onExpand: componentDefinition.canExpand ? () => this.expandModal(id) : undefined,
        }

        if (DEVELOPMENT) {
            Object.keys(modalProps).forEach((key) => {
                if (injectedProps[key]) {
                    const errMessage = `Props overlapping between modalProps and injectedProps: ${key} on modal ${modalType}`
                    throw new Error(errMessage)
                }
            })
        }

        const Container = ModalRouter.DEFAULT_CONTAINER
        return (
            <Container
                key={id}
                id={modalType}
                active={isTopModal}
                size={componentDefinition.size}
                isExpanded={expandedModalsIds.includes(id)}
                isClosing={isClosing}
            >
                <ErrorBoundary
                    fallback={
                        <div className="modalError">
                            <StatusPaneError onClose={() => this.closeModal(true)} />
                        </div>
                    }
                >
                    <Component {...modalProps} {...injectedProps} />
                </ErrorBoundary>
            </Container>
        )
    }

    renderModals(modals: IModal[], asyncImportLoader: boolean): React.ReactNode[] {
        const topModalIndex = modals.length - 1
        const svgLoaderElem = <SvgLoader key="svgLoader" className="loader-modal" />

        const modalElements = modals.map((modal, i) => this.renderModal(modal, i === topModalIndex))

        asyncImportLoader && modalElements.push(svgLoaderElem)

        return modalElements
    }

    componentDidMount() {
        window.addEventListener("keydown", (e) => {
            if (e.key === "Tab") {
                let lastElementChild: HTMLElement | null = null
                // Cover is always the last child
                if (this.modalsRef.current!.childElementCount > 1) {
                    lastElementChild = this.modalsRef.current!.childNodes[
                        this.modalsRef.current!.childElementCount - 2
                    ] as HTMLElement
                }
                lastElementChild &&
                    Dom.limitFocusInsideAnElementAfterATab(lastElementChild, e.shiftKey, "dropDown")
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps: IModalRouterProps) {
        const { availableComponents } = this.state

        const componentsToImports = nextProps.modals.reduce(
            (componentDefinitions, { modalType }) => {
                const componentDefinition = ModalRouter.COMPONENTS_DEFINITION[modalType]

                if (
                    componentDefinition &&
                    !componentDefinition.component &&
                    !availableComponents[modalType] &&
                    !this.pendingImports.includes(modalType)
                ) {
                    componentDefinitions.push({ ...componentDefinition, modalType })
                }

                return componentDefinitions
            },
            [] as (IModalConfig & { modalType: string })[],
        )

        componentsToImports.forEach(({ modalType, componentImport }) => {
            this.pendingImports.push(modalType)
            AsyncImport(componentImport!, true).then((component: React.ComponentType<object>) => {
                _.pull(this.pendingImports, modalType)
                this.setState({
                    availableComponents: {
                        ...this.state.availableComponents,
                        [modalType]: component,
                    },
                })
            })
        })
    }

    componentDidUpdate() {
        let lastElementChild: HTMLElement | null = null
        if (this.modalsRef.current!.childElementCount > 1) {
            lastElementChild = this.modalsRef.current!.childNodes[
                this.modalsRef.current!.childElementCount - 2
            ] as HTMLElement
        }
        // Focus the active modal except if it already has a focused element
        const shouldFocusLastModal =
            !document.activeElement ||
            (lastElementChild &&
                !Dom.findParent(document.activeElement, (e) => e === lastElementChild))

        if (shouldFocusLastModal) {
            lastElementChild?.focus()
        }
    }

    render() {
        const { modals, coverLayers, asyncImportLoader } = this.props

        const onModalDivContainerKeyDown = (e: React.KeyboardEvent): void => {
            if (e.key === "Escape" && Dom.findParentByClass(e.target as HTMLDivElement, "modals")) {
                this.closeModal()
            }
        }

        const topModalIndex = modals.length - 1
        const coverVisible = coverLayers > 0
        const coverClickable = !(
            (coverVisible || asyncImportLoader) &&
            topModalIndex >= 0 &&
            modals[topModalIndex].modalOptions?.preventCloseOnClickCover
        )

        const lastModalClosing = coverLayers === 1 && modals.length === 1 && modals[0]?.isClosing

        const isVisible = (coverVisible || asyncImportLoader) && !lastModalClosing

        return (
            <div ref={this.modalsRef} className="modals" onKeyDown={onModalDivContainerKeyDown}>
                {modals.length > 0 && this.renderModals(modals, asyncImportLoader)}
                <div
                    {...classNames("modalCover", {
                        "modalCover--visible": isVisible,
                        "modalCover--hidden": !isVisible,
                    })}
                    style={{ transition: `opacity ${MODAL_TRANSITION_DURATION_IN_MS}ms ease` }}
                    onClick={() => isVisible && coverClickable && this.closeModal()}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: ICommonState): IModalRouterPropsFromRedux => ({
    modals: state.modals,
    coverLayers: state.coverLayers,
    asyncImportLoader: state.asyncImportLoader,
})

const mapDispatchToProps = (dispatch: IDispatch): IModalRouterCallbacksFromRedux => ({
    hideActiveModal: () => dispatch(hideActiveModal()),
    showConfirmDialog: (onConfirmCallback, message) =>
        dispatch(
            showModal(
                "dialogBox",
                {
                    title: I18n.getString("common.global.areYouSure"),
                    icon: DIALOG_BOX_ICON_BY_TYPE.warning,
                    children: getMessageInParagraph(
                        message || I18n.getString("common.modal.closeConfirm"),
                    ),
                    mainButton: {
                        label: I18n.getString("common.global.close"),
                        action: () => {
                            dispatch(hideActiveModal()) // hide confirm
                            onConfirmCallback()
                        },
                    },
                    secondaryButton: {
                        label: I18n.getString("global.cancel"),
                        action: () => dispatch(hideActiveModal()),
                    },
                },
                { preventCloseOnClickCover: true },
            ),
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalRouter)
