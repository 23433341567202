export enum PRICE_PREFIX {
    POSITIVE = "+",
    NEGATIVE = "-",
}

export enum PRICE_SIZE {
    NORMAL = "NORMAL",
    STRONG = "STRONG",
    STRONGEST = "STRONGEST",
}

type IPriceSizeClasses = { [priceSize in PRICE_SIZE]: string }

export const SUM_CLASSES: IPriceSizeClasses = {
    [PRICE_SIZE.NORMAL]: "offerPrice__sum--txtAlt title--normal",
    [PRICE_SIZE.STRONG]: "title--strong",
    [PRICE_SIZE.STRONGEST]: "title--strongest",
}

export const DEVISE_CLASSES: IPriceSizeClasses = {
    [PRICE_SIZE.NORMAL]: "offerPrice__devise--txtAlt label--weak",
    [PRICE_SIZE.STRONG]: "label--weak",
    [PRICE_SIZE.STRONGEST]: "offerPrice__devise--txtAlt label--strong",
}

export const TAXES_CLASSES: IPriceSizeClasses = {
    [PRICE_SIZE.NORMAL]: "label--weakest",
    [PRICE_SIZE.STRONG]: "label--weakest",
    [PRICE_SIZE.STRONGEST]: "offerPrice__taxes--padding label--weak",
}
