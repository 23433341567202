import React from "react"

import {
    DEVISE_CLASSES,
    PRICE_SIZE,
    SUM_CLASSES,
    TAXES_CLASSES,
} from "common/components/detailedOffer/components/offerPrice/OFFER_PRICE"
import I18n from "common/services/i18n/I18n"
import { classNames } from "common/utils/JSX"

import "./offerPrice.less"

interface IOfferPriceProps {
    priceInCents: number
    priceSize: PRICE_SIZE
    withoutTaxes?: boolean
    priceFrom?: boolean
    keepPriceFromSpace?: boolean
    withBorder?: boolean
    pricePrefix?: string
    pricePerUser?: boolean
}

export const computePriceWithoutTaxes = (price: number): number => Math.round(price / 1.2)

const OfferPrice: React.FunctionComponent<IOfferPriceProps> = ({
    priceInCents,
    priceSize,
    priceFrom,
    withoutTaxes = false,
    keepPriceFromSpace = false,
    withBorder = false,
    pricePrefix = "",
    pricePerUser = false,
}) => {
    const isFree = priceInCents === 0

    const priceToDisplay = withoutTaxes ? computePriceWithoutTaxes(priceInCents) : priceInCents

    return (
        <div {...classNames("offerPrice", { "offerPrice--border": withBorder })}>
            {(priceFrom || keepPriceFromSpace) && (
                <div
                    className={`offerPrice__from ${
                        priceFrom ? "" : "offerPrice__from--empty"
                    } subtitle--weakest`}
                >
                    {priceFrom && I18n.getString("common.offer.price.from")}
                </div>
            )}
            <div className="offerPrice__price">
                <div className={`offerPrice__sum ${SUM_CLASSES[priceSize]}`}>
                    {!isFree
                        ? `${pricePrefix ? `${pricePrefix} ` : ""}${priceToDisplay / 100}`
                        : I18n.getString("common.offer.price.free")}
                </div>

                {!isFree && (
                    <div className="offerPrice__priceSuffix">
                        <p className={`offerPrice__devise ${DEVISE_CLASSES[priceSize]}`}>€</p>
                        <p className={`offerPrice__taxes ${TAXES_CLASSES[priceSize]}`}>
                            {I18n.getString(
                                `common.offer.price.${withoutTaxes ? "withoutTaxes" : "withTaxes"}`,
                            )}
                        </p>
                    </div>
                )}
            </div>

            {!isFree && (
                <div className="offerPrice__frequency subtitle--weakest">
                    <p>
                        {I18n.getString(
                            `${
                                pricePerUser
                                    ? "common.offer.price.monthly.per.user"
                                    : "common.offer.price.monthly"
                            }`,
                        )}
                    </p>
                </div>
            )}
        </div>
    )
}

export default OfferPrice
