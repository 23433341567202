import { RefObject, useEffect } from "react"

import { useResizeWindowEffect } from "common/hooks/EventHook"

interface IUseObjectFitHookOptions {
    handleWindowResize?: boolean
    cover?: boolean
}

const objectFitSupported: boolean = "objectFit" in document.documentElement.style

const setContainerElementFit = (container: HTMLElement, cover: boolean) => {
    const containerChild = container?.firstElementChild as HTMLElement

    if (containerChild) {
        containerChild.style.width = containerChild.style.width || "auto"
        containerChild.style.height = containerChild.style.height || "auto"
        const scaleWidth = container.clientWidth / containerChild.clientWidth
        const scaleHeight = container.clientHeight / containerChild.clientHeight

        if (cover) {
            containerChild.style[scaleWidth > scaleHeight ? "width" : "height"] = "100%"
            containerChild.style[scaleWidth <= scaleHeight ? "width" : "height"] = "auto"
            container.style.flexDirection = scaleWidth <= scaleHeight ? "column" : "row"
        } else {
            containerChild.style[scaleWidth < scaleHeight ? "width" : "height"] = "100%"
            containerChild.style[scaleWidth >= scaleHeight ? "width" : "height"] = "auto"
        }
    }
}

/**
 * This hook will reproduce the css 'object-fit' rule behaviour if it is not supported.
 * It is a kind of polyfill for IE11
 * @param containerRef
 * @param dependencies the value that could trigger a container resize
 * @param options
 * @return a function that allow to notify that the content/container size changed and should be refresh
 */
export const useObjectFit = (
    containerRef: RefObject<HTMLElement>,
    dependencies?: ReadonlyArray<any> | undefined,
    options?: IUseObjectFitHookOptions,
): [() => void, boolean] => {
    useEffect(() => {
        !objectFitSupported &&
            setContainerElementFit(containerRef.current!, options?.cover ?? false)
    }, dependencies)

    useResizeWindowEffect(() => {
        !objectFitSupported &&
            options?.handleWindowResize &&
            setContainerElementFit(containerRef.current!, options?.cover ?? false)
    }, [])

    const refresh = () =>
        !objectFitSupported &&
        setContainerElementFit(containerRef.current!, options?.cover ?? false)

    return [refresh, objectFitSupported]
}
