import React, { useRef } from "react"

import { useObjectFit } from "common/hooks/ObjectFitHook"

import "./entityBlockImage.less"

interface IEntityBlockImage {
    url: string
    imgAlt?: string
}

const EntityBlockImage: React.FunctionComponent<IEntityBlockImage> = ({ url, imgAlt }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [refresh] = useObjectFit(containerRef, undefined, { cover: true })

    return (
        <div className="entityBlockImage" ref={containerRef}>
            <img className="entityBlockImage__image" src={url} onLoad={refresh} alt={imgAlt} />
        </div>
    )
}

export default EntityBlockImage
