import _ from "lodash"

export enum RGPD_ITEM_IDS {
    MEDIA_VIEW_REPORTING = "MEDIA_VIEW_REPORTING",
    MEDIA_VIEW_MANDATORY_REPORTING = "MEDIA_VIEW_MANDATORY_REPORTING",
    SHARE = "SHARE",
    POD = "POD",
    MY_BRANDROOM = "MY_BRANDROOM",
    CUSTOMER_ENGAGEMENT_ASK_TO_BE_CALLED = "CUSTOMER_ENGAGEMENT_ASK_TO_BE_CALLED",
    CUSTOMER_ENGAGEMENT_READING_ACKNOWLEDGEMENT = "CUSTOMER_ENGAGEMENT_READING_ACKNOWLEDGEMENT",
    REFERRAL_INVITE = "REFERRAL_INVITE",
}

export enum RGPD_LABEL {
    RGPD_OPTIN = "rgpdOptIn",
    SHARE = "share",
    POD = "pod",
    BRANDROOM = "brandroom",
    CUSTOMER_ENGAGEMENT_ASK_TO_BE_CALLED = "customerEngagementAskToBeCalled",
    CUSTOMER_ENGAGEMENT_READING_ACKNOWLEDGEMENT = "customerEngagementReadingAcknowledgement",
    REFERRAL_INVITE = "referralInvite",
}

export enum RGPD_TITLE {
    MEDIA_VIEW_MANDATORY_REPORTING = "mandatoryRgpdOptIn",
}

export interface IRgpdItem {
    label: RGPD_LABEL
    title?: string
    acknowledgements: {
        feature?: string
        reporting?: string
    }
    reportingOnly?: boolean
    bloctel?: boolean
}

export interface IRgpdTypes {
    feature?: boolean
    reporting?: boolean
}

export type IRgpdAknowledgementType = keyof IRgpdTypes

type IRgpdItems = {
    [id in RGPD_ITEM_IDS]: IRgpdItem
}

export const RGPD_ITEMS: IRgpdItems = {
    [RGPD_ITEM_IDS.MEDIA_VIEW_REPORTING]: {
        label: RGPD_LABEL.RGPD_OPTIN,
        reportingOnly: true,
        acknowledgements: {
            reporting: "media-view-reporting",
        },
    },
    [RGPD_ITEM_IDS.MEDIA_VIEW_MANDATORY_REPORTING]: {
        label: RGPD_LABEL.RGPD_OPTIN,
        title: RGPD_TITLE.MEDIA_VIEW_MANDATORY_REPORTING,
        reportingOnly: true,
        acknowledgements: {
            reporting: "media-view-mandatory-reporting",
        },
    },
    [RGPD_ITEM_IDS.SHARE]: {
        label: RGPD_LABEL.SHARE,
        acknowledgements: {
            feature: "mail-share-feature",
            reporting: "mail-share-reporting",
        },
    },
    [RGPD_ITEM_IDS.POD]: {
        label: RGPD_LABEL.POD,
        bloctel: true,
        acknowledgements: {
            feature: "pod-feature",
            reporting: "pod-reporting",
        },
    },
    [RGPD_ITEM_IDS.MY_BRANDROOM]: {
        label: RGPD_LABEL.BRANDROOM,
        bloctel: true,
        acknowledgements: {
            feature: "my-brandroom-share-feature",
            reporting: "my-brandroom-share-reporting",
        },
    },
    [RGPD_ITEM_IDS.CUSTOMER_ENGAGEMENT_ASK_TO_BE_CALLED]: {
        label: RGPD_LABEL.CUSTOMER_ENGAGEMENT_ASK_TO_BE_CALLED,
        bloctel: true,
        acknowledgements: {
            feature: "customer-engagement-ask-to-be-called-feature",
            reporting: "customer-engagement-ask-to-be-called-reporting",
        },
    },
    [RGPD_ITEM_IDS.CUSTOMER_ENGAGEMENT_READING_ACKNOWLEDGEMENT]: {
        label: RGPD_LABEL.CUSTOMER_ENGAGEMENT_READING_ACKNOWLEDGEMENT,
        bloctel: true,
        acknowledgements: {
            feature: "customer-engagement-reading-acknowledgement-feature",
            reporting: "customer-engagement-reading-acknowledgement-reporting",
        },
    },
    [RGPD_ITEM_IDS.REFERRAL_INVITE]: {
        label: RGPD_LABEL.REFERRAL_INVITE,
        acknowledgements: {
            feature: "referral-invite-feature",
        },
    },
}

interface IRgpdItemFeature extends IRgpdItem {
    id: RGPD_ITEM_IDS
}

type IRgpdItemIdsByFeatureId = {
    [feature in string]: IRgpdItemFeature
}

const RGPD_ITEMS_BY_FEATURE_IDS: IRgpdItemIdsByFeatureId = {}
_.forEach(RGPD_ITEMS, (rgpdItem: IRgpdItem, rgpdItemId: RGPD_ITEM_IDS) => {
    _.forEach(rgpdItem.acknowledgements, (featureId: string) => {
        RGPD_ITEMS_BY_FEATURE_IDS[featureId] = _.assign({ id: rgpdItemId }, rgpdItem)
    })
})

type IRgpdItemIdsByLabel = {
    [label in RGPD_LABEL]: RGPD_ITEM_IDS
}

const RGPD_ITEM_IDS_BY_LABELS = {} as IRgpdItemIdsByLabel
_.forEach(RGPD_ITEMS, (rgpdItem: IRgpdItem, rgpdItemId: RGPD_ITEM_IDS) => {
    RGPD_ITEM_IDS_BY_LABELS[rgpdItem.label] = rgpdItemId
})

export { RGPD_ITEMS_BY_FEATURE_IDS, RGPD_ITEM_IDS_BY_LABELS }
