import PortalConfig from "@/services/config/PortalConfig"
import { I18n } from "@/services/i18n/I18n"
import { PersistenceConfig } from "@/services/persistence/PersistenceConfig"
import * as Store from "@/store/Store"

import AuthFacade from "common/services/auth/AuthFacade"
import EngineFacade from "common/services/engine/EngineFacade"
import { IEngineFacadeConfig } from "common/services/engine/EngineFacadeTypes"
import PersistenceFacade from "common/services/persistence/PersistenceFacade"
import UiKitLoader from "common/style/UiKitLoader"
import Url from "common/utils/Url"

export const initEngineFacade = (): void => {
    const engineFacadeConfig: IEngineFacadeConfig = {
        baseEngineUrl: "/engine",
        defaultFeedbackUrl: Url.join(PortalConfig.apiUrl, "feedback"),
        rootUserProfileUrl: PortalConfig.apiUrl,
    }
    EngineFacade.initialize(engineFacadeConfig as any)
}

export const initPersistenceFacade = (): void => {
    PersistenceFacade.initialize(PersistenceConfig)
}

export const initLang = async (): Promise<void> => {
    interface ICrossBrowserNavigator extends Navigator {
        userLanguage?: string
        systemLanguage?: string
    }

    const navigator = window.navigator as ICrossBrowserNavigator

    const requestedLang =
        (PersistenceFacade.hash as any).language.get() ||
        navigator.language ||
        navigator.userLanguage ||
        navigator.systemLanguage ||
        "en"

    const lang = await I18n.load(requestedLang)
    document.documentElement.lang = lang
}

export const initAuthFacade = (): Promise<void> =>
    AuthFacade.initialize().then(() => AuthFacade.initializeUser())

export const initUiKit = (): Promise<[void, void]> => UiKitLoader.load()

export const initStore = (): void => {
    Store.initialize()
}
