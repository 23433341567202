import React from "react"

import { PortalCard } from "@/components/portalCard/PortalCard"

import SvgLoader from "common/components/loader/SvgLoader"
import { classNames } from "common/utils/JSX"

import "./loadingCard.less"

interface ILoadingProps {
    transparent?: boolean
    fullscreen?: boolean
    mainTitle?: string
}

export const LoadingCard: React.FunctionComponent<ILoadingProps> = ({
    transparent,
    fullscreen,
    mainTitle,
}) => {
    return transparent ? (
        <div
            {...classNames("loadingCard loadingCard--transparent", {
                "loadingCard--fullscreen": fullscreen,
            })}
        >
            <SvgLoader />
        </div>
    ) : (
        <PortalCard mainTitle={mainTitle} isMaxWidth>
            <div className="loadingCard">
                <SvgLoader />
            </div>
        </PortalCard>
    )
}
