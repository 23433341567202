interface IStepperCircleConfig {
    radius: number
    stroke: number
    normalizedRadius: number
    circumference: number
}

const getStepperCircleConfig = (radius: number, stroke: number): IStepperCircleConfig => {
    const normalizedRadius = radius - stroke / 2
    return {
        radius,
        stroke,
        normalizedRadius,
        circumference: normalizedRadius * 2 * Math.PI,
    }
}

export const STEPPER_CIRCLE_CONFIG = getStepperCircleConfig(20, 4)
