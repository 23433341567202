import VALUE_TYPES from "common/services/persistence/ValueTypes"

interface IPersistenceConfigItem {
    key: string | ((...args: any[]) => string)
    type?: string
    defaultValue?: any
}

interface IPersistenceConfig {
    hash?: { [name: string]: IPersistenceConfigItem }
    sessionStorage?: { [name: string]: IPersistenceConfigItem }
    localStorage?: { [name: string]: IPersistenceConfigItem }
}

export const PersistenceConfig: IPersistenceConfig = {
    hash: {
        language: { key: "lang" },
        referrerLink: { key: "rflid" }, // set automatically from the referral program. It is the source hub id
        referrerFree: { key: "rf" }, // set manually to identify the source of the link (salesperson, campaign etc.)
        offer: { key: "offer" }, // Allow to directly show the business offers
        redirectTo: { key: "redirectTo", type: VALUE_TYPES.STRING }, // Used to redirect to a route after login
    },
}
