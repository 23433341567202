import React from "react"
import _ from "lodash"

import ChooseOffer, {
    IOfferLabel,
    IOnOfferChange,
} from "common/components/detailedOffer/components/chooseOffer/ChooseOffer"
import FeatureList from "common/components/detailedOffer/components/featureList/FeatureList"
import {
    PRICE_PREFIX,
    PRICE_SIZE,
} from "common/components/detailedOffer/components/offerPrice/OFFER_PRICE"
import OfferPrice from "common/components/detailedOffer/components/offerPrice/OfferPrice"
import OfferSummary from "common/components/detailedOffer/components/summary/OfferSummary"
import { MAX_OPTION_QTY_VALUE } from "common/components/detailedOffer/DETAILED_OFFER"
import NumberField from "common/components/formV2/field/number/NumberField"
import I18n from "common/services/i18n/I18n"
import {
    FORMAT_TAXES,
    IOffer,
    ISelectedOption,
    OFFER_GROUPS_IDS,
    OFFER_PLANS,
    PLAN_GROUP_MAPPING,
} from "common/types/OfferTypes"
import { classNames } from "common/utils/JSX"

import "./detailedOffer.less"

export type IOnOfferChangeFunc = (
    offerPlan: string,
    optionPlan: string | null,
    optionQuantity: number,
) => void

export interface ISelectedOffer {
    plan: string
    options: ISelectedOption[]
}

interface IDetailedOfferState {
    currentOffer: IOffer
    initialOfferIndex: number
    optionQuantity: number
}

export interface IDetailedOfferProps {
    offers: IOffer[]
    selectedOffer: ISelectedOffer
    onOfferChange: IOnOfferChangeFunc
    formatTaxes?: FORMAT_TAXES
    compact?: boolean // use it when the container height is small (stack elements on top of each other)
}

// The offer model take an array of options but this component only handle one option.
// If in the future we have to manage several options, this component will have to be refactored.
class DetailedOffer extends React.Component<IDetailedOfferProps, IDetailedOfferState> {
    private readonly offersLabel: IOfferLabel[]
    constructor(props) {
        super(props)

        this.offersLabel = this.getOffersList()

        this.state = this._getStateFromProps()
    }

    _getStateFromProps() {
        const { offers, selectedOffer } = this.props

        const initialOfferIndex =
            _.findIndex(offers, (offer) => offer.plan === selectedOffer.plan) || 0
        const currentOffer = offers[initialOfferIndex]
        const optionQuantity =
            selectedOffer.options.length > 0 ? selectedOffer.options[0].quantity : 0

        return {
            initialOfferIndex,
            currentOffer,
            optionQuantity,
        }
    }

    getOffersList = (): IOfferLabel[] => {
        const { offers } = this.props

        return _.map(offers, ({ plan }) => ({
            id: plan,
            label: I18n.getString(`common.offer.name.${plan}`),
        }))
    }

    onChange: IOnOfferChange = (newOfferPlan: OFFER_PLANS) => {
        const { offers, onOfferChange } = this.props
        const { currentOffer } = this.state

        if (newOfferPlan === currentOffer.plan) {
            return
        }

        const newInitialOfferIndex = _.findIndex(offers, (o) => o.plan === newOfferPlan)
        const newOffer = offers[newInitialOfferIndex]
        const newOptionPlan = newOffer.options.length > 0 ? newOffer.options[0].plan : null

        onOfferChange(newOffer.plan, newOptionPlan, 0)

        this.setState({
            initialOfferIndex: newInitialOfferIndex,
            currentOffer: newOffer,
            optionQuantity: 0,
        })
    }

    setOptionQuantity = (value: number) => {
        const { onOfferChange } = this.props
        const { currentOffer } = this.state

        onOfferChange(currentOffer.plan, currentOffer.options[0].plan, value)

        this.setState({ optionQuantity: value })
    }

    componentDidUpdate(prevProps: IDetailedOfferProps) {
        if (!_.isEqual(prevProps.selectedOffer, this.props.selectedOffer)) {
            this.setState(this._getStateFromProps())
        }
    }

    render() {
        const { offersLabel } = this
        const { compact, formatTaxes = FORMAT_TAXES.default } = this.props
        const { initialOfferIndex, currentOffer, optionQuantity } = this.state
        const withoutTaxes =
            formatTaxes === FORMAT_TAXES.default
                ? PLAN_GROUP_MAPPING[currentOffer.plan] === OFFER_GROUPS_IDS.business
                : formatTaxes === FORMAT_TAXES.withoutTaxes

        return (
            <div {...classNames("detailedOffer", { "detailedOffer--compact": compact })}>
                <div className="detailedOffer__chooseOffer">
                    <ChooseOffer
                        initialOfferIndex={initialOfferIndex}
                        offersLabel={offersLabel}
                        onChange={this.onChange}
                    />
                </div>
                <div className="detailedOffer__content">
                    <div className="detailedOffer__features">
                        <div className="detailedOffer__details">
                            <div className="detailedOffer__lists">
                                <FeatureList
                                    featureList={currentOffer.features}
                                    showOnlyEditableFeatures
                                    options={currentOffer.options}
                                />
                            </div>
                            <div className="detailedOffer__price">
                                <OfferPrice
                                    priceInCents={currentOffer.priceInCents}
                                    priceSize={PRICE_SIZE.NORMAL}
                                    withoutTaxes={withoutTaxes}
                                    withBorder
                                />
                            </div>
                        </div>
                        <div className="detailedOffer__quantity label--normal">
                            {I18n.getString("common.offer.quantity.included")}
                        </div>
                    </div>
                    {_.map(currentOffer.options, (option, index) => (
                        <div className="detailedOffer__features" key={index}>
                            <div className="detailedOffer__details">
                                <div className="detailedOffer__lists">
                                    <FeatureList
                                        featureList={option.features}
                                        addPlusPrefix={true}
                                        featureAddedByFrontAllowed={false}
                                    />
                                </div>
                                <div className="detailedOffer__price">
                                    <OfferPrice
                                        priceInCents={option.priceInCents}
                                        priceSize={PRICE_SIZE.NORMAL}
                                        pricePrefix={PRICE_PREFIX.POSITIVE}
                                        withoutTaxes={withoutTaxes}
                                        withBorder
                                    />
                                </div>
                            </div>
                            <div className="detailedOffer__quantity">
                                <NumberField
                                    value={optionQuantity}
                                    min={0}
                                    max={option.limit ? option.limit : MAX_OPTION_QTY_VALUE}
                                    limit={1}
                                    onChange={([, v], isValid) =>
                                        this.setOptionQuantity(
                                            isValid
                                                ? Math.min(v, option.limit ?? MAX_OPTION_QTY_VALUE)
                                                : 0,
                                        )
                                    }
                                />
                            </div>
                            {option.limit && optionQuantity === option.limit && (
                                <div
                                    className="detailedOffer__message"
                                    data-testid="optionWarningMessage"
                                >
                                    <div className="detailedOffer__block detailedOffer__block--warning" />
                                    <span className="paragraph--weak">
                                        {I18n.getString("common.offer.message.limit.reached")}
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="detailedOffer__total title--strong">
                    {I18n.getString("common.offer.total")}
                </div>

                <div className="detailedOffer__summary">
                    <OfferSummary
                        offer={currentOffer}
                        optionQuantity={optionQuantity}
                        formatTaxes={formatTaxes}
                    />
                </div>
            </div>
        )
    }
}

export default DetailedOffer
