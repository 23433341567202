import React from "react"
import _ from "lodash"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { IStepperProps } from "@/components/stepper/Stepper"
import { AbstractCard } from "@/screens/cards/AbstractCard"

import DetailedOffer, { IOnOfferChangeFunc } from "common/components/detailedOffer/DetailedOffer"
import I18n from "common/services/i18n/I18n"
import { FORMAT_TAXES, IOffer, ISelectedOption } from "common/types/OfferTypes"

interface IDetailedOfferCardProps {
    stepperProps: IStepperProps
    offers: IOffer[]
    formatTaxes: FORMAT_TAXES
    selectedOfferPlan: string
    goBackToOffers: () => void
    onOfferSelected: (offerPlan: string, offerOptions?: ISelectedOption[]) => void
}

interface IDetailedOfferCardState {
    selectedOfferPlan: string
    selectedOfferOptions: ISelectedOption[] | undefined
}

export class DetailedOfferCard extends AbstractCard<
    IDetailedOfferCardProps,
    IDetailedOfferCardState
> {
    constructor(props: IDetailedOfferCardProps) {
        super(props)

        this.state = {
            selectedOfferPlan: props.selectedOfferPlan,
            selectedOfferOptions: undefined,
        }
    }

    static get title(): string {
        return I18n.getString("card.detailedOffer.title")
    }

    onOfferSelected = (): void => {
        const { selectedOfferPlan, selectedOfferOptions } = this.state

        this.props.onOfferSelected(selectedOfferPlan, selectedOfferOptions)
    }

    // optionPlan and optionQuantity will be defined because we filter the offers without options
    onOfferChange: IOnOfferChangeFunc = (offerPlan, optionPlan, optionQuantity) => {
        this.setState({
            selectedOfferPlan: offerPlan,
            selectedOfferOptions: [{ plan: optionPlan!, quantity: optionQuantity }],
        })
    }

    render() {
        const { stepperProps, goBackToOffers, selectedOfferPlan, offers, formatTaxes } = this.props
        const offersWithOptions = _.filter(offers, (offer) => offer.options.length > 0)

        return (
            <PortalCard
                stepperProps={stepperProps}
                buttons={{
                    onPrevious: goBackToOffers,
                    onNext: this.onOfferSelected,
                }}
            >
                <DetailedOffer
                    offers={offersWithOptions}
                    formatTaxes={formatTaxes}
                    selectedOffer={{ plan: selectedOfferPlan, options: [] }}
                    onOfferChange={this.onOfferChange}
                />
            </PortalCard>
        )
    }
}
