import React, { useEffect, useState } from "react"

import Checkbox from "common/components/formV2/other/checkbox/Checkbox"
import I18n from "common/services/i18n/I18n"
import PersistenceFacade from "common/services/persistence/PersistenceFacade"

import "./dialogBoxCheckbox.less"
type IEventCheck = React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>

export interface IDialogBoxCheckboxProps {
    dialogId: string
    label?: string
    checked?: boolean
    onChange?: (checkStatus: boolean, e: IEventCheck) => void
}

const DialogBoxCheckbox: React.FunctionComponent<IDialogBoxCheckboxProps> = ({
    dialogId,
    label,
    checked,
    onChange,
}) => {
    const [value, setValue] = useState(false)

    useEffect(() => {
        const ls = PersistenceFacade.localStorage!.hiddenDialogs
        const hiddenDialogs = ls.get() ?? []
        const index = hiddenDialogs.findIndex((conf) => conf.id === dialogId)

        if (index === -1 && value) ls.set([...hiddenDialogs, { id: dialogId }])
        if (index !== -1 && !value)
            ls.set(hiddenDialogs.slice(0, index).concat(hiddenDialogs.slice(index + 1)))
    }, [value, dialogId])

    return (
        <div className="dialogBoxCheckbox">
            <Checkbox
                label={label ? label : I18n.getString("common.dialog.doNotShow")}
                checked={checked ? checked : value}
                onChange={onChange ? onChange : setValue}
            />
        </div>
    )
}

export default DialogBoxCheckbox
