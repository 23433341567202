import { SET_USER_PROFILE } from "common/state/actions/UserActions"
import { ICommonState } from "common/state/store/DefaultState"
import { IAction } from "common/state/StoreTypes"

import {
    CLOSE_ACTIVE_MODAL_ACTION,
    HIDE_ASYNC_IMPORT_LOADER_ACTION,
    REMOVE_MODAL_ACTION,
    SHOW_ASYNC_IMPORT_LOADER_ACTION,
    SHOW_COVER_ACTION,
    SHOW_MODAL_ACTION,
    UPDATE_ACTIVE_MODAL_ACTION,
} from "../actions/ModalActions"

import {
    reduceCloseActiveModal,
    reduceHideAsyncImportLoader,
    reduceRemoveModal,
    reduceSetUserProfile,
    reduceShowAsyncImportLoader,
    reduceShowCover,
    reduceShowModal,
    reduceUpdateActiveModal,
} from "./Reducers"

const RootReducer = (state: ICommonState, action: IAction<any>): ICommonState => {
    switch (action.type) {
        case SHOW_MODAL_ACTION:
            return reduceShowModal(state, action)
        case SHOW_COVER_ACTION:
            return reduceShowCover(state, action)
        case CLOSE_ACTIVE_MODAL_ACTION:
            return reduceCloseActiveModal(state, action)
        case REMOVE_MODAL_ACTION:
            return reduceRemoveModal(state, action)
        case SHOW_ASYNC_IMPORT_LOADER_ACTION:
            return reduceShowAsyncImportLoader(state, action)
        case HIDE_ASYNC_IMPORT_LOADER_ACTION:
            return reduceHideAsyncImportLoader(state, action)
        case UPDATE_ACTIVE_MODAL_ACTION:
            return reduceUpdateActiveModal(state, action)
        case SET_USER_PROFILE:
            return reduceSetUserProfile(state, action)
        default:
            return state
    }
}

export default RootReducer
