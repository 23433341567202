// inspired by https://stackoverflow.com/questions/46429937/ie11-does-a-polyfill-script-exist-for-css-variables
// This polyfill
// - parse all stylesheets
// - get all css variable declarations (keep the last value declared)
// - replace 'var(--variable)' expressions by the its value
// To dynamically set a variable in css, use the method setVariableValue which replace, in this case, the function document.body.style.setProperty.
// Use the updateContext method to apply values declared in a specific css class

class CssVariablePolyfill {
    _hasVar(css = "") {
        return !!css.match(/var\(--[^)]+\)/)
    }

    _getLinkStylesheetContent(url) {
        const request = new XMLHttpRequest()

        return new Promise((resolve, reject) => {
            request.open("GET", url, true)
            request.overrideMimeType("text/css;")

            request.onload = () => {
                request.status >= 200 && request.status < 400
                    ? resolve(request.responseText)
                    : reject("an error was returned from:", url)
            }
            request.onerror = () => reject("we could not get anything from:", url)
            request.send()
        })
    }

    _injectCssWithoutVariables(cssWithoutVariables) {
        const insertedStyle = document.getElementById("insertedStyle")

        if (insertedStyle) {
            insertedStyle.innerHTML = cssWithoutVariables
        } else {
            let style = document.createElement("style")
            style.type = "text/css"
            style.innerHTML = cssWithoutVariables
            style.id = "insertedStyle"
            document.head.appendChild(style)
        }
    }

    _replaceVariables(cssWithVariables, variables) {
        let cssWithoutVariables = cssWithVariables

        const allVariablesFound = _.uniq(cssWithVariables.match(/var\([^)]*\)/g))
        _.forEach(allVariablesFound, (variable) => {
            const variableName = variable.match(/var\(([^)]*)\)/)[1]

            cssWithoutVariables = cssWithoutVariables.replace(
                new RegExp(`var\\(${variableName}\\)`, "g"),
                variables[variableName],
            )
        })

        return cssWithoutVariables
    }

    // If a variable is found mutliple times (ex: dark/light, the last value found will be used)
    // The "updateContext" should be called later to choose the right context
    _getVariables(css) {
        const declarations = css.match(/[\s;{]--[a-zA-Z0-9-_]+:[^;}\n]+/g) || []

        const variables = {}

        _.forEach(declarations, (declaration) => {
            const match = declaration.match(/(--[a-zA-Z0-9-_]+)\s*:\s*([^;}\n]+)/)
            variables[match[1]] = match[2]
        })

        return variables
    }

    _getCssWithVariables(css) {
        const parseCssBlocks = (rawCss) => {
            const blocks = rawCss.match(/\s*([^{]+)\s*\{\s*([^}]*?)\s*}/g)

            let result = ""

            _.forEach(blocks, (block) => {
                if (this._hasVar(block)) {
                    const selector = block.match(/([^{]+)/)[1]
                    const rules = block.replace(`${selector}{`, "").replace(/\}$/, "")

                    const newContent = _.filter(rules.split(";"), (rule) =>
                        this._hasVar(rule.split(":")[1]),
                    ).join(";")

                    result += `${selector}{${newContent}}`
                }
            })

            return result
        }

        let result = ""

        const atRulesRegex = /@(keyframe|media)[^{]+\{([\s\S]+?})\s*}/g
        const atRules = css.match(atRulesRegex) || []
        let remainingCssToParse = css

        for (let i = 0; i < atRules.length; i++) {
            const currentAtRules = atRules[i]

            const split = remainingCssToParse.split(currentAtRules)

            const before = split.shift()
            const cleanedBefore = parseCssBlocks(before)

            if (cleanedBefore.length > 0) {
                result += cleanedBefore
            }

            if (this._hasVar(currentAtRules)) {
                if (currentAtRules.indexOf("@keyframe") === 0) {
                    result += currentAtRules
                } else {
                    const selector = currentAtRules.match(/([^{]+)/)[1]
                    const content = currentAtRules.replace(`${selector}{`, "").replace(/\}$/, "")

                    result += `${selector}{${parseCssBlocks(content)}}`
                }
            }

            remainingCssToParse = split.join(atRules[i])
        }

        const cleanedAfter = parseCssBlocks(remainingCssToParse)

        if (cleanedAfter.length > 0) {
            result += cleanedAfter
        }

        return result
    }

    _findAllCss() {
        const styleBlocks = document.querySelectorAll(
            'style:not(#insertedStyle),link[rel="stylesheet"]',
        )

        return Promise.all(
            _.map(styleBlocks, (block) => {
                if (block.nodeName === "STYLE") {
                    return Promise.resolve(block.innerHTML)
                } else if (block.nodeName === "LINK") {
                    return this._getLinkStylesheetContent(block.getAttribute("href"))
                }
            }),
        ).then((allCss) => allCss.join(""))
    }

    getCss() {
        return this._findAllCss().then((css) => {
            this.css = css

            this.cssWithVariables = this._getCssWithVariables(css)
            this.variables = this._getVariables(css)
        })
    }

    apply() {
        return this.getCss().then(() => {
            const { cssWithVariables, variables } = this

            const cssWithoutVariables = this._replaceVariables(cssWithVariables, variables)
            this._injectCssWithoutVariables(cssWithoutVariables)
        })
    }

    setVariableValue(variableId, value) {
        if (!this.cssWithVariables || !this.variables) {
            return
        }

        if (!this.settedVariables) {
            this.settedVariables = {}
        }

        this.settedVariables[variableId] = value
        this.variables[variableId] = value

        const cssWithoutVariables = this._replaceVariables(this.cssWithVariables, this.variables)
        this._injectCssWithoutVariables(cssWithoutVariables)
    }

    updateContext(className, resetSettedVariables = false) {
        if (!this.css || !this.cssWithVariables || !this.variables) {
            return
        }

        let cssBlocksForContext = this.css.match(new RegExp(`\\.${className}\\s*{([^}]+)}`, "g"))

        if (cssBlocksForContext) {
            const cssForContext = cssBlocksForContext.join("")

            const contextVariables = this._getVariables(cssForContext)
            _.assign(this.variables, contextVariables)
            if (!resetSettedVariables) {
                _.assign(this.variables, this.settedVariables)
            }

            const updatedCssWithoutVariables = this._replaceVariables(
                this.cssWithVariables,
                this.variables,
            )
            this._injectCssWithoutVariables(updatedCssWithoutVariables)
        }
    }

    getCssAndUpdateContext(className, resetSettedVariables) {
        return this.getCss().then(() => this.updateContext(className, resetSettedVariables))
    }

    static isNecessary() {
        return !(window.CSS && window.CSS.supports && window.CSS.supports("(--foo: red)"))
    }
}

export { CssVariablePolyfill }
export default () => {
    if (CssVariablePolyfill.isNecessary()) {
        console.log("CssVariablePolyfill: start")

        console.time("CssVariablePolyfill: done in")
        const cssVarPoly = new CssVariablePolyfill()
        return cssVarPoly.apply().then(() => {
            window.cssVarPoly = cssVarPoly

            console.timeEnd("CssVariablePolyfill: done in")
        })
    } else {
        return Promise.resolve()
    }
}
