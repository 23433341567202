import React, { useEffect, useRef, useState } from "react"
import _ from "lodash"

import Fieldset from "common/components/formV2/field/fieldset/Fieldset"
import { IField } from "common/components/formV2/FormTypes"
import { bem } from "common/utils/Bem"

import "./selectField.less"

export interface ISelectFieldOption {
    id: string
    label: string
    disabled?: boolean
}

export interface ISelectFieldOptionGroup {
    label?: string
    items: ISelectFieldOption[]
}

interface ISelectFieldBaseProps extends IField {
    groups: ISelectFieldOptionGroup[]
    multiple?: boolean
    allowEmptyValue?: boolean
}

export interface ISelectFieldProps extends ISelectFieldBaseProps {
    value?: string
    multiple?: false
    onChange(changeValue: string): void
}

export interface IMultiSelectFieldProps extends ISelectFieldBaseProps {
    value: Array<string>
    multiple: true
    onChange(changeValue: Array<string>): void
}

const SelectField: React.FunctionComponent<ISelectFieldProps | IMultiSelectFieldProps> = ({
    groups,
    label,
    disabled,
    onChange,
    customErrorMessage,
    required,
    loading,
    ariaLabel,
    value,
    withMargin,
    allowEmptyValue,
    multiple,
}) => {
    const selectId = useRef(_.uniqueId("select_"))
    const [showError, setShowError] = useState(false)

    const _onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (multiple) {
            const options = Array.from(e.target.selectedOptions).map((opt) => opt.value)
            onChange(options)
        } else {
            onChange(e.target.value)
        }
    }

    const renderGroupOptions = (group: ISelectFieldOptionGroup) =>
        group.items.map((option, index) => (
            <option
                value={option.id}
                label={option.label}
                key={`option_${option.id ?? index}`}
                disabled={option.disabled}
            >
                {option.label}
            </option>
        ))

    const renderGroups = (groups: Array<ISelectFieldOptionGroup>) =>
        groups.map((group, index) => (
            <optgroup label={group.label ?? ""} key={`optgroup_${index}`}>
                {renderGroupOptions(group)}
            </optgroup>
        ))

    const renderSelect = () => (
        <select
            name="select"
            value={value}
            disabled={disabled}
            required={required}
            id={selectId.current}
            className={bem("selectField", { multiple }, ["paragraph--normal"])}
            tabIndex={disabled ? -1 : 0}
            onChange={_onChange}
            aria-label={ariaLabel}
            onBlur={() => setShowError(true)}
            multiple={multiple}
        >
            {groups.length === 1 ? renderGroupOptions(groups[0]) : renderGroups(groups)}
        </select>
    )

    useEffect(() => {
        // Only to prevent error from empty or undefined value
        // Try to give value defined and not empty
        if (
            (value === undefined || value === "") &&
            groups.length > 0 &&
            groups[0].items.length > 0 &&
            !allowEmptyValue
        ) {
            multiple ? onChange([]) : onChange(groups[0].items[0].id)
        }
    }, [value, groups, allowEmptyValue])

    return (
        <Fieldset
            customErrorMessage={customErrorMessage}
            loading={loading}
            disabled={disabled}
            forLabel={selectId.current}
            label={label}
            withMargin={withMargin}
            showError={showError}
            required={required}
        >
            {multiple ? (
                <div className="multiSelectFieldWrapper">{renderSelect()}</div>
            ) : (
                renderSelect()
            )}
        </Fieldset>
    )
}

export default SelectField
