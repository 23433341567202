import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import _ from "lodash"

import { usePreventGlobalScroll } from "common/hooks/PreventGlobalScroll"
import Dom from "common/utils/Dom"
import { classNames } from "common/utils/JSX"

import "./dropDown.less"

interface IDropDownProps {
    targetElementId: string // open under the element with the id <targetElementId>
    onPositionChange?(pos: HORIZONTAL_DIRECTION): void
    alignedHorizontally?: boolean // boolean allowing to place the dropdown beside the button, instead on the bottom
    horizontalDirectionPriority?: HORIZONTAL_DIRECTION // By default, try to opening to the right. This prop can changes this priority (not recommended).
    adaptSizeToTarget?: boolean // if specified, the menu will have the same width as its target element.
    maxHeight?: number
    className?: string
    position?: {
        x: number
        y: number
    }
    forceWidthInPixel?: number // allow container to be the master of it width
    container?: Element
    isSubMenu?: boolean
    onClose?: () => void
    onScroll?: React.UIEventHandler
}

interface IDropDownState {
    style?: React.CSSProperties
    needRecompute?: boolean
}

interface IDimensions {
    top: number
    left: number
    width: number
    height: number
}

type IVerticalPosition = { top: number; bottom: undefined } | { top: undefined; bottom: number }
type IHorizontalPosition = { left: number; right: undefined } | { left: undefined; right: number }
type IPosition = [IVerticalPosition, IHorizontalPosition]

type IGetPositionFunc = (
    screenW: number,
    screenH: number,
    targetDimensions: IDimensions,
    domElementDimensions: IDimensions,
    directionPriority?: HORIZONTAL_DIRECTION,
    isSubMenu?: boolean,
) => IPosition

const MARGIN = 5

export enum HORIZONTAL_DIRECTION {
    TO_LEFT = "left",
    TO_RIGHT = "right",
}

export const shouldPlaceToTheRight = (
    leftSpaceWidth: number,
    rightSpaceWidth: number,
    domElementDimensions: IDimensions,
    directionPriority?: HORIZONTAL_DIRECTION,
): boolean =>
    directionPriority === HORIZONTAL_DIRECTION.TO_LEFT
        ? leftSpaceWidth < domElementDimensions.width && leftSpaceWidth < rightSpaceWidth
        : rightSpaceWidth >= domElementDimensions.width || rightSpaceWidth >= leftSpaceWidth

export const shouldPlaceToTheBottom = (
    topSpaceHeight: number,
    bottomSpaceHeight: number,
    domElementDimensions: IDimensions,
): boolean =>
    bottomSpaceHeight >= domElementDimensions.height || bottomSpaceHeight >= topSpaceHeight

export const getWidth = (
    adaptSizeToTarget: boolean,
    targetDimensions: IDimensions,
    screenW: number,
    horizontalPosition: IHorizontalPosition,
    forceWidthInPixel?: number,
): string => {
    let width = forceWidthInPixel ? `${forceWidthInPixel}px` : "auto"

    if (adaptSizeToTarget) {
        const offset = horizontalPosition.left
            ? horizontalPosition.left - targetDimensions.left
            : horizontalPosition.right! - (screenW - targetDimensions.left - targetDimensions.width)

        width = `${targetDimensions.width - 2 * offset}px`
    }

    return width
}

export const getPositionForVerticalAlignment: IGetPositionFunc = (
    screenW,
    screenH,
    targetDimensions,
    domElementDimensions,
    directionPriority = HORIZONTAL_DIRECTION.TO_RIGHT,
) => {
    const topSpaceHeight = targetDimensions.top - 2 * MARGIN
    const bottomSpaceHeight =
        screenH - (targetDimensions.top + targetDimensions.height) - 2 * MARGIN
    const leftSpaceWidth = targetDimensions.left + targetDimensions.width - MARGIN
    const rightSpaceWidth = screenW - targetDimensions.left - MARGIN

    const placedToBottom = shouldPlaceToTheBottom(
        topSpaceHeight,
        bottomSpaceHeight,
        domElementDimensions,
    )
    const placedToRight = shouldPlaceToTheRight(
        leftSpaceWidth,
        rightSpaceWidth,
        domElementDimensions,
        directionPriority,
    )

    const verticalPosition: IVerticalPosition = placedToBottom
        ? {
              top: targetDimensions.top + targetDimensions.height + MARGIN,
              bottom: undefined,
          }
        : { bottom: screenH - targetDimensions.top + MARGIN, top: undefined }

    const horizontalPosition: IHorizontalPosition = placedToRight
        ? { left: Math.max(targetDimensions.left, MARGIN), right: undefined }
        : {
              right: Math.max(screenW - targetDimensions.left - targetDimensions.width, MARGIN),
              left: undefined,
          }

    return [verticalPosition, horizontalPosition]
}

export const getPositionForHorizontalAlignment: IGetPositionFunc = (
    screenW,
    screenH,
    targetDimensions,
    domElementDimensions,
    directionPriority = HORIZONTAL_DIRECTION.TO_RIGHT,
    isSubMenu,
) => {
    const topSpaceHeight = targetDimensions.top + targetDimensions.height - MARGIN,
        bottomSpaceHeight = screenH - targetDimensions.top - MARGIN,
        leftSpaceWidth = targetDimensions.left - 2 * MARGIN,
        rightSpaceWidth = screenW - (targetDimensions.left + targetDimensions.width) - 2 * MARGIN

    const placedToBottom = shouldPlaceToTheBottom(
        topSpaceHeight,
        bottomSpaceHeight,
        domElementDimensions,
    )
    const placedToRight = shouldPlaceToTheRight(
        leftSpaceWidth,
        rightSpaceWidth,
        domElementDimensions,
        directionPriority,
    )

    const verticalPosition: IVerticalPosition = placedToBottom
        ? {
              top: Math.max(targetDimensions.top - (isSubMenu ? MARGIN * 2 : 0), MARGIN),
              bottom: undefined,
          }
        : {
              bottom: Math.max(
                  screenH -
                      (targetDimensions.top + targetDimensions.height) -
                      (isSubMenu ? MARGIN * 2 : 0),
                  MARGIN,
              ),
              top: undefined,
          }

    const horizontalPosition: IHorizontalPosition = placedToRight
        ? { left: targetDimensions.left + targetDimensions.width + MARGIN, right: undefined }
        : { right: screenW - targetDimensions.left + MARGIN, left: undefined }

    return [verticalPosition, horizontalPosition]
}

/* the container can open below a html node with a specific id, or a x,y position */
const DropDown: React.FunctionComponent<IDropDownProps> = ({
    targetElementId,
    container,
    className,
    adaptSizeToTarget,
    alignedHorizontally,
    forceWidthInPixel,
    horizontalDirectionPriority,
    maxHeight,
    isSubMenu,
    position,
    children,
    onPositionChange,
    onClose,
    onScroll,
}) => {
    const [state, setState] = useState<IDropDownState>()
    const domElementRef = useRef<HTMLDivElement>(null)
    const target = useRef<HTMLElement | null>()
    const scrollContainerOverflowY = useRef<"auto" | "scroll">("auto")

    usePreventGlobalScroll(".dropDown__scrollContainer")

    useEffect(() => {
        target.current = document.getElementById(targetElementId)
        _computePosition()
        domElementRef.current?.focus()
    }, [])

    useEffect(() => {
        if (state && state.style) {
            onPositionChange?.(
                state.style.left ? HORIZONTAL_DIRECTION.TO_RIGHT : HORIZONTAL_DIRECTION.TO_LEFT,
            )
        }
    }, [state?.style])

    useEffect(() => {
        if (state?.needRecompute) {
            // If needRecompute, a vertical scrollbar will appear. But with Firefox "overflow-y: "auto" is not well managed and scrollbar can be above the content. No more bug with "overflow-y: "scroll".
            scrollContainerOverflowY.current = "scroll"
            _computePosition()
        }
    }, [state?.needRecompute])

    useEffect(() => {
        _computePosition()
    }, [position])

    const _getTargetDimensions = (): IDimensions => {
        const { top, left, width, height } = position
            ? { top: position.y, left: position.x, width: 0, height: 0 }
            : target.current?.getBoundingClientRect() || { top: 0, left: 0, width: 0, height: 0 }

        return { top, left, width, height }
    }

    const _computePosition = () => {
        const targetDimensions = _getTargetDimensions()
        const [screenW, screenH] = [document.body.offsetWidth, window.innerHeight]
        const domElementDimensions: IDimensions = Object.assign(
            domElementRef.current!.getBoundingClientRect(),
            forceWidthInPixel ? { width: forceWidthInPixel } : {},
        )

        const getPosition: IGetPositionFunc = alignedHorizontally
            ? getPositionForHorizontalAlignment
            : getPositionForVerticalAlignment

        const [verticalPos, horizontalPos] = getPosition(
            screenW,
            screenH,
            targetDimensions,
            domElementDimensions,
            horizontalDirectionPriority,
            isSubMenu,
        )
        const maxHeightMin = Math.min(
            screenH - (verticalPos.top || verticalPos.bottom || 0) - MARGIN,
            maxHeight || screenH,
        )
        const needRecompute = state?.needRecompute
            ? false
            : Math.floor(maxHeightMin) < Math.floor(domElementDimensions.height) // a vertical scrollbar will appear in the next render

        const width = getWidth(
            !!adaptSizeToTarget,
            targetDimensions,
            screenW,
            horizontalPos,
            forceWidthInPixel,
        )

        setState({
            needRecompute,
            style: {
                top: _.isNumber(verticalPos.top) ? `${verticalPos.top}px` : "",
                bottom: _.isNumber(verticalPos.bottom) ? `${verticalPos.bottom}px` : "",
                left: _.isNumber(horizontalPos.left) ? `${horizontalPos.left}px` : "",
                right: _.isNumber(horizontalPos.right) ? `${horizontalPos.right}px` : "",
                maxHeight: maxHeightMin ? `${maxHeightMin}px` : "none",
                width: _.isNumber(width) ? `${width}px` : width,
            },
        })
    }

    const keyDownHandler = (e) => {
        if (e.key === "Escape") {
            onClose?.()
        }

        if (e.key === "Tab") {
            Dom.limitFocusInsideAnElementAfterATab(domElementRef.current!, e.shiftKey, "dropDown")
        }
    }

    const scrollContainerStyle = {
        maxHeight: state?.style?.maxHeight,
        overflowY: scrollContainerOverflowY.current,
    }

    return ReactDOM.createPortal(
        <div
            ref={domElementRef}
            {...classNames("dropDown", className, {
                "dropDown--visible": !state?.needRecompute && state?.style,
                "dropDown--alignedHorizontally": alignedHorizontally,
            })}
            style={state?.style}
            data-testid={"dropDown"}
            role={"menu"}
            tabIndex={0}
            onKeyDown={keyDownHandler}
        >
            <div
                className="dropDown__scrollContainer"
                style={scrollContainerStyle}
                onScroll={onScroll}
            >
                {children}
            </div>
        </div>,
        container || window.document.body,
    )
}

export default DropDown
