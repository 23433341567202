import React from "react"
import _ from "lodash"
import Rx from "rx-lite"

import { transformContentToJsx } from "common/components/contextMenu/ContextMenuHelper"
import {
    IContextMenuContent,
    IContextMenuTrigger,
} from "common/components/contextMenu/ContextMenuTypes"
import { IEntityBlockProps } from "common/components/entityBlock/EntityBlock"
import { MenuSeparator } from "common/components/menu/Menu"
import Dom from "common/utils/Dom"
import { classNames } from "common/utils/JSX"

import { HORIZONTAL_DIRECTION } from "./components/dropDown/DropDown"
import DropDownContextMenu from "./components/dropDownContextMenu/DropDownContextMenu"
import FullScreenContextMenu from "./components/fullScreenContextMenu/FullScreenContextMenu"

const FULL_SCREEN_LIMIT_WIDTH = 550

export const isFullScreenMode = () => window.innerWidth <= FULL_SCREEN_LIMIT_WIDTH

interface IContextMenuState {
    opened: boolean
}

export interface IContextMenuProps {
    trigger?: IContextMenuTrigger
    container?: Element // where context menu will be rendered (document.body by default)
    content?: IContextMenuContent
    className?: string // this class is set to the menu (menuContainer)
    buttonWrapperClassName?: string
    alignedHorizontally?: boolean
    adaptSizeToTarget?: boolean
    onToggle?: (opened: boolean, openedInFullScreen: boolean) => void // called when the menu is opened or closed
    shouldClose?: (e: React.MouseEvent | React.TouchEvent) => boolean // called when clicking outside, return a boolean to close (or not) the menu
    forceWidthInPixel?: number
    maxHeight?: number
    position?: { x: number; y: number } // the x,y position are considered if there is not a trigger.element specified,
    displayEmptyMenu?: boolean
    dropdownClassName?: string
    onScroll?: React.UIEventHandler
    divEventCatcherEnabled?: boolean
    horizontalDirectionPriority?: HORIZONTAL_DIRECTION
    header?: IEntityBlockProps // todo : make it mandatory when every menu has a header
}

class ContextMenu extends React.Component<IContextMenuProps, IContextMenuState> {
    private readonly triggerElementRef: React.RefObject<HTMLDivElement>
    private id: string
    private fullScreenMode: boolean
    private openedInFullScreen: boolean
    private scrollEvent: any
    private downEvent: any

    constructor(props) {
        super(props)
        this.id = "contextMenu_" + _.uniqueId()

        this.triggerElementRef = React.createRef()
        this.openedInFullScreen = this.fullScreenMode = isFullScreenMode()

        this.state = {
            opened: !!this.props.position,
        }
    }

    _handleResize = () => {
        const { opened } = this.state

        const currentFullScreenMode = isFullScreenMode()

        // No reset if mode has not changed, but close the menu if not in fullscreen
        if (currentFullScreenMode === this.fullScreenMode) {
            !this.fullScreenMode && opened && this.close()
            return
        }

        this.fullScreenMode = currentFullScreenMode

        if (this.fullScreenMode) {
            this.scrollEvent && this.scrollEvent.dispose()
            this.downEvent && this.downEvent.dispose()
        } else {
            if (!this.scrollEvent || this.scrollEvent.isStopped) {
                this._initScrollEvent()
            }

            // we use a timeout to avoid conflicts with the possible click that provides the position
            if (!this.downEvent || this.downEvent.isStopped) {
                window.setTimeout(this._initClickOutsideEvent, this.props.position ? 100 : 0)
            }
        }

        opened && this.close()
    }

    _toggle = (event, forceClose) => {
        const { fullScreenMode } = this
        const { onToggle, trigger, position } = this.props
        const opened = !forceClose && (!!position || !this.state.opened)

        if (!trigger?.allowEventPropagation) {
            event && event.stopPropagation()
        }

        if (opened) {
            this.openedInFullScreen = fullScreenMode
        } else {
            this.triggerElementRef.current?.focus()
        }

        this.setState({ opened }, () => onToggle && onToggle(opened, this.openedInFullScreen))
    }

    _onTriggerElementClick = (event: React.MouseEvent) => {
        const { trigger, position } = this.props

        if (!trigger?.allowEventPropagation) event.stopPropagation()
        if (!position) this._toggle(event, false)
    }

    /* this method can be used by the parent to force the menu to close */
    close = () => this._toggle(null, true)

    _initScrollEvent() {
        const scroll = Rx.Observable.merge(
            Rx.Observable.fromEvent(window, "scroll"),
            Rx.Observable.fromEvent(document.querySelectorAll(".scrollable"), "scroll"),
        )

        this.scrollEvent = scroll.filter(() => this.state.opened).subscribe(this.close)
    }

    _initClickOutsideEvent = () => {
        const { shouldClose } = this.props

        const clickOutside = (e) => {
            const triggerElementRef = this.triggerElementRef.current
            return (
                !Dom.findParentByClass(e.target, "dropDown") && // only one dropDown should be opened
                e.target !== triggerElementRef &&
                (!triggerElementRef || !triggerElementRef.contains(e.target))
            )
        }

        const eventDown = Rx.Observable.merge(
            Rx.Observable.fromEvent(window, "mousedown"),
            Rx.Observable.fromEvent(window, "touchstart"),
        )

        this.downEvent = eventDown
            .filter(() => this.state.opened)
            .filter((e) => clickOutside(e))
            .subscribe((e: React.MouseEvent | React.TouchEvent) => {
                if (!shouldClose || shouldClose(e)) {
                    this.close()
                }
            })
    }

    render() {
        const { id, fullScreenMode } = this
        const { opened } = this.state
        const {
            container,
            className,
            onScroll,
            buttonWrapperClassName,
            position,
            alignedHorizontally,
            children,
            trigger,
            adaptSizeToTarget,
            forceWidthInPixel,
            maxHeight,
            displayEmptyMenu,
            content,
            dropdownClassName,
            divEventCatcherEnabled,
            horizontalDirectionPriority,
            header,
        } = this.props

        const newProps = trigger?.activeProps
            ? { [trigger.activeProps]: opened && !position }
            : classNames(
                  _.get(trigger?.element, "props.className", ""),
                  opened ? trigger?.activeClassName : "",
              )

        const childrenToRender = (
            content ? transformContentToJsx(content) : children
        ) as React.ReactElement[]

        // if the menu has no items or only contains menu separator, it is not displayed
        return !displayEmptyMenu &&
            !_.find(
                childrenToRender,
                (c: React.ReactElement) => c && c.type !== MenuSeparator,
            ) ? null : (
            <>
                {trigger?.element && (
                    <div
                        id={id}
                        className={buttonWrapperClassName}
                        onClick={!trigger.isDisabled ? this._onTriggerElementClick : undefined}
                        onKeyDown={(e) => {
                            if (
                                !trigger.isDisabled &&
                                trigger.accessibleAttributes &&
                                (e.key === "Enter" || e.key === " ")
                            ) {
                                e.preventDefault()
                                this._toggle(e, false)
                            }
                        }}
                        ref={this.triggerElementRef}
                        aria-expanded={opened}
                        {...trigger.accessibleAttributes}
                    >
                        {React.cloneElement(trigger.element, newProps)}
                    </div>
                )}

                <FullScreenContextMenu
                    {...{
                        id,
                        container,
                        onScroll,
                        opened: opened && fullScreenMode,
                        onClose: this.close,
                        header,
                    }}
                >
                    {childrenToRender}
                </FullScreenContextMenu>

                <DropDownContextMenu
                    {...{
                        id,
                        container,
                        position,
                        className,
                        alignedHorizontally,
                        opened: opened && !fullScreenMode,
                        adaptSizeToTarget,
                        dropdownClassName,
                        onClose: this.close,
                        forceWidthInPixel,
                        onScroll,
                        maxHeight,
                        divEventCatcherEnabled,
                        horizontalDirectionPriority,
                    }}
                >
                    {childrenToRender}
                </DropDownContextMenu>
            </>
        )
    }

    componentDidMount() {
        window.addEventListener("resize", this._handleResize, false)

        if (!this.fullScreenMode) {
            this._initScrollEvent()

            // we use a timeout to avoid conflicts with the possible click that provides the position
            window.setTimeout(this._initClickOutsideEvent, this.props.position ? 100 : 0)
        }
    }

    componentDidUpdate(prevProps: Readonly<IContextMenuProps>) {
        if (!prevProps.position && this.props.position) this._toggle(null, false)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._handleResize, false)

        if (!this.fullScreenMode) {
            this.scrollEvent && this.scrollEvent.dispose()
            this.downEvent && this.downEvent.dispose()
        }

        this.state.opened &&
            this.props.onToggle &&
            this.props.onToggle(false, this.openedInFullScreen)
    }
}

export default ContextMenu
