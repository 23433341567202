import _ from "lodash"

interface IClassList {
    add: (className: string) => void
    remove: (className: string) => void
    contains: (className: string) => boolean
}

const FOCUSABLE_ELEMENTS_SELECTOR =
    'button:not([disabled]):not([tabindex="-1"]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

class Dom {
    static _getClassList(element: Element): IClassList {
        if (element.classList) {
            return element.classList
        } else {
            const classAttribute = element.getAttribute("class") || ""
            const arrayOfClass = classAttribute.split(" ")
            return {
                add: (className) => {
                    !arrayOfClass.includes(className) &&
                        element.setAttribute("class", [...arrayOfClass, className].join(" "))
                },
                remove: (className) => {
                    arrayOfClass.includes(className) &&
                        element.setAttribute("class", _.without(arrayOfClass, className).join(" "))
                },
                contains: (className) => arrayOfClass.includes(className),
            }
        }
    }

    static addClass(element: Element, ...classNames: string[]): void {
        classNames.forEach((className) => {
            this._getClassList(element).add(className)
        })
    }

    static removeClass(element: Element, ...classNames: string[]): void {
        classNames.forEach((className) => {
            this._getClassList(element).remove(className)
        })
    }

    static hasClass(element: Element, names: string[] | string): boolean {
        if (_.isUndefined(element.className)) {
            return false
        }

        names = _.isArray(names) ? names : [names]
        const className = _.find(names, (name) => {
            return element && this._getClassList(element).contains(name)
        })
        return !_.isUndefined(className)
    }

    static hasRole(element: Element, roleName: string): boolean {
        return element.tagName === "BUTTON" || element.getAttribute("role") === roleName
    }

    static ElementOrParentHasClass(element: Element, name: string): boolean {
        return !!(Dom.hasClass(element, name) || Dom.findParentByClass(element, name))
    }

    static findParent(
        element: Element | null,
        searchFct: (target: Element) => boolean,
    ): Element | null {
        let target: Element | null = element
        while (target && !searchFct(target)) {
            target = target.parentNode instanceof Element ? target.parentNode : null // parentElement does not work for svg element on IE11
        }

        return target || null
    }

    static findParentByDataAttr(
        element: Element,
        dataAttr: string,
        value?: string,
    ): { target: Element; value: string | null } | null {
        const searchFct = (target): boolean =>
            !!target.getAttribute(dataAttr) &&
            (_.isUndefined(value) || target.getAttribute(dataAttr) !== value)
        const target = Dom.findParent(element, searchFct)
        return target ? { target, value: target.getAttribute(dataAttr) } : null
    }

    static findParentByClass(element: Element, searchedClass: string[] | string): Element | null {
        return Dom.findParent(element, (target) => Dom.hasClass(target, searchedClass))
    }

    static injectLink(rel: string, type: string): Element {
        const link = document.createElement("link")
        rel && (link.rel = rel)
        type && (link.type = type)

        document.head.appendChild(link)
        return link
    }

    static getScrollValue(): number {
        return Math.max(0, document.documentElement.scrollTop || document.body.scrollTop)
    }

    static onTransitionEnd(
        element: Element,
        callback: (event?: TransitionEvent) => void,
        resolveDelay: number | null = null,
    ): { dispose: () => void } {
        const transitionEndEvents = [
            "webkitTransitionEnd",
            "otransitionend",
            "oTransitionEnd",
            "msTransitionEnd",
            "transitionend",
        ]

        let resolveTimeout: number | undefined
        if (resolveDelay !== null) {
            resolveTimeout = window.setTimeout(() => {
                clean()
                callback()
            }, resolveDelay)
        }

        let done = false
        const clean = (): void => {
            window.clearTimeout(resolveTimeout)
            _.forEach(transitionEndEvents, (eventName) => {
                element.removeEventListener(eventName, transitionEndHandler, false)
            })
            done = true
        }

        const transitionEndHandler = (event: TransitionEvent): void => {
            if (event.target === element) {
                clean()
                callback(event)
            }
        }

        _.forEach(transitionEndEvents, (eventName) => {
            element.addEventListener(eventName, transitionEndHandler, false)
        })

        return { dispose: () => !done && clean() }
    }

    static setCssVariable(key: string, value: string): void {
        const { cssVarPoly } = window

        if (cssVarPoly) {
            cssVarPoly.setVariableValue(key, value)
        } else {
            document.body.style.setProperty(key, value)
        }
    }

    static listenToTabFocuses() {
        const removeClass = () => {
            window.removeEventListener("mousemove", removeClass)
            Dom.removeClass(document.body, "with-focus-decorators")
        }

        window.addEventListener("keydown", (e) => {
            if (e.key === "Tab") {
                Dom.addClass(document.body, "with-focus-decorators")

                window.addEventListener("mousemove", removeClass)
            }
        })
    }

    static getFocusableElements(element?: Element): NodeList | [] {
        if (!element) return []

        return element.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR)
    }

    static getFirstFocusableElement(element: Element): Element | null {
        return element.querySelector(FOCUSABLE_ELEMENTS_SELECTOR)
    }

    static limitFocusInsideAnElementAfterATab(
        element: HTMLElement,
        shiftKeyPressed: boolean,
        preventElementFocusByClassName?: string,
    ) {
        const focusableElements = Dom.getFocusableElements(element)

        if (
            shiftKeyPressed &&
            (document.activeElement === element ||
                (focusableElements && document.activeElement === focusableElements[0]))
        ) {
            focusableElements.length > 0 &&
                setTimeout(() => {
                    const el = focusableElements[focusableElements.length - 1] as HTMLElement
                    el.focus()
                }, 0)
        } else if (
            !shiftKeyPressed &&
            focusableElements.length > 0 &&
            document.activeElement === focusableElements[focusableElements.length - 1]
        ) {
            element.focus()
        }

        setTimeout(() => {
            const hasToKeepFocus =
                focusableElements.length > 0 &&
                element &&
                !Dom.findParent(document.activeElement, (e) => e === element) &&
                (!preventElementFocusByClassName ||
                    (preventElementFocusByClassName &&
                        document.activeElement &&
                        !Dom.ElementOrParentHasClass(
                            document.activeElement,
                            preventElementFocusByClassName,
                        )))

            if (hasToKeepFocus) {
                element.focus()
            }
        }, 0)
    }
}

export default Dom
