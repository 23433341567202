import React, { ReactNode } from "react"

import Button from "common/components/button/Button"
import { classNames } from "common/utils/JSX"

import "./actionCard.less"

export interface IActionCardProps {
    label: string
    iconId: string
    onClick(): void
    thumbnail?: ReactNode
    className?: string
    disabled?: boolean
    ariaAttributes?: React.AriaAttributes
}

const ActionCard: React.FunctionComponent<IActionCardProps> = ({
    iconId,
    label,
    onClick,
    thumbnail,
    className,
    disabled = false,
    ariaAttributes,
}) => (
    <div
        {...classNames("actionCard", className, {
            "actionCard--withThumbnail": !!thumbnail,
        })}
    >
        {thumbnail && <div className="actionCard__thumbnail">{thumbnail}</div>}

        <div className="actionCard__label label--strong">{label}</div>

        <div className="actionCard__button">
            <Button
                iconId={iconId}
                onClick={onClick}
                disabled={disabled}
                dataTestId={"actionCard__button"}
                ariaAttributes={ariaAttributes}
            />
        </div>
    </div>
)

export default ActionCard
