import React from "react"
import _ from "lodash"

import { MODAL_TRANSITION_DURATION_IN_MS } from "common/constants/ANIMATION_TIME"
import { useIsMountedState } from "common/utils/Hooks"
import { classNames } from "common/utils/JSX"

import { IModalContainerProps } from "./ModalContainerTypes"

import "./modalContainer.less"

const ModalContainer: React.FunctionComponent<IModalContainerProps> = ({
    id,
    className,
    size,
    active,
    dragButtonComponent,
    customStyle,
    isExpanded,
    isClosing,
    cancelTransition,
    children,
}) => {
    const mounted = useIsMountedState() // used for fade in
    const fullId = `modal${_.capitalize(id)}`
    const sizeClassName = `modalContainer--${size}`

    return (
        <div
            id={fullId}
            tabIndex={0}
            data-testid={fullId}
            {...classNames("modalContainer", sizeClassName, className, {
                "modalContainer--active": active,
                "modalContainer--visible": mounted && !isClosing,
                "modalContainer--expanded": isExpanded,
            })}
            style={{
                ...customStyle,
                ...(!cancelTransition && {
                    transition: `opacity ${MODAL_TRANSITION_DURATION_IN_MS}ms ease, transform ${MODAL_TRANSITION_DURATION_IN_MS}ms ease`,
                }),
            }}
        >
            <div className="modalContainer__content">{children}</div>

            {dragButtonComponent && (
                <div className="modalContainer__dragButtonContainer">{dragButtonComponent}</div>
            )}
        </div>
    )
}

export default ModalContainer
