import React, { useRef, useState } from "react"
import { Redirect, Route, Switch, useHistory, withRouter } from "react-router-dom"
import _ from "lodash"

import { IStepperProps } from "@/components/stepper/Stepper"
import { ChooseNameCard } from "@/screens/cards/chooseNameCard/ChooseNameCard"
import {
    ConfigureEmployeeAccountCard,
    getConfigureEmployeeAccountCardTitle,
} from "@/screens/cards/configureEmployeeAccountCard/ConfigureEmployeeAccountCard"
import { DetailedOfferCard } from "@/screens/cards/detailedOfferCard/DetailedOfferCard"
import HubsListCard from "@/screens/cards/hubsListCard/HubsListCard"
import { LoadingCard } from "@/screens/cards/loadingCard/LoadingCard"
import { getOffersCardTitle, OffersCard } from "@/screens/cards/offersCard/OffersCard"
import { SuccessCard } from "@/screens/cards/successCard/SuccessCard"
import UserCardContainer from "@/screens/cards/userCard/UserCardContainer"
import {
    createEmployeeHub as createEmployeeHubApi,
    createHub as createHubApi,
} from "@/services/api/PortalAPI"
import { IExistingSelfManagedHub } from "@/services/api/PortalAPI"
import { I18n } from "@/services/i18n/I18n"

import AuthFacade from "common/services/auth/AuthFacade"
import EngineFacade from "common/services/engine/EngineFacade"
import PersistenceFacade from "common/services/persistence/PersistenceFacade"
import { FORMAT_TAXES, IOffer, ISelectedOption, OFFER_GROUPS_IDS } from "common/types/OfferTypes"
import Url from "common/utils/Url"

import "./portal.less"

interface IPortalProps {
    onError: (err: any) => void
    offers: IOffer[]
    userMyUbList: IExistingSelfManagedHub[]
}

interface IRouteStepperConfig {
    step: number
    title: string
    optional?: boolean
}

interface IStartUpHashValues {
    language?: string
    referrerLink?: string
    referrerFree?: string
    offerGroupId?: OFFER_GROUPS_IDS
}

enum ROUTES {
    ACCOUNT = "/account",
    SIGN_IN = "/sign-in",
    HUBS = "/my-hubs",
    OFFERS = "/offers",
    DETAILED_OFFERS = "/detailed-offers",
    CHOOSE_NAME = "/choose-hub-name",
    CREATION_RECAP = "/hub-creation-recap",
    CONFIGURE_EMPLOYEE_ACCOUNT = "/bee-welcome",
    EMPLOYEE_CREATION_RECAP = "/bee-ready",
}

const getPageTitleFromRoute = (route: string) => {
    switch (route) {
        case ROUTES.HUBS:
            return I18n.getString("page.title.hubsList")
        default:
            return I18n.getString("page.title.creation")
    }
}

interface IRouteStepperStepsConfig {
    [route: string]: IRouteStepperConfig
}

const getRoutesStepperConfig = (): IRouteStepperStepsConfig => ({
    [ROUTES.OFFERS]: { step: 1, title: getOffersCardTitle() },
    [ROUTES.DETAILED_OFFERS]: { step: 1, title: DetailedOfferCard.title, optional: true },
    [ROUTES.CHOOSE_NAME]: { step: 2, title: ChooseNameCard.title },
    [ROUTES.CREATION_RECAP]: { step: 3, title: SuccessCard.title },
})

const getConfigureEmployeeStepperConfig = (): IRouteStepperStepsConfig => ({
    [ROUTES.CONFIGURE_EMPLOYEE_ACCOUNT]: { step: 1, title: getConfigureEmployeeAccountCardTitle() },
    [ROUTES.EMPLOYEE_CREATION_RECAP]: { step: 2, title: SuccessCard.title },
})

const getStepperProps = (route: ROUTES, config: IRouteStepperStepsConfig): IStepperProps => {
    const totalSteps = _.reduce(config, (total, { step }) => Math.max(total, step), 0)
    const routeStepperConfig = config[route]
    const nextRouteStepperConfig = _.find(
        config,
        ({ step }) => step === routeStepperConfig.step + 1,
    )

    return {
        totalSteps: totalSteps,
        currentStep: routeStepperConfig.step,
        currentStepTitle: routeStepperConfig.title,
        nextStepTitle: nextRouteStepperConfig?.title,
        optionalStep: routeStepperConfig.optional,
    }
}

/* renderless component */
const PageTitleUpdater = withRouter(({ location }) => {
    const pageTitle = document.getElementById("pageTitle")
    if (pageTitle) pageTitle.innerHTML = getPageTitleFromRoute(location.pathname)
    return null
})

const shouldRedirectTo = (): string => PersistenceFacade.hash!.redirectTo.get()

const getLanguage = (): string => PersistenceFacade.hash!.language.get()

const getReferrerFree = (): string => PersistenceFacade.hash!.referrerFree.get()

const getReferrerLink = (): string => PersistenceFacade.hash!.referrerLink.get()

const getOfferGroupId = (): OFFER_GROUPS_IDS | undefined => {
    const offerGroupIdFromHash = PersistenceFacade.hash!.offer.get()
    return offerGroupIdFromHash in OFFER_GROUPS_IDS ? offerGroupIdFromHash : undefined
}

const setStartUpHashValues = (values: IStartUpHashValues) => {
    values.referrerFree && PersistenceFacade.hash!.referrerFree.set(values.referrerFree)
    values.referrerLink && PersistenceFacade.hash!.referrerLink.set(values.referrerLink)
    values.offerGroupId && PersistenceFacade.hash!.offer.set(values.offerGroupId)
    values.language && PersistenceFacade.hash!.language.set(values.language)
}

const signIn = (startUpHashValues: IStartUpHashValues) => {
    setStartUpHashValues(startUpHashValues)
    AuthFacade.login(true)
}

const changeAccount = () => {
    const url = window.location.href.replace(ROUTES.ACCOUNT, ROUTES.SIGN_IN)
    AuthFacade.logout(
        Url.addHashParams(url, {
            [PersistenceFacade.hash!.redirectTo.key]: ROUTES.OFFERS,
        }),
    )
}

export const Portal: React.FunctionComponent<IPortalProps> = ({
    offers,
    userMyUbList,
    onError,
}) => {
    const [formatTaxes, setFormatTaxes] = useState(FORMAT_TAXES.default)
    const [selectedOfferPlan, setSelectedOfferPlan] = useState("")
    const [selectedOfferOptions, setSelectedOfferOptions] = useState<ISelectedOption[]>()
    const [hubUrl, setHubUrl] = useState<string>()
    let { current: redirectTo } = useRef(shouldRedirectTo())
    const { current: language } = useRef(getLanguage())
    const { current: referrerFree } = useRef(getReferrerFree())
    const { current: referrerLink } = useRef(getReferrerLink())
    const { current: offerGroupId } = useRef(getOfferGroupId())
    const history = useHistory()
    const goTo = (route) => history.push(route)
    const isAuthenticated = AuthFacade.isAuthenticated()

    const onNameSelected = async (name: string): Promise<void> => {
        goTo(ROUTES.CREATION_RECAP)

        try {
            const { libraryUrl } = await createHubApi(
                name,
                selectedOfferPlan,
                selectedOfferOptions,
                {
                    referrerLink,
                    referrerFree,
                },
            )
            setHubUrl(libraryUrl)
        } catch (err) {
            onError(err)
        }
    }

    const createEmployeeHub = async (): Promise<void> => {
        goTo(ROUTES.EMPLOYEE_CREATION_RECAP)

        try {
            const { libraryUrl } = await createEmployeeHubApi()
            setHubUrl(libraryUrl)
        } catch (err) {
            onError(err)
        }
    }

    const logoUrl = Url.join(EngineFacade.baseEngineUrl, "assets", "logos", "ubstream", "white.svg")

    return (
        <div className="portal">
            <div className="portal__header">
                <div className="portal__logoContainer">
                    {isAuthenticated && (
                        <img
                            src={logoUrl}
                            className={"portal__logo"}
                            alt={I18n.getString("common.global.logo.alt")}
                        />
                    )}
                </div>
            </div>

            <div className="portal__body">
                <Switch>
                    <Route
                        path={ROUTES.SIGN_IN}
                        exact
                        render={() => {
                            if (!isAuthenticated) {
                                redirectTo = redirectTo || ROUTES.HUBS
                                signIn({ language, referrerFree, referrerLink, offerGroupId })
                            }

                            return isAuthenticated && <Redirect to={redirectTo || ROUTES.HUBS} />
                        }}
                    />

                    <Route
                        path={ROUTES.ACCOUNT}
                        exact
                        render={() => {
                            if (!isAuthenticated) {
                                redirectTo = ROUTES.OFFERS
                                return <Redirect to={ROUTES.SIGN_IN} />
                            }

                            return (
                                <UserCardContainer
                                    continueWithAccount={() => history.push(ROUTES.OFFERS)}
                                    useAnotherAccount={changeAccount}
                                />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.OFFERS}
                        exact
                        render={() => {
                            if (!isAuthenticated) {
                                redirectTo = ROUTES.OFFERS
                                return <Redirect to={ROUTES.SIGN_IN} />
                            }

                            return (
                                <OffersCard
                                    stepperProps={getStepperProps(
                                        ROUTES.OFFERS,
                                        getRoutesStepperConfig(),
                                    )}
                                    setFormatTaxes={setFormatTaxes}
                                    formatTaxes={formatTaxes}
                                    offers={offers}
                                    userMyUbList={userMyUbList}
                                    onOfferSelected={(offerPlan) => {
                                        setSelectedOfferPlan(offerPlan)
                                        goTo(ROUTES.CHOOSE_NAME)
                                    }}
                                    goToDetailedOffer={(offerPlan) => {
                                        setSelectedOfferPlan(offerPlan)
                                        goTo(ROUTES.DETAILED_OFFERS)
                                    }}
                                    offerGroupId={offerGroupId}
                                />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.DETAILED_OFFERS}
                        exact
                        render={() => {
                            if (selectedOfferPlan === "") return <Redirect to={ROUTES.OFFERS} />

                            return (
                                <DetailedOfferCard
                                    stepperProps={getStepperProps(
                                        ROUTES.DETAILED_OFFERS,
                                        getRoutesStepperConfig(),
                                    )}
                                    offers={offers}
                                    formatTaxes={formatTaxes}
                                    selectedOfferPlan={selectedOfferPlan}
                                    goBackToOffers={() => goTo(ROUTES.OFFERS)}
                                    onOfferSelected={(offerPlan, offerOptions): void => {
                                        setSelectedOfferPlan(offerPlan)
                                        offerOptions && setSelectedOfferOptions(offerOptions)
                                        goTo(ROUTES.CHOOSE_NAME)
                                    }}
                                />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.CHOOSE_NAME}
                        exact
                        render={() => {
                            if (selectedOfferPlan === "") return <Redirect to={ROUTES.OFFERS} />

                            return (
                                <ChooseNameCard
                                    stepperProps={getStepperProps(
                                        ROUTES.CHOOSE_NAME,
                                        getRoutesStepperConfig(),
                                    )}
                                    goBackToOffers={() => goTo(ROUTES.OFFERS)}
                                    onNameSelected={onNameSelected}
                                />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.CREATION_RECAP}
                        exact
                        render={() => {
                            if (selectedOfferPlan === "") return <Redirect to={ROUTES.OFFERS} />

                            return hubUrl ? (
                                <SuccessCard
                                    stepperProps={getStepperProps(
                                        ROUTES.CREATION_RECAP,
                                        getRoutesStepperConfig(),
                                    )}
                                    hubUrl={hubUrl}
                                    goToHub={() => window.open(hubUrl, "_self")}
                                />
                            ) : (
                                <LoadingCard mainTitle={I18n.getString("card.loadingCard.title")} />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.HUBS}
                        exact
                        render={() => {
                            if (!isAuthenticated) {
                                redirectTo = ROUTES.HUBS
                                return <Redirect to={ROUTES.SIGN_IN} />
                            }
                            if (userMyUbList.length === 0) return <Redirect to={ROUTES.OFFERS} />

                            return (
                                <HubsListCard
                                    hubs={userMyUbList}
                                    goToHubCreation={() => goTo(ROUTES.OFFERS)}
                                />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.CONFIGURE_EMPLOYEE_ACCOUNT}
                        exact
                        render={() => {
                            if (!isAuthenticated) {
                                redirectTo = ROUTES.CONFIGURE_EMPLOYEE_ACCOUNT
                                return <Redirect to={ROUTES.SIGN_IN} />
                            }

                            return (
                                <ConfigureEmployeeAccountCard
                                    stepperProps={getStepperProps(
                                        ROUTES.CONFIGURE_EMPLOYEE_ACCOUNT,
                                        getConfigureEmployeeStepperConfig(),
                                    )}
                                    createEmployeeLibrary={createEmployeeHub}
                                    backToPortal={() => goTo(ROUTES.HUBS)}
                                />
                            )
                        }}
                    />

                    <Route
                        path={ROUTES.EMPLOYEE_CREATION_RECAP}
                        exact
                        render={() => {
                            if (!isAuthenticated) {
                                redirectTo = ROUTES.EMPLOYEE_CREATION_RECAP
                                return <Redirect to={ROUTES.SIGN_IN} />
                            }

                            return hubUrl ? (
                                <SuccessCard
                                    stepperProps={getStepperProps(
                                        ROUTES.EMPLOYEE_CREATION_RECAP,
                                        getConfigureEmployeeStepperConfig(),
                                    )}
                                    hubUrl={hubUrl}
                                    goToHub={() => window.open(hubUrl, "_self")}
                                />
                            ) : (
                                <LoadingCard mainTitle={I18n.getString("card.loadingCard.title")} />
                            )
                        }}
                    />

                    <Redirect to={ROUTES.ACCOUNT} />
                </Switch>

                {isAuthenticated && <PageTitleUpdater />}
            </div>
        </div>
    )
}
