import React from "react"
import _ from "lodash"

import { AuthenticatedHttp, CancelToken } from "common/utils/Http"

export const EMPTY_SRC =
    "data:image/gif;base64,R0lGODlhAQABAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAABAAEAAAICVAEAOw=="

class ProtectedImg extends React.Component {
    constructor(props) {
        super(props)

        this.ref = React.createRef()
        this.isLoading = false
    }

    _loadImage() {
        this.isLoading && this.blobRequest?.cancel()
        this.isLoading = true

        const { options = {}, callback = () => {} } = this.props
        const { cache = null } = options
        const requestId = (this.requestId = _.uniqueId())
        const src = this.ref.current.getAttribute("data-src")

        if (src) {
            const getBlob = () => {
                const valueFromCache = cache ? cache.get(src) : null

                this.blobRequest = CancelToken.source()

                if (valueFromCache) {
                    return Promise.resolve(valueFromCache)
                } else {
                    return AuthenticatedHttp.get(src, {
                        ignoreDiagnoses: true,
                        configOverride: {
                            responseType: "blob",
                            cancelToken: this.blobRequest?.token,
                        },
                    })
                }
            }

            getBlob()
                .then((blob) => {
                    if (cache) {
                        cache.set(src, blob)
                    }

                    if (!this.unmounted && requestId === this.requestId) {
                        this.src && URL.revokeObjectURL(this.src)
                        this.src = URL.createObjectURL(blob)
                        this.ref.current.src = this.src
                        callback && callback(this.src)
                    }
                })
                .catch((err) => {
                    this.props.onError && this.props.onError(err)
                })
        }
    }

    componentDidMount() {
        this.ref.current.src = EMPTY_SRC
        this.ref.current.isLoading = false

        const { src: initialSrc } = this.props
        if (initialSrc) {
            this.ref.current.setAttribute("data-src", initialSrc)
            this._loadImage()
        }

        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === "data-src") {
                    this._loadImage()
                }
            })
        })

        this.observer.observe(this.ref.current, { attributes: true })
    }

    componentDidUpdate(prevProps) {
        this.isLoading = false
        if (prevProps.src !== this.props.src) {
            this.blobRequest?.cancel()
            this.ref.current.setAttribute("data-src", this.props.src)
            this.ref.current.setAttribute("src", EMPTY_SRC)
            this._loadImage()
        }
    }

    componentWillUnmount() {
        this.unmounted = true

        this.src && URL.revokeObjectURL(this.src)
        this.isLoading = false
        this.blobRequest?.cancel()
        this.ref.current.src = ""
        this.observer?.disconnect()
    }

    render() {
        const props = _.assign({}, this.props, {
            options: undefined,
            callback: undefined,
        })

        return <img ref={this.ref} data-src="" {...props} data-testid="protectedImg" />
    }
}

export default (props) => {
    const { src = "" } = props

    // If src is a direct link to a file (instead of an api url):
    if (/\.[a-z0.9]+$/.test(src) || src.startsWith("blob:") || src === EMPTY_SRC) {
        const cleanedProps = _.assign({}, props, { options: undefined, callback: undefined })
        return <img {...cleanedProps} data-testid="protectedImg_rawImg" />
    } else {
        return <ProtectedImg {...props} />
    }
}
