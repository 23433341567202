import React from "react"
import _ from "lodash"

import FeatureList from "common/components/detailedOffer/components/featureList/FeatureList"
import { PRICE_SIZE } from "common/components/detailedOffer/components/offerPrice/OFFER_PRICE"
import OfferPrice from "common/components/detailedOffer/components/offerPrice/OfferPrice"
import { FEATURE_ID } from "common/components/detailedOffer/DETAILED_OFFER"
import {
    FORMAT_TAXES,
    IOffer,
    IOfferFeatureValue,
    OFFER_GROUPS_IDS,
    PLAN_GROUP_MAPPING,
} from "common/types/OfferTypes"

import "./offerSummary.less"

interface IOfferSummaryProps {
    offer: IOffer
    optionQuantity: number
    formatTaxes?: FORMAT_TAXES
}

const OfferSummary: React.FunctionComponent<IOfferSummaryProps> = ({
    offer,
    optionQuantity,
    formatTaxes = FORMAT_TAXES.default,
}) => {
    const withoutTaxes =
        formatTaxes === FORMAT_TAXES.default
            ? PLAN_GROUP_MAPPING[offer.plan] === OFFER_GROUPS_IDS.business
            : formatTaxes === FORMAT_TAXES.withoutTaxes

    const totalPrice: number =
        offer.priceInCents + (offer.options[0]?.priceInCents ?? 0) * optionQuantity

    const computeValue = (featureId: FEATURE_ID, value: IOfferFeatureValue): IOfferFeatureValue => {
        const currentFeature = _.find(
            offer.options[0]?.features,
            (feature) => feature.featureId === featureId,
        )

        let optionValue = ((currentFeature?.value as number) ?? 0) * optionQuantity

        if (_.isNumber(value)) {
            optionValue = _.isNumber(optionValue) ? optionValue : 0

            return value + optionValue
        }

        return value
    }

    return (
        <div className="offerSummary">
            <FeatureList
                featureList={offer.features}
                computeValue={computeValue}
                showOnlyEditableFeatures
                options={offer.options}
            />
            <div data-testid="totalOfferPrice">
                <OfferPrice
                    priceInCents={totalPrice}
                    priceSize={PRICE_SIZE.STRONG}
                    withoutTaxes={withoutTaxes}
                />
            </div>
        </div>
    )
}

export default OfferSummary
