import {
    applyMiddleware,
    compose,
    createStore,
    Middleware,
    Reducer,
    Store as ReduxStore,
} from "redux"
import thunk from "redux-thunk"

class Store {
    private store: ReduxStore | undefined

    createStore(rootReducers: Reducer, ...middleWares: Middleware[]): ReduxStore {
        this.store = createStore(rootReducers, compose(applyMiddleware(thunk, ...middleWares)))
        return this.store
    }

    getStore(): ReduxStore {
        if (!this.store) {
            throw new Error("Store is not initialized")
        }
        return this.store
    }
}

export default new Store()
