import React from "react"
import { connect } from "react-redux"

import Stepper, { IStepperProps } from "@/components/stepper/Stepper"
import { I18n } from "@/services/i18n/I18n"
import { IHelpDialog, showHelpDialog } from "@/store/actions/ModalActions"
import IconList from "@/styles/IconList"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"
import Icon from "common/components/icon/Icon"
import { IDispatch } from "common/state/StoreTypes"
import { classNames } from "common/utils/JSX"

import "./portalCard.less"

interface IPortalCardButtons {
    onPrevious: () => void
    onNext: () => void
    onNextDisabled?: boolean
}

interface IPortalCardProps {
    stepperProps?: IStepperProps
    mainTitle?: string
    buttons?: IPortalCardButtons
    helpDialog?: IHelpDialog
    isMaxWidth?: boolean
}

interface IPortalCardDispatch {
    showHelp(): void
}

const PortalCardComponent: React.FunctionComponent<IPortalCardProps & IPortalCardDispatch> = ({
    stepperProps,
    mainTitle,
    buttons,
    helpDialog,
    children,
    isMaxWidth,
    showHelp,
}) => (
    <div {...classNames("portalCard", { "portalCard--maxWidth": isMaxWidth })}>
        <div className="portalCard__header">
            {stepperProps && <Stepper {...stepperProps} />}
            {mainTitle && <h2 className="portalCard__mainTitle label--strong">{mainTitle}</h2>}
            {!!helpDialog && (
                <button className="portalCard__helpButton" tabIndex={1} onClick={showHelp}>
                    <Icon id={IconList.info_help_lg} />
                </button>
            )}
        </div>

        <div className="portalCard__content">{children}</div>

        {buttons && (
            <div className="portalCard__footer">
                <Button
                    onClick={buttons.onPrevious}
                    label={I18n.getString("common.global.back")}
                    actionType={ACTION_TYPE.secondary}
                />
                <Button
                    onClick={buttons.onNext}
                    label={I18n.getString("common.global.continue")}
                    actionType={ACTION_TYPE.primary}
                    tag={BUTTON_TAG.success}
                    disabled={buttons.onNextDisabled}
                />
            </div>
        )}
    </div>
)

const mapDispatchToProps = (
    dispatch: IDispatch,
    ownProps: IPortalCardProps,
): IPortalCardDispatch => ({
    showHelp: () => dispatch(showHelpDialog(ownProps.helpDialog!)),
})

export const PortalCard = connect(null, mapDispatchToProps)(PortalCardComponent)
