import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import _ from "lodash"

import "current-device"

import { App } from "@/screens/App"
import { LoadingCard } from "@/screens/cards/loadingCard/LoadingCard"
import * as PortalAPI from "@/services/api/PortalAPI"
import { IExistingSelfManagedHub } from "@/services/api/PortalAPI"
import KeycloakClient from "@/services/auth/KeycloakClient"
import PortalConfig from "@/services/config/PortalConfig"
import {
    initAuthFacade,
    initEngineFacade,
    initLang,
    initPersistenceFacade,
    initStore,
    initUiKit,
} from "@/services/Init"

import ErrorPageRenderer from "common/screens/errorPage/ErrorPageRenderer"
import AuthFacade from "common/services/auth/AuthFacade"
import EngineFacade from "common/services/engine/EngineFacade"
import InitCssVariablePolyfill from "common/style/polyfills/cssVar/CssVariablePolyfill"
import { IOffer } from "common/types/OfferTypes"
import { readFile } from "common/utils/File"
import { Http } from "common/utils/Http"
import Url from "common/utils/Url"

import "common/style/reset.less"
import "@/styles/global.less"

const onError = (err: any): void => {
    const logout = () => AuthFacade.logout()
    const goBack = () => (document.location.href = "/")

    console.error(err)
    ErrorPageRenderer.render(document.getElementById("portal")!, {
        code: err && err.status,
        $diagnoses: _.get(err, "data.$diagnoses"),
        logout,
        goBack,
    })
}

const renderInitialLoader = (): void => {
    ReactDOM.render(<LoadingCard transparent fullscreen />, document.getElementById("portal"))
}

const setBackground = () => {
    const url = Url.join(EngineFacade.baseEngineUrl, "authAssets", "background", "portal.jpg")
    return Http.get(url, { configOverride: { responseType: "blob" } })
        .then((blobFile) => readFile(blobFile, true))
        .then((imageData) => (document.body.style.backgroundImage = `url(${imageData})`))
}

const renderPortal = async (isAuthenticated: boolean) => {
    let offers: Array<IOffer> = [],
        userMyUbList: Array<IExistingSelfManagedHub> = []

    if (isAuthenticated) {
        offers = await PortalAPI.getOffers()
        userMyUbList = await PortalAPI.getExistingLibraries()
    }

    ReactDOM.render(
        <BrowserRouter>
            <App onError={onError} offers={offers} userMyUbList={userMyUbList} />
        </BrowserRouter>,
        document.getElementById("portal"),
    )
}
;(async () => {
    try {
        renderInitialLoader()

        await PortalConfig.init()
        const isAuthenticated = await KeycloakClient.init()

        initEngineFacade()
        initPersistenceFacade()

        if (isAuthenticated) {
            await setBackground()
            await initLang()
            await initAuthFacade()
            await initUiKit()
            await InitCssVariablePolyfill()
        }

        initStore()

        await renderPortal(isAuthenticated)
    } catch (e) {
        onError(e)
    }
})()
