import { hideAsyncImportLoader, showAsyncImportLoader } from "common/state/actions/ModalActions"
import Store from "common/state/store/Store"

const { setTimeout, clearTimeout } = window

export const doAsyncImport = <T>(
    importCallback: () => Promise<T>,
    showLoader = false,
): Promise<T> => {
    const openTimer = setTimeout(() => {
        showLoader && Store.getStore().dispatch(showAsyncImportLoader())
    }, 250)

    return importCallback().then((asyncImport) => {
        openTimer && clearTimeout(openTimer)
        showLoader && Store.getStore().dispatch(hideAsyncImportLoader())
        return asyncImport
    })
}

export const doAsyncImportDefault = async <Z, T extends { default: Z }>(
    importCallback: () => Promise<T>,
    showLoader = false,
): Promise<T["default"]> => {
    return (await doAsyncImport(importCallback, showLoader)).default
}

function AsyncImport<Z, T extends { default: Z }>(
    AsyncImport: () => Promise<T>,
    showLoader?: boolean,
): Promise<T["default"]>
function AsyncImport<Z, T extends { default: Z }>(
    AsyncImport: () => Promise<T>,
    showLoader: boolean,
    exportDefault: false,
): Promise<T>

function AsyncImport<Z, T extends { default: Z }>(
    AsyncImport: () => Promise<T>,
    showLoader?: boolean,
    exportDefault?: false,
): Promise<T["default"] | T> {
    return exportDefault !== false
        ? doAsyncImportDefault(AsyncImport, showLoader)
        : doAsyncImport(AsyncImport, showLoader)
}

export default AsyncImport
