import _ from "lodash"

import COMMON_PERSISTENCE_CONFIG from "common/services/persistence/PersistenceConfig"
import {
    PERSISTENCE_INTERFACES,
    PERSISTENCE_TYPES,
} from "common/services/persistence/PersistenceTypes"
import VALUE_TYPES from "common/services/persistence/ValueTypes"
class PersistenceFacade {
    initialize({ hash = {}, localStorage = {}, sessionStorage = {} }) {
        /* HASH VARIABLES */
        this.hash = _.assign(COMMON_PERSISTENCE_CONFIG.hash, hash)

        /* LOCAL STORAGE VARIABLES */
        this.localStorage = _.assign(COMMON_PERSISTENCE_CONFIG.localStorage, localStorage)

        /* SESSION STORAGE VARIABLES */
        this.sessionStorage = _.assign(COMMON_PERSISTENCE_CONFIG.sessionStorage, sessionStorage)

        PersistenceFacade._registerPersistenceVariables(this.hash, PERSISTENCE_TYPES.HASH)
        PersistenceFacade._registerPersistenceVariables(
            this.localStorage,
            PERSISTENCE_TYPES.LOCAL_STORAGE,
        )
        PersistenceFacade._registerPersistenceVariables(
            this.sessionStorage,
            PERSISTENCE_TYPES.SESSION_STORAGE,
        )
    }

    static _makeGetter(valueType, persistenceInterface, defaultValue) {
        return (key) => {
            try {
                let value = persistenceInterface.read(key)
                value = !_.isUndefined(value) ? VALUE_TYPES.decodeValue(valueType, value) : value
                return _.isUndefined(value) && !_.isUndefined(defaultValue) ? defaultValue : value
            } catch (err) {
                console.error(err)

                persistenceInterface.clear()
                return defaultValue || undefined
            }
        }
    }

    static _makeSetter(valueType, persistenceInterface) {
        return (key, value) => {
            value = !_.isUndefined(value) ? VALUE_TYPES.encodeValue(valueType, value) : value
            persistenceInterface.write(key, value)
        }
    }

    static _getInterface(persistenceType) {
        return PERSISTENCE_INTERFACES[persistenceType]
    }

    static _registerPersistenceVariables(persistenceObject, persistenceType) {
        _.forEach(persistenceObject, (config, name) => {
            const { type = VALUE_TYPES.STRING, defaultValue } = config
            const key = config.key || name
            const persistenceInterface = PersistenceFacade._getInterface(persistenceType)

            const get = PersistenceFacade._makeGetter(type, persistenceInterface, defaultValue)
            const set = PersistenceFacade._makeSetter(type, persistenceInterface)

            if (_.isFunction(key)) {
                persistenceObject[name] = {
                    for: (...args) => {
                        const dynamicKey = key(...args)
                        const getterSetter = {
                            get: () => get(dynamicKey),
                            set: (value) => set(dynamicKey, value),
                            delete: () => set(dynamicKey, undefined),
                        }
                        return _.merge(
                            { key: dynamicKey },
                            getterSetter,
                            VALUE_TYPES.getSpecificMethods(getterSetter, type),
                        )
                    },
                }
            } else {
                const getterSetter = {
                    get: () => get(key),
                    set: (value) => set(key, value),
                    delete: () => set(key, undefined),
                }
                persistenceObject[name] = _.merge(
                    { key },
                    getterSetter,
                    VALUE_TYPES.getSpecificMethods(getterSetter, type),
                )
            }
        })
    }
}

export default new PersistenceFacade()

// exports for tests
export { PersistenceFacade }
