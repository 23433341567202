export enum MODAL_CONTAINER_SIZE {
    AUTO = "auto",
    SMALL = "small",
    MEDIUM = "medium",
    MEDIUM_LARGE = "medium-large",
    LARGE = "large",
    FULL = "full",
    ADAPTIVE_WIDTH = "adaptive-width",
}

export interface IModalContainerProps {
    id: string
    size: MODAL_CONTAINER_SIZE
    isExpanded?: boolean
    isClosing?: boolean
    active: boolean
    className?: string
    customStyle?: React.CSSProperties
    dragButtonComponent?: JSX.Element
    cancelTransition?: boolean
}
