import _ from "lodash"

interface IClassNamesInputObject {
    [key: string]: any // will be evaluated to truthy or falsy
}

type IClassNamesInput = (string | IClassNamesInputObject | null | undefined | false)[]

/** @deprecated: use the bem utility instead */
export const classNames = (...input: IClassNamesInput): { className: string } => {
    const getClassNamesFromObject = (obj: IClassNamesInputObject): string[] =>
        _.reduce(obj, (list, value, c) => (value ? _.concat(list, _.trim(c)) : list), [])

    const classNamesList = _.reduce(
        input,
        (list, c) =>
            c ? _.concat(list, _.isObject(c) ? getClassNamesFromObject(c) : _.trim(c)) : list,
        [],
    )

    return { className: _.uniq(_.compact(classNamesList)).join(" ") }
}

export const preventDrag = {
    draggable: "true",
    onDragStart: (event: Event): void => {
        event.preventDefault()
        event.stopPropagation()
    },
}

export interface ITransformStyleInput {
    height: number
    width: number
    x: number
    y: number
}

interface ITransformStyle {
    transform: string
    width: string
    height: string
}

export const getTransformStyle = (element: ITransformStyleInput): ITransformStyle => ({
    transform: `translate(${Math.round(element.x)}px, ${Math.round(element.y)}px)`,
    width: `${element.width}px`,
    height: `${element.height}px`,
})
