import React, { useEffect, useState } from "react"

import FloatingCard, {
    IFloatingCard,
} from "common/components/contextMenu/components/floatingCards/FloatingCards"
import FloatingCards from "common/components/contextMenu/components/floatingCards/FloatingCards"
import SubMenu, { ISubMenuProps } from "common/components/contextMenu/SubMenu"
import EntityBlock, { IEntityBlockProps } from "common/components/entityBlock/EntityBlock"
import IconList from "common/components/icon/IconList"
import { getRightIcon } from "common/components/menu/components/menuItem/BasicItemHelper"
import { IMenuItemButton } from "common/components/menu/components/menuItem/MenuItemTypes"
import { BasicMenuItem, CustomMenuItem } from "common/components/menu/Menu"

interface IFullScreenContextMenuProps {
    opened: boolean
    id: string
    onClose(): void
    container?: Element
    onScroll?: React.UIEventHandler
    header?: IEntityBlockProps // todo : make it mandatory when every menu has a header
}

const ROOT_LEVEL_ID = "root"

const isReactElement = (node: React.ReactNode): node is React.ReactElement =>
    !!node && typeof node === "object" && "type" in node

const isSubMenu = (
    node: React.ReactNode,
): node is React.ReactElement<ISubMenuProps, typeof SubMenu> =>
    isReactElement(node) && node.type === SubMenu

const FullScreenContextMenu: React.FunctionComponent<IFullScreenContextMenuProps> = ({
    container,
    opened,
    id: contextMenuId,
    onClose,
    children,
    onScroll,
    header,
}) => {
    const [activeCardId, setActiveCardId] = useState<string>(ROOT_LEVEL_ID)

    // Reset the current level after the menu's closing transition ends
    useEffect(() => {
        if (opened) return

        const t = window.setTimeout(() => setActiveCardId(ROOT_LEVEL_ID), 300)
        return () => clearTimeout(t)
    }, [opened])

    const getClonedMenuItem = (
        node: React.ReactElement,
        cardId: string,
        index: number,
    ): React.ReactElement => {
        // override menu items click to automatically close the menu
        // use a noClose flag on menu items to avoid that behaviour
        const menuItemClickHandler =
            (originalHandler: Function, noClose: boolean) =>
            (...args: any) => {
                // setTimeout needed to avoid conflict between menu closing and feature opening
                originalHandler && window.setTimeout(() => originalHandler(...args))
                !noClose && onClose()
            }

        const buttonItem: IMenuItemButton = node.props.button
            ? {
                  ...node.props.button,
                  onClick: menuItemClickHandler(node.props.button.onClick, node.props.noClose),
              }
            : undefined

        return React.cloneElement(node, {
            key: node.key || `card_${cardId}_${index}`,
            onClick: menuItemClickHandler(node.props.onClick, node.props.noClose),
            button: buttonItem,
        })
    }

    const getCards = (): Array<IFloatingCard> => {
        const cards: Array<IFloatingCard> = []

        const fillCard = (
            node: React.ReactNode,
            cardLabel?: string,
            id = ROOT_LEVEL_ID,
            parentId?: string,
        ) => {
            const nodeChildren: React.ReactNode = isReactElement(node) ? node.props.children : node
            const goBack = () => parentId && setActiveCardId(parentId)

            const card = {
                id: id,
                header: parentId ? (
                    <FloatingCards.Header title={cardLabel} onClick={goBack} />
                ) : header ? (
                    <EntityBlock {...header} noPadding />
                ) : undefined,
                content: [] as Array<React.ReactNode>,
            }

            React.Children.forEach<React.ReactNode>(nodeChildren, (child, index) => {
                if (!child) {
                    return null
                } else if (isSubMenu(child)) {
                    const subLevelId = child.props.id
                        ? `${contextMenuId}_subLevel_${child.props.id}`
                        : `${contextMenuId}_subLevel_${id}_${index}`

                    card.content.push(
                        child.props.customRender ? (
                            <CustomMenuItem
                                key={subLevelId}
                                onClick={() => setActiveCardId(subLevelId)}
                                withHover
                            >
                                {child.props.customRender}
                            </CustomMenuItem>
                        ) : (
                            <BasicMenuItem
                                key={subLevelId}
                                label={child.props.label}
                                mainIcon={child.props.iconId}
                                rightElements={[getRightIcon(IconList.navi_next_sm)]}
                                onClick={() => setActiveCardId(subLevelId)}
                                disabled={child.props.disabled}
                            />
                        ),
                    )
                    fillCard(child, child.props.label, subLevelId, id)
                } else if (isReactElement(child)) {
                    card.content.push(getClonedMenuItem(child, id, index))
                }
            })

            cards.push(card)
        }

        fillCard(children)

        return cards
    }

    return (
        <FloatingCard
            container={container}
            visible={opened}
            currentId={activeCardId}
            onClose={onClose}
            onScroll={onScroll}
            cards={getCards()}
        />
    )
}

export default FullScreenContextMenu
