import _ from "lodash"
import * as UrlParam from "query-string"

class Url {
    static join(...args: string[]): string {
        return args.join("/").replace(/([^:])\/+/g, "$1/")
    }

    static addQueryParams(url: string, data: object): string {
        const [baseQueryParams, hashString] = url.split("#")
        const urlQueryParams = Url.addSuffixedParams(baseQueryParams, data, "?")
        return Url.addHashString(urlQueryParams, hashString)
    }

    static removeQueryParams(url: string, keysToRemove: string[] | string): string {
        const [baseUrlWithQueryParams, hashString] = url.split("#")
        const [baseUrl, baseQueryString] = baseUrlWithQueryParams.split("?")

        let queryString = UrlParam.parse(baseQueryString)
        _.forEach(_.isArray(keysToRemove) ? keysToRemove : [keysToRemove], (key) => {
            delete queryString[key]
        })
        queryString = UrlParam.stringify(queryString)

        const urlQueryParams = queryString ? `${baseUrl}?${queryString}` : baseUrl

        return Url.addHashString(urlQueryParams, hashString)
    }

    static addPreventCacheQueryParams(url: string, key = "cache"): string {
        return url + (url.includes("?") ? "&" : "?") + `${key}=` + Date.now()
    }

    static addHashParams(url: string, data: object): string {
        return Url.addSuffixedParams(url, data, "#")
    }

    static addHashString(url: string, hashString?: string) {
        return `${url}${hashString ? `#${hashString}` : ""}`
    }

    static removeHashParams(url: string, keysToRemove: string[] | string): string {
        const [baseUrl, baseHash] = url.split("#")

        let hash = UrlParam.parse(baseHash)
        _.forEach(_.isArray(keysToRemove) ? keysToRemove : [keysToRemove], (key) => {
            delete hash[key]
        })
        hash = UrlParam.stringify(hash)

        return hash ? `${baseUrl}#${hash}` : baseUrl
    }

    static removeHash(url: string): string {
        const urlObject = new URL(url)
        return Url.join(urlObject.origin, urlObject.pathname)
    }

    static addPrefix(url: string): string {
        if (!url.match(/^https?:\/\//) && !url.match(/^\/\//)) {
            return `//${url}`
        } else {
            return url
        }
    }

    static addSuffixedParams(url: string, params: object, separator: string): string {
        const regex = new RegExp(`([^${separator}]+)(\\${separator}.*)?`), // example with "?" => /([^?]+)(\?.*)?/
            splitUrl = regex.exec(url),
            rawUrl = splitUrl![1],
            existingParams = (splitUrl![2] || separator).slice(separator.length).split("&")

        const newParams = _.reduce(
            existingParams,
            (acc, param) => {
                const [key, value] = param.split("=")
                if (!_.isNil(value)) {
                    acc[key] = acc[key] ? _.flatten([acc[key], value]) : value
                }
                return acc
            },
            {},
        )

        _.keys(params)
            .filter((key) => !_.isNil(params[key]) && params[key] !== "")
            .forEach((key) => {
                newParams[key] = _.isArray(params[key])
                    ? _.compact(
                          _.flatten([
                              newParams[key],
                              params[key].map((p) => encodeURIComponent(p)),
                          ]),
                      )
                    : _.compact(_.flatten([newParams[key], encodeURIComponent(params[key])]))
            })

        if (_.isEmpty(newParams)) {
            return rawUrl
        }

        return (
            rawUrl +
            separator +
            _.flatten(
                _.reduce(
                    newParams,
                    (acc, value, key) => [
                        ...acc,
                        _.isArray(value) ? _.map(value, (v) => `${key}=${v}`) : `${key}=${value}`,
                    ],
                    [],
                ),
            ).join("&")
        )
    }

    static getOrigin(url: string): string | null {
        if (!url) {
            return null
        }
        const pathArray = url.split("/")
        if (pathArray.length < 3) {
            return null
        }
        const protocol = pathArray[0]
        const host = pathArray[2]
        return protocol + "//" + host
    }
}

export default Url
