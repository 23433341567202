import { IDialogBoxIcon } from "common/components/dialogBox/DialogBox"
import IconList from "common/components/icon/IconList"

export type IDialogBoxTypes = "warning" | "info" | "error" | "confirm" | "success"

export const DIALOG_BOX_ICON_BY_TYPE: { [K in IDialogBoxTypes]: IDialogBoxIcon } = {
    warning: { id: IconList.info_warning_lg, colorClass: "iconType--warning" },
    info: { id: IconList.info_info_lg, colorClass: "iconType--info" },
    error: { id: IconList.info_error_lg, colorClass: "iconType--error" },
    confirm: { id: IconList.info_help_lg, colorClass: "iconType--info" },
    success: { id: IconList.info_success_lg, colorClass: "iconType--success" },
}
