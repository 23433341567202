import _ from "lodash"

import { IEngineFacadeInit, IMinimalThemeDatas } from "common/services/engine/EngineFacadeTypes"
import Url from "common/utils/Url"

class EngineFacade {
    public libId: string
    public libraryUrl: string
    public libraryApiUrl: string
    public rootLibraryApiUrl: string
    public rootUserProfileUrl?: string
    public keycloakConfig: { url: string; realm: string; idpHint: string }
    public isLibraryPrivate: boolean
    public isPublicMode: boolean
    public baseEngineUrl: string
    public statisticsApiUrl: string
    public defaultFeedbackUrl: string
    public mediaConsultApiUrl: string
    public uploadApiUrl: string
    public portalUrl: string
    public ownerLibId?: string
    public useRecaptchaForSelectionCreation: boolean
    public theme: IMinimalThemeDatas
    public cloudPrintEnabled: boolean
    public onlyofficeApiUrl: string
    public collaboraApiUrl: string

    initialize({
        libId,
        libraryUrl,
        libraryApiUrl,
        rootLibraryApiUrl,
        keycloakConfig,
        isLibraryPrivate,
        isPublicMode,
        baseEngineUrl = Url.getOrigin(window.engineUrl) || "",
        statisticsApiUrl,
        defaultFeedbackUrl = Url.join(libraryApiUrl, "feedback"),
        useRecaptchaForSelectionCreation,
        rootUserProfileUrl,
        mediaConsultApiUrl,
        uploadApiUrl,
        portalUrl,
        onlyofficeApiUrl,
        collaboraApiUrl,
        theme = {
            base: "dark",
            logoUrl: "",
            primaryColor: "#333333",
            secondaryColor: "#FFFFFF",
        },
        ownerLibId,
        cloudPrintEnabled,
    }: IEngineFacadeInit) {
        this.libId = libId
        this.libraryUrl = libraryUrl
        this.libraryApiUrl = libraryApiUrl
        this.rootLibraryApiUrl = rootLibraryApiUrl
        this.rootUserProfileUrl = rootUserProfileUrl

        this.keycloakConfig = keycloakConfig
        this.isLibraryPrivate = isLibraryPrivate

        this.isPublicMode = isPublicMode

        this.baseEngineUrl = baseEngineUrl
        this.statisticsApiUrl = statisticsApiUrl
        this.defaultFeedbackUrl = defaultFeedbackUrl
        this.mediaConsultApiUrl = mediaConsultApiUrl
        this.portalUrl = portalUrl
        this.uploadApiUrl = uploadApiUrl
        this.onlyofficeApiUrl = onlyofficeApiUrl
        this.collaboraApiUrl = collaboraApiUrl

        this.ownerLibId = ownerLibId

        this.useRecaptchaForSelectionCreation = !!useRecaptchaForSelectionCreation

        this.theme = theme

        this.cloudPrintEnabled = !!cloudPrintEnabled
    }

    update(values) {
        _.forEach(values, (value, key) => {
            this[key] = value
        })
    }
}

export default new EngineFacade()
