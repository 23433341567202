import _ from "lodash"

import { LOCAL } from "common/constants/Env"

export type IStrings = { [key: string]: string }
export type ILoadFileCallback = (locale: string) => Promise<IStrings>

export type IVariables = { [variableName: string]: any }

export const DEFAULT_LOCALE = "en"

const DEFAULT_CALLBACK: ILoadFileCallback = () => Promise.resolve({})

class I18nState {
    public loaded = false
    public locale = DEFAULT_LOCALE
    public strings: { [locale: string]: IStrings } = {}
}

class I18n {
    public state = new I18nState()

    public isLoaded(): boolean {
        return this.state.loaded
    }

    /** @deprecated use getLocale instead **/
    public get locale(): string {
        return this.state.locale
    }

    public getLocale(): string {
        return this.state.locale
    }

    public getLangLabel(locale?: string): string {
        if (!locale) {
            return ""
        }
        return (
            this.getString(`common.global.language.${locale.slice(0, 2).toUpperCase()}`) ||
            locale.charAt(0).toUpperCase() + locale.slice(1)
        )
    }

    public async load(
        requestedLocale: string,
        getCommonFileCallback: ILoadFileCallback = DEFAULT_CALLBACK,
        getProjectFileCallback: ILoadFileCallback = DEFAULT_CALLBACK,
        getRemoteFileCallback: ILoadFileCallback = DEFAULT_CALLBACK,
    ): Promise<string> {
        requestedLocale = I18n.normalizeLang(requestedLocale)

        const loadCommonFile = async (locale: string): Promise<void> => {
            await Promise.all([
                getCommonFileCallback(locale).then((strings) => this.setStrings(locale, strings)),
                getRemoteFileCallback(locale).then((strings) => this.setStrings(locale, strings)),
            ])
        }

        const loadProjectFile = (locale: string): Promise<void> =>
            getProjectFileCallback(locale).then((strings) => this.setStrings(locale, strings))

        const langToLoad = DEFAULT_LOCALE === requestedLocale ? undefined : requestedLocale

        // 1. Load default lang
        const defaultLangLoaded = this.loadDefaultLang(loadCommonFile, loadProjectFile)

        // 2. Load Current lang
        const currentLangLoaded = _.isUndefined(langToLoad)
            ? defaultLangLoaded.then(() => DEFAULT_LOCALE)
            : defaultLangLoaded
                  .then(() =>
                      this.loadCurrentLang(requestedLocale, loadCommonFile, loadProjectFile),
                  )
                  .catch(() => DEFAULT_LOCALE)

        // 3. Return loaded lang
        const currentLang = await currentLangLoaded
        this.state.locale = currentLang
        this.state.loaded = true
        return currentLang
    }

    public loadSync(locale: string, strings: IStrings): void {
        this.state.locale = locale
        this.state.loaded = true
        this.setStrings(DEFAULT_LOCALE, {})
        this.setStrings(locale, strings)
    }

    public loadOverride(strings: IStrings): void {
        this.setStrings(this.state.locale, strings)
    }

    public getString(key: string, variables?: IVariables): string

    // Example :
    //
    // "myKey.media": "{username} shares you a media",
    // "myKey.view": "{username} shares you a view",
    // "myKey.any": "{username} shares you something",
    //
    // I18n.getString(
    //     (type) => `myKey.${type}`,
    //     [some, "any"], => list of possible replacement for the variable type in the previous argument
    //     { username } => variable injected in the template
    //
    // if someVariable contains 'media' or 'view', the corresponding key will be used.
    // Otherwise, the key containing 'any' will be used.
    public getString<T>(keyFct: (arg: T) => string, args: T[], variables?: IVariables): string

    public getString<T>(
        arg1: string | ((arg: T) => string),
        arg2?: IVariables | T[],
        arg3?: IVariables,
    ): string {
        return typeof arg1 === "string"
            ? this.getStringFromKey(arg1 as string, arg2 as IVariables | undefined)
            : this.getStringWithFallback(
                  arg1 as (arg: T) => string,
                  arg2 as T[],
                  arg3 as IVariables | undefined,
              )
    }

    // Match the HTML generated from computeMarkdown (only link for now) and line break (br)
    public isStringWithHTML(str: string): boolean {
        return !!str.match(/<\s*a[^>]*>(.*?)<\s*\/\s*a>/) || !!str.match("<br>")
    }

    public async loadDefaultLang(
        loadCommonFile: (locale: string) => Promise<any>,
        loadProjectFile: (locale: string) => Promise<any>,
    ): Promise<void> {
        await loadCommonFile(DEFAULT_LOCALE)
        await loadProjectFile(DEFAULT_LOCALE)
    }

    public async loadCurrentLang(
        requestedLocale: string,
        loadCommonFile: (locale: string) => Promise<any>,
        loadProjectFile: (locale: string) => Promise<any>,
    ): Promise<string> {
        return loadCommonFile(requestedLocale)
            .then(() => loadProjectFile(requestedLocale))
            .then(() => requestedLocale)
            .catch(() => DEFAULT_LOCALE)
    }

    public setStrings(locale: string, strings: IStrings): void {
        const oldStrings = this.state.strings[locale] || {}
        const newStrings = _.assign({}, oldStrings, strings)
        _.assign(this.state.strings, { [locale]: newStrings })
    }

    private getStringFromKey(key: string, variables: IVariables = {}): string {
        const currentStrings = this.state.strings[this.state.locale]
        const defaultStrings = this.state.strings[DEFAULT_LOCALE]

        if (!this.isLoaded()) {
            LOCAL && console.warn(`Trying to use strings while I18n service is not loaded`)
            return ""
        }

        const tmpKey = `_${key}`

        const value =
            currentStrings[key] ||
            currentStrings[tmpKey] ||
            defaultStrings[key] ||
            defaultStrings[tmpKey]

        if (!value) {
            LOCAL && console.warn(`I18n ${key} hasn't been set [locale=${this.state.locale}]`)
            return ""
        }

        const computers = [
            (str: string) => I18n.computeVariables(str, variables),
            (str: string) => I18n.computeMarkdown(str),
        ]

        return _.reduce(computers, (computedResult, computer) => computer(computedResult), value)
    }

    private getStringWithFallback<T>(
        keyFct: (arg: T) => string,
        args: T[],
        variables: IVariables = {},
    ): string {
        let str = ""
        _.forEach(args, (arg) => {
            const key = keyFct(arg)
            str = this.getString(key, variables)
            return !str
        })
        return str
    }

    public static normalizeLang(langStr: string = ""): string {
        return langStr.toLowerCase().trim().slice(0, 2)
    }

    public static computeVariables(str: string, variables: IVariables): string {
        return variables
            ? _.reduce(variables, (string, value, key) => string.replace(`{${key}}`, value), str)
            : str
    }

    /**
     *  [dpo@bee-buzziness.com](mailto:dpo@bee-buzziness.com) ->
     *  <a href="mailto:dpo@bee-buzziness.com">dpo@bee-buzziness.com</a>
     *  [dpo@bee-buzziness.com](dpo@bee-buzziness.com) ->
     *  <a href="dpo@bee-buzziness.com" target="_blank">dpo@bee-buzziness.com</a>
     */
    public static MARKDOWN_REGEX = /\[([^\]]*)\]\(([^)]*)\)/g
    public static computeMarkdown(str: string): string {
        return str.replace(this.MARKDOWN_REGEX, (result, $1, $2) => {
            return `<a href="${$2}"${$2.includes("mailto") ? "" : ' target="_blank"'}>${$1}</a>`
        })
    }
}

const CommonI18nSingleton = new I18n()

class CommonI18n extends I18n {
    constructor() {
        super()
        CommonI18nSingleton.state = this.state
    }
}

export { I18n, CommonI18n }
export default CommonI18nSingleton
