import React, { useEffect, useState } from "react"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { IStepperProps } from "@/components/stepper/Stepper"
import { LoadingCard } from "@/screens/cards/loadingCard/LoadingCard"
import { IIsBeeUserResponse, isBeeUser } from "@/services/api/PortalAPI"
import { I18n } from "@/services/i18n/I18n"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"

import "./configureEmployeeAccountCard.less"

interface IConfigureEmployeeAccountCardProps {
    stepperProps: IStepperProps
    createEmployeeLibrary: () => void
    backToPortal: () => void
}

export const getConfigureEmployeeAccountCardTitle = () =>
    I18n.getString("card.configureEmployeeLibrary.title")
export const ConfigureEmployeeAccountCard: React.FunctionComponent<
    IConfigureEmployeeAccountCardProps
> = ({ stepperProps, createEmployeeLibrary, backToPortal }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<IIsBeeUserResponse>({ isBeeUser: false })
    useEffect(() => {
        // TODO handle error
        isBeeUser()
            .then((data) => setData(data))
            .then(() => setLoading(false))
    }, [])

    return loading ? (
        <LoadingCard mainTitle={I18n.getString("card.loadingCard.title")} />
    ) : (
        <PortalCard stepperProps={stepperProps} isMaxWidth>
            <div className="configureEmployeeAccountCard">
                <div className="configureEmployeeAccountCard__content">
                    {data.isBeeUser && (
                        <div className="configureEmployeeAccountCard__title">
                            <h2 className="title--strong">
                                {I18n.getString("card.configureEmployeeLibrary.welcomeTitle")}
                            </h2>
                        </div>
                    )}
                    <p className="paragraph--normal">
                        {I18n.getString(
                            `card.configureEmployeeLibrary.${
                                data.isBeeUser ? "welcome" : "misconfiguration"
                            }`,
                        )}
                    </p>
                </div>

                <div className="configureEmployeeAccountCard__footer">
                    <Button
                        label={I18n.getString(
                            `card.configureEmployeeLibrary.${
                                data.isBeeUser ? "button" : "backToPortalButton"
                            }`,
                        )}
                        onClick={data.isBeeUser ? createEmployeeLibrary : backToPortal}
                        tag={BUTTON_TAG.success}
                        actionType={ACTION_TYPE.primary}
                    />
                </div>
            </div>
        </PortalCard>
    )
}
