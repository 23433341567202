import { cookUserProfile } from "common/data/userProfile/UserProfileCooked"
import UserProfileHelper from "common/data/userProfile/UserProfileHelper"
import {
    IUserProfileCooked,
    IUserProfileRaw,
    IUserSettings,
} from "common/data/userProfile/UserProfileTypes"
import {
    IUserLibChooserLabels,
    IUserLibChooserProps,
} from "common/screens/modalComponents/userLibChooser/UserLibChooser"
import AuthFacade from "common/services/auth/AuthFacade"
import * as UserProfileAPI from "common/services/backApi/UserProfileAPI"
import EngineFacade from "common/services/engine/EngineFacade"
import * as AcknowledgeSender from "common/services/rgpd/AcknowledgementsSender"
import RgpdFacade from "common/services/rgpd/RgpdFacade"
import { ICommonState } from "common/state/store/DefaultState"
import { IAction, IThunkAction } from "common/state/StoreTypes"

import { showModal } from "./ModalActions"

export const SET_USER_PROFILE = "SET_USER_PROFILE"

export const getUserLibId = (
    userLibChooserLabels: IUserLibChooserLabels,
    ignoreCurrentLib: boolean,
    callback: (libId: string | null) => void,
): IThunkAction<boolean, ICommonState> => {
    return (dispatch, getState) => {
        const userProfileHelper = new UserProfileHelper(getState().userProfile!)

        const libraries = userProfileHelper.getLibraries(ignoreCurrentLib)

        if (libraries.length > 1) {
            const userLibChooserProps: IUserLibChooserProps = {
                ...userLibChooserLabels,
                libraries,
                initialSelectedItemId: libraries[0].id,
                onConfirm: callback,
            }
            dispatch(showModal("userLibChooser", userLibChooserProps))
            return false
        } else {
            callback(libraries.length > 0 ? libraries[0].id : null)
            return true
        }
    }
}

export const setUserProfile = (
    userProfile: IUserProfileCooked,
): IAction<{ userProfile: IUserProfileCooked }> => ({
    type: SET_USER_PROFILE,
    userProfile,
})

export const refreshUserProfile =
    (newUserProfileRaw?: IUserProfileRaw): IThunkAction<Promise<IUserProfileCooked>> =>
    async (dispatch) => {
        const newUserProfile = cookUserProfile(
            newUserProfileRaw || (await AuthFacade.fetchUserProfile()),
        )
        dispatch(setUserProfile(newUserProfile))
        return newUserProfile
    }

export const sendAcknowledgement =
    (text: string, values: object, libId: string, userInfo?: boolean): IThunkAction<Promise<any>> =>
    async (dispatch) => {
        const response = await AcknowledgeSender.send(text, values, libId, userInfo)

        if (AuthFacade.isAuthenticated()) {
            const newUserProfile = await dispatch(refreshUserProfile())

            if (libId === EngineFacade.libId) {
                const userProfileHelper = new UserProfileHelper(newUserProfile)
                const mediaReportingResponse =
                    userProfileHelper.getMediaReportingAcknowledgementResponse()
                if (mediaReportingResponse) {
                    RgpdFacade.setMediaReportingAccepted(mediaReportingResponse)
                }
            }
        } else {
            RgpdFacade.setAnonymousId(response.id)
        }

        return response
    }

export const editUserSettings =
    (userSettings: IUserSettings): IThunkAction<Promise<IUserProfileCooked>> =>
    async (dispatch) => {
        try {
            await UserProfileAPI.editUserSettings(userSettings)
            await AuthFacade.forceRefreshToken()
            return dispatch(refreshUserProfile())
        } catch (err: any) {
            await AuthFacade.forceRefreshToken()
            await dispatch(refreshUserProfile())
            throw err
        }
    }
