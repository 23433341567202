import React, { useState } from "react"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { IStepperProps } from "@/components/stepper/Stepper"
import OffersCardHeader from "@/screens/cards/offersCard/header/OffersCardHeader"
import { IExistingSelfManagedHub } from "@/services/api/PortalAPI"
import { I18n } from "@/services/i18n/I18n"

import {
    FORMAT_TAXES,
    IOffer,
    OFFER_GROUPS_IDS,
    OFFER_PLANS,
    PLAN_GROUP_MAPPING,
} from "common/types/OfferTypes"

import { Offer } from "./offer/Offer"

import "./offersCard.less"

interface IOfferCardProps {
    offers: IOffer[]
    stepperProps: IStepperProps
    onOfferSelected: (offerPlan: string) => void
    goToDetailedOffer: (offerPlan: string) => void
    userMyUbList: IExistingSelfManagedHub[]
    setFormatTaxes: (format: FORMAT_TAXES) => void
    formatTaxes: FORMAT_TAXES
    offerGroupId?: OFFER_GROUPS_IDS
}

export const getAllUrlByOfferGroups = (
    userMyUbList: IExistingSelfManagedHub[],
): { [key in OFFER_PLANS]: string[] } =>
    userMyUbList.reduce(
        (groups, existingSelfManagedHub) => {
            const groupId = existingSelfManagedHub.offer
            if (groupId) {
                groups[groupId] = groups[groupId].concat(existingSelfManagedHub.libraryUrl)
            }
            return groups
        },
        {
            [OFFER_PLANS.advanced]: [],
            [OFFER_PLANS.free]: [],
            [OFFER_PLANS.standard]: [],
            [OFFER_PLANS.starter]: [],
            [OFFER_PLANS.personal]: [],
        } as { [key in OFFER_PLANS]: string[] },
    )

export const getOffersCardTitle = () => I18n.getString("card.offers.stepper.title")

export const OffersCard: React.FunctionComponent<IOfferCardProps> = ({
    offers,
    userMyUbList,
    stepperProps,
    onOfferSelected,
    goToDetailedOffer,
    formatTaxes,
    setFormatTaxes,
    offerGroupId = OFFER_GROUPS_IDS.individual,
}) => {
    const groupedUrlsOffers = getAllUrlByOfferGroups(userMyUbList)
    const [currentGroupedOffersId, setCurrentGroupedOffersId] = useState(offerGroupId)

    const switchOffersGroup = (offerGroupSelectedId: OFFER_GROUPS_IDS) => {
        setCurrentGroupedOffersId(offerGroupSelectedId)
    }

    const changeTaxes = (isWithoutTaxes: boolean) => {
        setFormatTaxes(isWithoutTaxes ? FORMAT_TAXES.withoutTaxes : FORMAT_TAXES.withTaxes)
    }

    const isWithoutTaxes =
        formatTaxes === FORMAT_TAXES.default
            ? currentGroupedOffersId === OFFER_GROUPS_IDS.business
            : formatTaxes === FORMAT_TAXES.withoutTaxes

    return (
        <PortalCard stepperProps={stepperProps}>
            <div className={`offersCard offersCard--${currentGroupedOffersId}`}>
                <OffersCardHeader
                    currentGroupOffersId={currentGroupedOffersId}
                    withoutTaxes={isWithoutTaxes}
                    switchOffersGroup={switchOffersGroup}
                    onChangeTaxes={changeTaxes}
                />

                <div className="offersCard__content">
                    {offers.map((offer, index) => (
                        <Offer
                            hidden={PLAN_GROUP_MAPPING[offer.plan] !== currentGroupedOffersId}
                            key={index}
                            offer={offer}
                            withoutTaxes={isWithoutTaxes}
                            onOfferSelected={onOfferSelected}
                            goToDetailedOffer={goToDetailedOffer}
                            urlAlreadySubscribeOffer={
                                offer.plan === OFFER_PLANS.free
                                    ? groupedUrlsOffers[offer.plan][0]
                                    : undefined
                            }
                        />
                    ))}
                </div>
            </div>
        </PortalCard>
    )
}
