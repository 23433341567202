import http, { AxiosRequestConfig, AxiosResponse } from "axios"

import { IDiagnoseRaw } from "common/data/diagnoses/DiagnoseTypes"
import CanceledHTTPCallError from "common/errors/CanceledHTTPCallError"
import AuthFacade from "common/services/auth/AuthFacade"
import CaptchaFacade from "common/services/captcha/CaptchaFacade"
import EngineFacade from "common/services/engine/EngineFacade"
import I18n from "common/services/i18n/I18n"
import StatisticsContextFacade from "common/services/statistics/StatisticsContextFacade"
import DeviceUtilities from "common/utils/Device"
import Url from "common/utils/Url"

enum HTTP_METHODS {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
    PATCH = "patch",
}

export type IDiagnosesHandler = ($diagnoses: IDiagnoseRaw[]) => void

interface IRequestHeaders {
    [key: string]: string
}

export interface IHttpRequestOptions {
    contentType?: string | null
    customHeaders?: IRequestHeaders
    ignoreDiagnoses?: boolean
    customDiagnosesHandler?: IDiagnosesHandler // if customDiagnosesHandler throw, the default diagnose handler will handle the error
    localDiagnoses?: IDiagnoseRaw[]
    returnBrutResponse?: boolean
    preventCache?: boolean
    captchaKey?: string
    anonymousId?: string
    configOverride?: Partial<AxiosRequestConfig> // /!\ can override all default request config
}

interface IHttpRequestUrlBuilderParams {
    baseUrl: string
    method: HTTP_METHODS
    preventCache?: boolean
}

interface IHttpRequestHeadersBuilderParams {
    withAuthorization: boolean
    contentType?: string | null
    captchaKey?: string
    anonymousId?: string
    customHeaders?: IRequestHeaders
}

let diagnosesHandler: IDiagnosesHandler | null = null
export const setDiagnosesHandler = (handler: IDiagnosesHandler) => (diagnosesHandler = handler)
export const getDefaultDiagnosesHandler = (): IDiagnosesHandler => {
    if (!diagnosesHandler) {
        throw new Error("diagnosesHandler is undefined")
    }
    return diagnosesHandler
}

class HttpRequestUrlBuilder {
    private url: string
    private readonly params: IHttpRequestUrlBuilderParams

    constructor(params: IHttpRequestUrlBuilderParams) {
        this.url = params.baseUrl
        this.params = params
    }

    public enhanceUrlWithStatisticsContextIfNecessary(): HttpRequestUrlBuilder {
        this.url = HttpRequestUrlBuilder.enhanceUrlWithStatisticsContext(this.url)
        return this
    }

    public getUrl(): string {
        return this.url
    }

    public enhanceUrlWithCacheProtectionIfNecessary(): HttpRequestUrlBuilder {
        if (
            this.params.preventCache ||
            (DeviceUtilities.isIE() && this.params.method === HTTP_METHODS.GET)
        ) {
            this.url = Url.addPreventCacheQueryParams(this.url)
        }
        return this
    }

    public static enhanceUrlWithStatisticsContext(url: string): string {
        const statisticsContext = StatisticsContextFacade.getStatisticsContext()
        const shouldSendStatisticsContext =
            statisticsContext && url.startsWith(EngineFacade.libraryApiUrl)

        if (shouldSendStatisticsContext) {
            return Url.addQueryParams(url, {
                sid: statisticsContext.sessionId,
                tid: statisticsContext.trackingId,
                tia: statisticsContext.trackingIsAnonymous,
                ctx: statisticsContext.context,
            })
        } else {
            return url
        }
    }
}

class HttpRequestHeadersBuilder {
    private readonly applyHeadersFcts: (() => Promise<IRequestHeaders>)[] = []
    private readonly params: IHttpRequestHeadersBuilderParams

    constructor(params: IHttpRequestHeadersBuilderParams) {
        this.params = params
    }

    public addAcceptLanguageHeader(): HttpRequestHeadersBuilder {
        this.applyHeadersFcts.push(async () => ({ ["Accept-Language"]: I18n.getLocale() }))
        return this
    }

    public addContentTypeHeaderIfNecessary(): HttpRequestHeadersBuilder {
        if (!this.params.contentType === null) {
            this.applyHeadersFcts.push(async () => ({
                ["Content-Type"]: this.params.contentType || "application/json;charset=UTF-8",
            }))
        }
        return this
    }

    public addAuthorizationHeaderIfNecessary(): HttpRequestHeadersBuilder {
        if (this.params.withAuthorization && AuthFacade.isAuthenticated()) {
            this.applyHeadersFcts.push(async () => ({
                ["authorization"]: await AuthFacade.getToken(),
            }))
        }
        return this
    }

    public addCaptchaHeaderIfNecessary(): HttpRequestHeadersBuilder {
        if (this.params.captchaKey) {
            this.applyHeadersFcts.push(async () => ({
                ["x-captcha-token"]: await CaptchaFacade.execute(this.params.captchaKey!),
            }))
        }
        return this
    }

    public addAnonymousIdHeaderIfNecessary(): HttpRequestHeadersBuilder {
        if (this.params.anonymousId) {
            this.applyHeadersFcts.push(async () => ({
                ["x-anonymous-id"]: this.params.anonymousId!,
            }))
        }
        return this
    }

    public async getHeaders(): Promise<IRequestHeaders> {
        let headers = {}
        for (const fct of this.applyHeadersFcts) {
            headers = { ...headers, ...(await fct()) }
        }
        return { ...headers, ...this.params.customHeaders }
    }
}

const handleDiagnoses = (
    diagnoses: IDiagnoseRaw[] = [],
    options: Pick<
        IHttpRequestOptions,
        "ignoreDiagnoses" | "customDiagnosesHandler" | "localDiagnoses"
    > = {},
): boolean => {
    const allDiagnoses = [...diagnoses]

    if (options.localDiagnoses && options.localDiagnoses.length > 0) {
        diagnoses.push(...options.localDiagnoses)
    }

    const onDiagnosesReceived = options.customDiagnosesHandler
        ? ($diagnoses: IDiagnoseRaw[]) => {
              try {
                  return options.customDiagnosesHandler!($diagnoses)
              } catch (err) {
                  return diagnosesHandler?.($diagnoses)
              }
          }
        : diagnosesHandler

    if (diagnoses && diagnoses.length > 0 && onDiagnosesReceived && !options.ignoreDiagnoses) {
        onDiagnosesReceived(allDiagnoses)
        return true
    } else {
        return false
    }
}

const request = async <Response>(
    method: HTTP_METHODS,
    baseUrl: string,
    body: object | string | undefined,
    withAuthorization: boolean,
    options: IHttpRequestOptions = {},
): Promise<Response> => {
    const urlBuilderParams: IHttpRequestUrlBuilderParams = {
        baseUrl,
        method,
        preventCache: options.preventCache,
    }
    const url = new HttpRequestUrlBuilder(urlBuilderParams)
        .enhanceUrlWithStatisticsContextIfNecessary()
        .enhanceUrlWithCacheProtectionIfNecessary()
        .getUrl()

    const headersBuilderParams: IHttpRequestHeadersBuilderParams = {
        withAuthorization,
        contentType: options.contentType,
        captchaKey: options.captchaKey,
        anonymousId: options.anonymousId,
        customHeaders: options.customHeaders,
    }
    const headers = await new HttpRequestHeadersBuilder(headersBuilderParams)
        .addAcceptLanguageHeader()
        .addContentTypeHeaderIfNecessary()
        .addAuthorizationHeaderIfNecessary()
        .addCaptchaHeaderIfNecessary()
        .addAnonymousIdHeaderIfNecessary()
        .getHeaders()

    const httpConfig: AxiosRequestConfig = {
        method,
        url,
        data: body,
        headers,
        ...options.configOverride,
    }

    return http(httpConfig)
        .then((response: AxiosResponse) => {
            const data = response.data

            const diagnoses = data?.$diagnoses || data?.diagnoses
            handleDiagnoses(diagnoses, options)

            return options.returnBrutResponse ? response : data
        })
        .catch((error) => {
            if (http.isCancel(error)) {
                throw new CanceledHTTPCallError()
            } else {
                const diagnoses = error?.response?.data?.$diagnoses
                const shouldLogError = !handleDiagnoses(diagnoses, options)
                if (shouldLogError) {
                    console.error(method, url, error)
                }

                throw error.response
            }
        })
}

const httpFactory = (withAuthorization: boolean) => {
    return {
        get: <Response = any>(url: string, options?: IHttpRequestOptions) =>
            request<Response>(HTTP_METHODS.GET, url, undefined, withAuthorization, options),
        post: <Response = any>(
            url: string,
            body: object | string | undefined = undefined,
            options?: IHttpRequestOptions,
        ) => request<Response>(HTTP_METHODS.POST, url, body, withAuthorization, options),
        put: <Response = any>(
            url: string,
            body: object | string | undefined = undefined,
            options?: IHttpRequestOptions,
        ) => request<Response>(HTTP_METHODS.PUT, url, body, withAuthorization, options),
        patch: <Response = any>(
            url: string,
            body: object | string | undefined = undefined,
            options?: IHttpRequestOptions,
        ) => request<Response>(HTTP_METHODS.PATCH, url, body, withAuthorization, options),
        delete: <Response = any>(
            url: string,
            body: object | undefined = undefined,
            options?: IHttpRequestOptions,
        ) => request<Response>(HTTP_METHODS.DELETE, url, body, withAuthorization, options),
    }
}

export const Http = httpFactory(false)
export const AuthenticatedHttp = httpFactory(true)

export const enhanceUrlWithStatisticsContext = HttpRequestUrlBuilder.enhanceUrlWithStatisticsContext
export const CancelToken = http.CancelToken
