import React from "react"

import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import { bem } from "common/utils/Bem"

import "./button.less"

export enum BUTTON_TAG {
    success = "success",
    info = "info",
    warning = "warning",
    error = "error",
    custom = "custom", // use "CUS" css variables
}

export enum ACTION_TYPE {
    primary = "primary",
    secondary = "secondary",
    third = "third",
}

export interface IButtonProps {
    tabIndex?: number
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    dataTestId?: string
    label?: string
    strongLabel?: boolean
    iconId?: string | string[]
    style?: React.CSSProperties
    className?: string
    active?: boolean
    disabled?: boolean
    tag?: BUTTON_TAG | null
    dot?: boolean
    actionType?: ACTION_TYPE
    loading?: boolean
    ariaAttributes?: React.AriaAttributes
    isSubmit?: boolean
    title?: string
    autofocus?: boolean
}

const Button: React.FunctionComponent<IButtonProps> = ({
    className,
    dataTestId,
    onClick,
    active,
    disabled,
    tabIndex,
    label,
    strongLabel,
    iconId,
    tag,
    dot,
    style,
    loading,
    actionType = ACTION_TYPE.primary,
    ariaAttributes,
    isSubmit,
    title,
    autofocus,
}) => {
    const actionTypeElementClass = `${actionType}Button`
    const _strongLabel =
        strongLabel !== undefined ? strongLabel : actionType === ACTION_TYPE.primary

    const renderIcons = () => {
        const iconIds = Array.isArray(iconId) ? iconId : [iconId]

        return iconIds.map(
            (icon, i) =>
                icon && (
                    <Icon
                        iconClassName={bem(actionTypeElementClass, "icon")}
                        id={icon}
                        key={icon}
                        style={i >= 1 ? { marginLeft: "5px" } : {}}
                    />
                ),
        )
    }

    return (
        <button
            type={isSubmit ? "submit" : "button"}
            title={title}
            data-testid={dataTestId || null}
            className={bem(
                actionTypeElementClass,
                { active, inactive: disabled, loading, [tag as string]: !!tag },
                [className],
            )}
            onClick={onClick && !disabled && !loading ? onClick : undefined}
            tabIndex={tabIndex}
            disabled={disabled}
            style={style}
            {...ariaAttributes}
            autoFocus={autofocus}
        >
            {label && (
                <span
                    className={bem(actionTypeElementClass, "label", [
                        _strongLabel ? "label--strong" : "label--normal",
                    ])}
                >
                    {label}
                </span>
            )}
            {loading && (
                <Icon
                    iconClassName={bem(actionTypeElementClass, "icon", { rotating: true })}
                    id={IconList.info_loader_sm}
                    dataTestId={"button--loading"}
                />
            )}
            {!loading && renderIcons()}
            {dot && <div className={bem(actionTypeElementClass, "dot")} />}
        </button>
    )
}

export default Button
