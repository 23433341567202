import React from "react"
import _ from "lodash"

import Button, { ACTION_TYPE, BUTTON_TAG, IButtonProps } from "common/components/button/Button"
import IconList from "common/components/icon/IconList"
import { DEVELOPMENT } from "common/constants/Env"
import I18n from "common/services/i18n/I18n"
import DeviceUtilities from "common/utils/Device"
import Dom from "common/utils/Dom"
import { classNames } from "common/utils/JSX"

import "./panelsFooter.less"

interface IPanelsFooterProps {
    main: IButtonProps
    secondary?: IButtonProps
    className?: string
    noScrollButton?: boolean
}

interface IPanelsFooterState {
    positionRelative: boolean
    onBottom: boolean
}

class PanelsFooter extends React.Component<IPanelsFooterProps, IPanelsFooterState> {
    private readonly ref: React.RefObject<HTMLDivElement>
    private readonly cancelStickyOnInputFocused: boolean
    private scrollableParent: HTMLElement | undefined
    private childObserver: MutationObserver

    constructor(props) {
        super(props)

        this.ref = React.createRef()

        this.cancelStickyOnInputFocused = DeviceUtilities.isMobile() || DeviceUtilities.isTablet()

        this.state = {
            positionRelative: false,
            onBottom: true,
        }
    }

    private _isOnBottom(): boolean {
        const { scrollTop, offsetHeight, scrollHeight } = this.scrollableParent!
        return Math.ceil(scrollTop + offsetHeight) >= scrollHeight
    }

    private _onParentScroll = (): void => {
        const onBottom = this._isOnBottom()

        if (onBottom !== this.state.onBottom) {
            this.setState({ onBottom })
        }
    }

    private _onFocusChanged = (): void => {
        const focusableNodeNames: (string | undefined)[] = ["INPUT", "TEXTAREA", "SELECT"]
        const positionRelative = focusableNodeNames.includes(document.activeElement?.nodeName)

        if (positionRelative !== this.state.positionRelative) {
            this.setState({ positionRelative })
        }
    }

    private _scrollToBottom = (): void => {
        this.scrollableParent!.scrollTop = this.scrollableParent!.scrollHeight
    }

    componentDidMount() {
        if (DEVELOPMENT) {
            const parentElement = this.ref.current!.parentElement!
            const parentStyle = getComputedStyle(parentElement)

            if (parentStyle.padding && parentStyle.padding !== "0px") {
                throw new Error(
                    `Invalid FeatureContentButtons container: padding on container (padding: ${parentStyle.padding})`,
                )
            }
        }

        this.scrollableParent = Dom.findParentByDataAttr(
            this.ref.current!,
            "data-sticky-bottom-buttons-container", // TODO: replace with scrollable ?
        )?.target as HTMLElement

        if (this.scrollableParent) {
            this.setState({ onBottom: this._isOnBottom(), positionRelative: false })

            this.scrollableParent.addEventListener("scroll", this._onParentScroll, false)

            // handle dynamic content that appear/disappear in the parent content
            if (window.MutationObserver) {
                this.childObserver = new MutationObserver((mutationsList) => {
                    _.forEach(
                        _.uniqBy(mutationsList, "type"),
                        (m) => m.type === "childList" && this._onParentScroll(),
                    )
                })
                this.childObserver.observe(this.scrollableParent, {
                    childList: true,
                    subtree: true,
                })
            }
        } else {
            this.setState({ onBottom: true, positionRelative: true })
        }

        if (this.cancelStickyOnInputFocused) {
            window.addEventListener("focus", this._onFocusChanged, true)
            window.addEventListener("blur", this._onFocusChanged, true)
        }
    }

    componentWillUnmount() {
        this.scrollableParent?.removeEventListener("scroll", this._onParentScroll, false)
        this.childObserver?.disconnect()

        if (this.cancelStickyOnInputFocused) {
            window.removeEventListener("focus", this._onFocusChanged, true)
            window.removeEventListener("blur", this._onFocusChanged, true)
        }
    }

    render() {
        const { main, secondary, className, noScrollButton } = this.props

        const { onBottom, positionRelative } = this.state

        const forcePositionRelative = !DeviceUtilities.supportPositionSticky() || positionRelative

        return (
            <div
                ref={this.ref}
                {...classNames("panelsFooter", className, {
                    "panelsFooter--noScrollButton": noScrollButton,
                    "panelsFooter--onBottom": onBottom || forcePositionRelative,
                    "panelsFooter--forcePositionRelative": forcePositionRelative,
                })}
            >
                <div className="panelsFooter__wrapper">
                    <Button
                        className="panelsFooter__scroller"
                        onClick={this._scrollToBottom}
                        iconId={IconList.navi_sidebar_close_sm}
                        actionType={ACTION_TYPE.secondary}
                        disabled={onBottom || forcePositionRelative}
                        ariaAttributes={{
                            "aria-label": I18n.getString("common.global.scrollToTheEnd.ariaLabel"),
                        }}
                    />
                    <div className="panelsFooter__buttons">
                        {secondary && <Button {...secondary} actionType={ACTION_TYPE.secondary} />}
                        <Button
                            {...main}
                            actionType={ACTION_TYPE.primary}
                            tag={main.tag === undefined ? BUTTON_TAG.success : main.tag}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PanelsFooter
