import React, { DependencyList, useCallback, useEffect, useRef } from "react"

import Device from "common/utils/Device"

export interface ICenterPoint {
    x: number
    y: number
}

export interface IEventHandlers<T> {
    onPointerDown?: React.PointerEventHandler<T>
    onPointerUp?: React.PointerEventHandler<T>
    onPointerMove?: React.PointerEventHandler<T>
    onPointerCancel?: React.PointerEventHandler<T>
    onPointerOut?: React.PointerEventHandler<T>
    onPointerLeave?: React.PointerEventHandler<T>
    onWheel?: React.WheelEventHandler<T>
    onDoubleClick?: React.MouseEventHandler<T>
    onTouchStart?: React.TouchEventHandler<T>
    onTouchEnd?: React.TouchEventHandler<T>
    onTouchCancel?: React.TouchEventHandler<T>
    onTouchMove?: React.TouchEventHandler<T>
    onKeyUp?: React.KeyboardEventHandler<T>
    onKeyDown?: React.KeyboardEventHandler<T>
    onMouseUp?: React.MouseEventHandler<T>
    onScroll?: React.UIEventHandler<T>
    onDrop?: React.MouseEventHandler<T>
    onDragOver?: React.MouseEventHandler<T>
}

// Distance in px between two frames
const PINCH_THRESHOLD = 2

const DBL_TAP_TIMER = 500
const DBL_TAP_PX_ZONE = 30

const LIMIT_VERTICAL_PAN_THRESHOLD = 2

enum PAN_STATE {
    START = "START",
    PAN = "PAN",
    END = "END",
}

export const useEvent = (
    event,
    handler,
    passive = false,
    target: Window | Document | HTMLElement = window,
) => {
    useEffect(() => {
        // initiate the event handler
        target.addEventListener(event, handler, { passive })

        // this will clean up the event every time the component is re-rendered
        return () => target.removeEventListener(event, handler)
    })
}

export const useDblClickEvent = <T extends HTMLElement>(
    onDblClick: (event: React.PointerEvent) => void,
): [IEventHandlers<T>] => {
    const timeTap = useRef([{ time: 0, x: 0, y: 0 }])
    const lastClickTemp = useRef(-1)
    const timeBetweenTap = useRef(-1)

    const onDown = useCallback((event: React.PointerEvent) => {
        if (Date.now() - lastClickTemp.current >= DBL_TAP_TIMER) {
            timeTap.current = []
        }

        lastClickTemp.current = Date.now()

        timeTap.current.push({
            time: Date.now(),
            x: event.nativeEvent.offsetX,
            y: event.nativeEvent.offsetY,
        })
    }, [])

    const onUp = useCallback(
        (event: React.PointerEvent) => {
            if (timeTap.current.length >= 2) {
                timeBetweenTap.current = timeTap.current[1].time - timeTap.current[0].time
                if (
                    timeBetweenTap.current <= DBL_TAP_TIMER &&
                    Math.abs(timeTap.current[1].x - timeTap.current[0].x) < DBL_TAP_PX_ZONE &&
                    Math.abs(timeTap.current[1].y - timeTap.current[0].y) < DBL_TAP_PX_ZONE
                ) {
                    onDblClick(event)
                    timeTap.current = []
                }
            }
        },
        [onDblClick],
    )

    return [
        {
            onPointerDown: onDown,
            onPointerUp: onUp,
            onPointerCancel: onUp,
            onPointerOut: onUp,
            onPointerLeave: onUp,
        },
    ]
}

export const usePinchEvent = <T extends HTMLElement>(
    onPinch: (diff: number, centerPoint: ICenterPoint) => void,
    onPinchEnd?: () => void,
    pinchThreshold: number = PINCH_THRESHOLD,
): [IEventHandlers<T>] => {
    const evCache = useRef<React.PointerEvent[]>([])
    const prevDiff = useRef(-1)

    const pointerdownHandler = useCallback((event: React.PointerEvent) => {
        evCache.current.push(event)
    }, [])

    const pointermoveHandler = useCallback(
        (event: React.PointerEvent) => {
            event.preventDefault()
            if (evCache.current.length > 0) {
                for (let i = 0; i < evCache.current.length; i++) {
                    if (event.pointerId == evCache.current[i].pointerId) {
                        evCache.current[i] = event
                        break
                    }
                }

                // If two pointers are down, check for pinch gestures
                if (evCache.current.length === 2) {
                    // Calculate the distance between the two pointers
                    const XDistance = evCache.current[0].clientX - evCache.current[1].clientX
                    const YDistance = evCache.current[0].clientY - evCache.current[1].clientY

                    const curDiff = Math.max(Math.abs(XDistance), Math.abs(YDistance))

                    const centerPoint = {
                        x:
                            Math.min(evCache.current[0].clientX, evCache.current[1].clientX) +
                            Math.abs(XDistance) / 2,
                        y:
                            Math.min(evCache.current[0].clientY, evCache.current[1].clientY) +
                            Math.abs(YDistance) / 2,
                    }

                    if (
                        prevDiff.current > 0 &&
                        Math.abs(prevDiff.current - curDiff) > pinchThreshold
                    ) {
                        if (prevDiff.current > curDiff) {
                            onPinch(1, centerPoint)
                        } else {
                            onPinch(-1, centerPoint)
                        }
                        prevDiff.current = curDiff
                    }

                    prevDiff.current === -1 && (prevDiff.current = curDiff)
                }
            }
        },
        [onPinch, pinchThreshold],
    )

    const removeAll = useCallback(() => {
        evCache.current = []
        prevDiff.current = -1
    }, [])

    const remove_event = useCallback(
        (event: React.PointerEvent) => {
            let isEventRemoved = false
            // Remove this event from the target's cache
            for (let i = 0; i < evCache.current.length; i++) {
                if (evCache.current[i].pointerId == event.pointerId) {
                    evCache.current.splice(i, 1)
                    isEventRemoved = true
                    break
                }
            }

            if (!isEventRemoved) {
                removeAll()
            }
        },
        [removeAll],
    )

    const pointerupHandler = useCallback(
        (event: React.PointerEvent) => {
            event.preventDefault()
            remove_event(event)
            onPinchEnd?.()
            if (evCache.current.length < 2) prevDiff.current = -1
        },
        [onPinchEnd, remove_event],
    )

    const pointerCancelHandler = useCallback(() => {
        if (evCache.current.length < 2) {
            removeAll()
            prevDiff.current = -1
        }
    }, [removeAll])

    return [
        {
            onPointerDown: pointerdownHandler,
            onPointerMove: pointermoveHandler,
            onPointerUp: pointerupHandler,
            onPointerCancel: pointerCancelHandler,
            onPointerOut: pointerCancelHandler,
            onPointerLeave: pointerCancelHandler,
        },
    ]
}

export const usePanEvent = <T extends HTMLElement>(
    onPanStart: () => void,
    onPanMove: (position: { x: number; y: number }) => void,
    onPanEnd: (velocity: { x: number; y: number }, distance: { x: number; y: number }) => void,
): [IEventHandlers<T>] => {
    const mouse = useRef({
        x: 0,
        y: 0,
        oldX: 0,
        oldY: 0,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        click: false,
        panState: PAN_STATE.END,
    })

    const pointerCount = useRef(0)
    const start = useRef(Date.now())

    const changePanState = (newState: PAN_STATE) => {
        switch (newState) {
            case PAN_STATE.START:
                {
                    start.current = Date.now()
                    onPanStart()
                    mouse.current.panState = PAN_STATE.START
                }
                break
            case PAN_STATE.PAN:
                {
                    if (pointerCount.current === 1) {
                        // hack to fix pan on Safari IOS
                        if (Device.isIOS()) {
                            if (mouse.current.y - mouse.current.oldY === 0) {
                                return
                            }
                        }

                        const panX = (mouse.current.endX = mouse.current.x - mouse.current.oldX)
                        const panY = (mouse.current.endY = mouse.current.y - mouse.current.oldY)

                        mouse.current.oldX = mouse.current.x
                        mouse.current.oldY = mouse.current.y

                        onPanMove({ x: panX, y: panY })
                        mouse.current.panState = PAN_STATE.PAN
                    }
                }
                break
            case PAN_STATE.END:
                {
                    const deltaTime = Date.now() - start.current
                    const velocityX = mouse.current.endX / deltaTime || 0
                    const velocityY = mouse.current.endY / deltaTime || 0

                    onPanEnd(
                        { x: velocityX, y: velocityY },
                        {
                            x: mouse.current.oldX - mouse.current.startX,
                            y: mouse.current.oldY - mouse.current.startY,
                        },
                    )
                    mouse.current.panState = PAN_STATE.END
                }
                break
        }
    }

    const onDown = (event: React.MouseEvent | React.PointerEvent | React.TouchEvent) => {
        pointerCount.current++

        if (isTouchEvent(event)) {
            mouse.current.x = event.changedTouches[0].clientX
            mouse.current.y = event.changedTouches[0].clientY
        } else {
            mouse.current.x = event.nativeEvent.clientX
            mouse.current.y = event.nativeEvent.clientY
        }

        mouse.current.startX = mouse.current.x
        mouse.current.startY = mouse.current.y
        mouse.current.oldX = mouse.current.x
        mouse.current.oldY = mouse.current.y

        mouse.current.click = true
    }

    const onMove = (event: React.MouseEvent | React.PointerEvent | React.TouchEvent) => {
        if (isTouchEvent(event)) {
            mouse.current.x = event.changedTouches[0].clientX
            mouse.current.y = event.changedTouches[0].clientY
        } else {
            mouse.current.x = event.nativeEvent.clientX
            mouse.current.y = event.nativeEvent.clientY
        }

        if (mouse.current.click && pointerCount.current === 1) {
            if (mouse.current.panState === PAN_STATE.END) {
                changePanState(PAN_STATE.START)
            }
            changePanState(PAN_STATE.PAN)
        }
    }

    const onUp = () => {
        pointerCount.current = pointerCount.current - 1 > 0 ? pointerCount.current - 1 : 0
        mouse.current.click = false

        if (mouse.current.panState === PAN_STATE.PAN) {
            changePanState(PAN_STATE.END)
        }
    }

    return [
        {
            onPointerDown: onDown,
            onPointerMove: onMove,
            onPointerUp: onUp,
            onPointerLeave: onUp,
        },
    ]
}

export const useHorizontalPanEventWithTouch = <T extends HTMLElement>(
    onPanStart: (startPosition: { x: number }) => void,
    onPanMove: (position: { x: number }) => void,
    onPanEnd: () => void,
    limitVerticalPanThreshold: number = LIMIT_VERTICAL_PAN_THRESHOLD,
): [IEventHandlers<T>] => {
    const touchData = useRef({
        startX: 0,
        startY: 0,
        x: 0,
        y: 0,
        touching: false,
        panState: PAN_STATE.END,
    })

    const pointerCount = useRef(0)

    const changePanState = (newState: PAN_STATE) => {
        switch (newState) {
            case PAN_STATE.START:
                {
                    onPanStart({ x: touchData.current.startX })
                    touchData.current.panState = PAN_STATE.START
                }
                break
            case PAN_STATE.PAN:
                {
                    if (pointerCount.current === 1) {
                        onPanMove({ x: touchData.current.x })
                        touchData.current.panState = PAN_STATE.PAN
                    }
                }
                break
            case PAN_STATE.END:
                {
                    onPanEnd()
                    touchData.current.panState = PAN_STATE.END
                }
                break
        }
    }

    const onDown = () => {
        pointerCount.current++
        touchData.current.touching = true
    }

    const onMove = (event: React.TouchEvent) => {
        if (touchData.current.touching && pointerCount.current === 1) {
            if (touchData.current.panState === PAN_STATE.END) {
                touchData.current.startX = event.changedTouches[0].clientX
                touchData.current.startY = event.changedTouches[0].clientY
                changePanState(PAN_STATE.START)
            } else {
                touchData.current.x = event.changedTouches[0].clientX
                touchData.current.y = event.changedTouches[0].clientY
                if (
                    touchData.current.panState === PAN_STATE.START &&
                    Math.abs(touchData.current.y - touchData.current.startY) >
                        limitVerticalPanThreshold
                ) {
                    touchData.current.touching = false
                    changePanState(PAN_STATE.END)
                } else {
                    changePanState(PAN_STATE.PAN)
                }
            }
        }
    }

    const onUp = () => {
        pointerCount.current = pointerCount.current - 1 > 0 ? pointerCount.current - 1 : 0
        touchData.current.touching = false

        if (touchData.current.panState === PAN_STATE.PAN) {
            changePanState(PAN_STATE.END)
        }
    }

    return [
        {
            onTouchStart: onDown,
            onTouchMove: onMove,
            onTouchEnd: onUp,
            onTouchCancel: onUp,
        },
    ]
}

const isTouchEvent = (e: React.TouchEvent | React.MouseEvent): e is React.TouchEvent => {
    return e && "touches" in e
}
const isMouseEvent = (e: React.TouchEvent | React.MouseEvent): e is React.MouseEvent => {
    return e && "clientX" in e
}

export const useSwipeEvent = <T extends HTMLElement>(
    useOnlyTouchEvent: boolean,
    swipeLeft?: (event: React.PointerEvent | React.TouchEvent) => void,
    swipeRight?: (event: React.PointerEvent | React.TouchEvent) => void,
    swipeTop?: (event: React.PointerEvent | React.TouchEvent) => void,
    swipeBottom?: (event: React.PointerEvent | React.TouchEvent) => void,
): [IEventHandlers<T>] => {
    const initialPos = useRef({ x: 0, y: 0 })
    const time = useRef(-1)
    const minDistance = 50
    const maxTime = 200

    const getEventCoord = (
        event: React.PointerEvent | React.TouchEvent,
    ): { x: number; y: number } => {
        const position = { x: 0, y: 0 }

        if (isTouchEvent(event)) {
            position.x = event.changedTouches[0].clientX
            position.y = event.changedTouches[0].clientY
        } else if (isMouseEvent(event)) {
            position.x = event.clientX
            position.y = event.clientY
        }

        return position
    }

    const onPointerDown = (event: React.PointerEvent | React.TouchEvent) => {
        event.persist()
        initialPos.current = getEventCoord(event)
        time.current = Date.now()
    }

    const onPointerUp = (event: React.PointerEvent | React.TouchEvent) => {
        event.persist()
        const currentPos = getEventCoord(event)

        const XDistance = Math.abs(currentPos.x - initialPos.current.x)
        const YDistance = Math.abs(currentPos.y - initialPos.current.y)

        const isHorizontal = XDistance > YDistance

        if (Date.now() - time.current <= maxTime) {
            if (isHorizontal) {
                if (XDistance >= minDistance) {
                    if (initialPos.current.x < currentPos.x) {
                        swipeRight?.(event)
                    } else {
                        swipeLeft?.(event)
                    }
                }
            } else {
                if (YDistance >= minDistance) {
                    if (initialPos.current.y < currentPos.y) {
                        swipeBottom?.(event)
                    } else {
                        swipeTop?.(event)
                    }
                }
            }
        }
    }

    return [
        Device.isDesktop() && !useOnlyTouchEvent
            ? {
                  onPointerDown: onPointerDown,
                  onPointerUp: onPointerUp,
              }
            : {
                  onTouchStart: onPointerDown,
                  onTouchEnd: onPointerUp,
              },
    ]
}

export const useResizeWindowEffect = (
    effect,
    dependencies?: DependencyList,
    debounceTime: number = 0,
) => {
    useEffect(() => {
        let timeout: number
        const resizeHandler = () => {
            window.clearTimeout(timeout)
            timeout = window.setTimeout(effect, debounceTime)
        }

        window.addEventListener("resize", resizeHandler)
        return () => window.removeEventListener("resize", resizeHandler)
    }, dependencies)
}
