import { IModal } from "common/components/modal/ModalTypes"
import { IUserProfileCooked } from "common/data/userProfile/UserProfileTypes"
import AuthFacade from "common/services/auth/AuthFacade"

export interface ICommonState {
    modals: IModal[]
    coverLayers: number
    asyncImportLoader: boolean
    userProfile: IUserProfileCooked | null
}

export default (): ICommonState => ({
    modals: [],
    coverLayers: 0,
    asyncImportLoader: false,
    userProfile: AuthFacade.getInitialUserProfile(),
})
