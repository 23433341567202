import { IContact, IUserSettings } from "common/data/userProfile/UserProfileTypes"
import EngineFacade from "common/services/engine/EngineFacade"
import { AuthenticatedHttp } from "common/utils/Http"
import Url from "common/utils/Url"

export enum ContactTypes {
    MAIL = "mail",
    SMS = "sms",
}

export enum UserMessageShareType {
    shareSms = "sms",
    shareEmail = "email",
    shareGroup = "group",
    authorizationEmail = "authorizations~email",
    authorizationGroup = "authorizations~group",
    addGroupMemberEmail = "addGroupMember~email",
}

export interface IPreviousMessage {
    createdAt: number
    recipients: Array<string>
    text: string
}

const NB_MAX_LAST_RECENT_RECIPIENTS = 6

export const createPersonalLibrary = (): Promise<{ feedbackId: string }> => {
    const url = Url.join(EngineFacade.rootLibraryApiUrl, "/userprofile/personalLibrary")
    return AuthenticatedHttp.post(url)
}

type IGetContactsArgs = [count?: number, search?: string]
function getContacts(type: ContactTypes.MAIL, ...args: IGetContactsArgs): Promise<IContact[]>
function getContacts(type: ContactTypes.SMS, ...args: IGetContactsArgs): Promise<IContact[]>
function getContacts(type: ContactTypes, ...args: IGetContactsArgs): Promise<IContact[]> {
    const [count, search] = args
    let url = Url.join(EngineFacade.libraryApiUrl, "userprofile", "contacts")
    url = Url.addQueryParams(url, { count, type, search })

    return AuthenticatedHttp.get(url)
}
export { getContacts }

export const getRecentInternalContacts = async (type = ContactTypes.MAIL): Promise<IContact[]> => {
    let url = Url.join(EngineFacade.rootLibraryApiUrl, "userprofile", "contacts", "recent")
    url = Url.addQueryParams(url, { count: NB_MAX_LAST_RECENT_RECIPIENTS, type })

    return AuthenticatedHttp.get(url)
}

export const getUserPictureUrl = (userId: string, resourceId?: string): string => {
    let url = Url.join(EngineFacade.rootLibraryApiUrl, "userprofile", userId, "picture")
    if (resourceId) {
        url = Url.addQueryParams(url, { resourceId })
    }
    return url
}

export const editUserSettings = async (userSettings: IUserSettings): Promise<void> => {
    const url = Url.join(EngineFacade.rootLibraryApiUrl, "userprofile", "settings")
    await AuthenticatedHttp.put(url, userSettings)
}

export const getPreviousSharedMessages = async (
    type: UserMessageShareType,
): Promise<Array<IPreviousMessage>> => {
    const url = Url.join(
        EngineFacade.rootLibraryApiUrl,
        "userprofile",
        "share-messages?type=" + type,
    )
    return AuthenticatedHttp.get(url)
}
