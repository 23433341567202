import { KeycloakConfig, KeycloakOnLoad } from "keycloak-js"

import { Http } from "common/utils/Http"
import Url from "common/utils/Url"

export interface IPortalConfig {
    keycloakJsUrl: string
    keycloakConfig: KeycloakConfig & { mode: KeycloakOnLoad }
}

class PortalConfig {
    public config: IPortalConfig
    public apiUrl: string

    constructor() {
        this.apiUrl = Url.join(window.location.origin, "api")
    }

    public async init(): Promise<void> {
        this.config = await Http.get(Url.join(this.apiUrl, "params"))
    }
}

export default new PortalConfig()
