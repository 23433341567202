import React, { useRef, useState } from "react"
import _ from "lodash"

import Fieldset from "common/components/formV2/field/fieldset/Fieldset"
import { IField } from "common/components/formV2/FormTypes"
import I18n from "common/services/i18n/I18n"
import Device from "common/utils/Device"
export enum TEXT_FIELD_AUTO_COMPLETE {
    ON = "on",
    OFF = "off",
}

export interface ITextField extends IField {
    value?: string
    onChange?(v: string): void
    hasClearButton?: boolean
    autoComplete?: TEXT_FIELD_AUTO_COMPLETE
    placeholder?: string
    maxLength?: number
    autoSelect?: boolean
    pattern?: string
    childrenInRow?: boolean
}

const TextField: React.FunctionComponent<ITextField> = ({
    value,
    customErrorMessage,
    disabled,
    label,
    required,
    loading,
    onChange,
    onClick,
    onBlur,
    onKeyDown,
    ariaLabel,
    hasClearButton,
    autoComplete = TEXT_FIELD_AUTO_COMPLETE.ON,
    placeholder,
    autoFocus,
    autoSelect,
    maxLength,
    withMargin,
    withFocus,
    pattern,
    children,
    childrenInRow,
}) => {
    const id = useRef(_.uniqueId("textField_"))
    const [showError, setShowError] = useState(false)

    return (
        <Fieldset
            disabled={disabled}
            loading={loading}
            onClear={hasClearButton && value ? () => onChange?.("") : undefined}
            customErrorMessage={customErrorMessage}
            label={label}
            forLabel={id.current}
            withMargin={withMargin}
            withFocus={withFocus}
            showError={showError}
            required={required}
            childrenInRow={childrenInRow}
        >
            <input
                id={id.current}
                name="text"
                type="text"
                className="textField paragraph--normal"
                value={value ?? ""}
                onFocus={(e) => {
                    autoSelect && e.target.select()
                }}
                placeholder={placeholder ?? I18n.getString("common.form.textField.placeholder")}
                required={!!required}
                tabIndex={disabled ? -1 : 0}
                onChange={(e) => (!disabled ? onChange?.(e.target.value) : undefined)}
                aria-label={ariaLabel}
                disabled={disabled}
                autoComplete={autoComplete}
                readOnly={!onChange}
                autoFocus={Device.isIOS() ? false : autoFocus}
                maxLength={maxLength}
                onBlur={(e) => {
                    onBlur?.(e)
                    setShowError(true)
                }}
                onClick={onClick}
                onKeyDown={onKeyDown}
                pattern={pattern}
            />
            {children}
        </Fieldset>
    )
}
export default TextField
