import { FEATURE_ID } from "common/components/detailedOffer/DETAILED_OFFER"

export enum DisabledOfferReason {
    OWNER_HAS_FREE_LIBRARY = "owner-has-free-library",
    USER_HAS_FREE_LIBRARY = "user-has-free-library",
}

export type IOfferFeatureValue = number | boolean | string | null | undefined

export type IOfferFeature = {
    featureId: FEATURE_ID
    value: IOfferFeatureValue
    display: boolean
}

export enum OFFER_PLANS {
    free = "free",
    starter = "starter",
    personal = "personal",
    standard = "standard",
    advanced = "advanced",
}

export enum OFFER_GROUPS_IDS {
    individual = "individual",
    business = "business",
}

export const PLAN_GROUP_MAPPING = {
    [OFFER_PLANS.free]: OFFER_GROUPS_IDS.individual,
    [OFFER_PLANS.starter]: OFFER_GROUPS_IDS.individual,
    [OFFER_PLANS.personal]: OFFER_GROUPS_IDS.business,
    [OFFER_PLANS.standard]: OFFER_GROUPS_IDS.business,
    [OFFER_PLANS.advanced]: OFFER_GROUPS_IDS.business,
}

export enum FORMAT_TAXES {
    withTaxes = "withTaxes",
    withoutTaxes = "withoutTaxes",
    default = "default",
}

export interface IOfferOptions {
    plan: string
    priceInCents: number
    features: IOfferFeature[]
    options: IOfferOptions[]
    limit?: number
}

interface IOfferParams {
    plan: OFFER_PLANS
    limit?: number
    priceInCents: number
    features: IOfferFeature[]
    options: IOfferOptions[]
}

export interface IOffer extends IOfferParams {
    limit: number
    img?: string
    disabled?: boolean
    disabledReason?: DisabledOfferReason
}

export interface ISelectedOption {
    plan: string
    quantity: number
}
