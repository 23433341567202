import { BADGE_TYPES } from "common/components/badge/Badge"
import {
    RightBadge,
    RightIcon,
    RightText,
} from "common/components/menu/components/menuItem/BasicMenuItem"
import { IRightElement } from "common/components/menu/components/menuItem/MenuItemTypes"

export const getRightBadge = (type: BADGE_TYPES, text: string): IRightElement => ({
    component: RightBadge,
    props: { type, text },
})
export const getRightIcon = (iconId: string): IRightElement => ({
    component: RightIcon,
    props: { iconId },
})

export const getRightText = (text: string): IRightElement => ({
    component: RightText,
    props: { text },
})
