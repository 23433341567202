import { StatisticsContext } from "common/services/statistics/StatisticsContext"
import { StatisticsContextManager } from "common/services/statistics/StatisticsContextManager"

interface IStatisticsContext {
    sessionId?: string
    trackingId?: string
    trackingIsAnonymous?: boolean
    context: keyof typeof StatisticsContext
}

class StatisticsContextFacade {
    private statisticsContextManager: StatisticsContextManager

    public setStatisticsContext(statisticsContextManager: StatisticsContextManager) {
        this.statisticsContextManager = statisticsContextManager
    }

    public getStatisticsContext(): IStatisticsContext | undefined {
        if (!this.statisticsContextManager) {
            return
        }

        return {
            sessionId: this.statisticsContextManager.getSessionId(),
            trackingId: this.statisticsContextManager.getTrackingId(),
            trackingIsAnonymous: this.statisticsContextManager.isAnonymous(),
            context: this.statisticsContextManager.getStatisticsContext(),
        }
    }
}

export default new StatisticsContextFacade()
