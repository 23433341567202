import EngineFacade from "common/services/engine/EngineFacade"
import { Http } from "common/utils/Http"
import Url from "common/utils/Url"

const loadColorsFontsShadows = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        let uiKitUrl = Url.join(EngineFacade.baseEngineUrl, "ui-kit/ui-kit2.css")
        uiKitUrl = Url.addPreventCacheQueryParams(uiKitUrl)

        const link = document.createElement("LINK") as HTMLLinkElement
        link.rel = "stylesheet"
        link.type = "text/css"
        link.href = uiKitUrl
        link.onload = () => resolve()
        link.onerror = reject
        document.head.appendChild(link)
    })
}

const loadIcons = async (url: string = "picto/icon_board.svg"): Promise<void> => {
    const iconsUrl = Url.join(EngineFacade.baseEngineUrl, url)
    const svgSymbols = await Http.get<string>(iconsUrl, { preventCache: true })
    const container = document.createElement("DIV")
    container.id = "iconsDefinitionsContainer"
    container.style.display = "none"
    container.innerHTML = svgSymbols
    document.body.insertAdjacentHTML("afterbegin", svgSymbols)
}

// Load logo and icon for offline page, is put in cache by service worker
const loadUiElementsForOfflinePage = (): Promise<void[]> => {
    const logoOfflinePageUrl = Url.join(
        EngineFacade.baseEngineUrl,
        "assets/logos/ubstream/color.svg",
    )
    const iconOfflinePageUrl = Url.join(EngineFacade.baseEngineUrl, "assets/misc/offline.svg")
    const offlineHtml = Url.join(
        EngineFacade.baseEngineUrl,
        "beelibrary/version/1.0/offlinePage/offlinePage.html",
    )
    const offlineCss = Url.join(
        EngineFacade.baseEngineUrl,
        "beelibrary/version/1.0/offlinePage/offlinePage.css",
    )

    return Promise.all([
        Http.get(logoOfflinePageUrl),
        Http.get(iconOfflinePageUrl),
        Http.get(offlineHtml),
        Http.get(offlineCss),
    ])
}

const load = (): Promise<[void, void]> => Promise.all([loadColorsFontsShadows(), loadIcons()])

export default {
    load,
    loadColorsFontsShadows,
    loadIcons,
    loadUiElementsForOfflinePage,
}
