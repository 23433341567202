import React, { useMemo } from "react"

import "./dialogBoxTitle.less"

interface IDialogBoxTitleProps {
    value: string
}

const PUNCTUATION_MARKS = ["?", "!", ":"]

const getSplitValue = (value: string): [string] | [string, string] => {
    const splitValue = value.split(" ")
    const lastWord = splitValue[splitValue.length - 1]

    if (splitValue.length > 2 && PUNCTUATION_MARKS.includes(lastWord)) {
        return [
            splitValue.slice(0, splitValue.length - 2).join(" ") + " ",
            splitValue.slice(-2).join(" "),
        ]
    } else {
        return [value]
    }
}

const DialogBoxTitle: React.FunctionComponent<IDialogBoxTitleProps> = ({ value }) => {
    const splitValue = useMemo(() => getSplitValue(value), [value])

    return (
        <div className="dialogBoxTitle">
            {splitValue.map((v, i) => (
                <span key={i} className="dialogBoxTitle__text title--normal">
                    {v}
                </span>
            ))}
        </div>
    )
}

export default DialogBoxTitle
