import _ from "lodash"

import { IDiagnoseRaw } from "common/data/diagnoses/DiagnoseTypes"
import AuthFacade from "common/services/auth/AuthFacade"
import EngineFacade from "common/services/engine/EngineFacade"
import Url from "common/utils/Url"

export enum EVENTS_KINDS {
    PROGRESS = "progress",
    STANDARD = "standard",
}

export enum PROGRESS_SSE_STATUS {
    PENDING = "pending",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
    ERROR = "error",
}

interface ISseEvent {
    kind: EVENTS_KINDS
}

export interface ISseStandardEvent<E> {
    kind: EVENTS_KINDS.STANDARD
    type: string
    data: {
        contexts: string[]
        status: string
        $diagnoses: IDiagnoseRaw[]
    } & E
}

export enum SSE_EVENTS {
    EVENT_CREATE_SELF_MANAGED_ORG = "EventCreateSelfManagedOrg",
}

export enum SSE_STATUS {
    END = "end",
    ERROR = "error",
}

export enum STREAM_TYPES {
    LIVE = "LIVE",
    DB = "DB",
    DB_AND_LIVE = "DB_AND_LIVE",
}

interface ISseProgressEventData {
    id: string
    status: PROGRESS_SSE_STATUS
    percentage: number
    $diagnoses: IDiagnoseRaw[]
    data?: any
    title?: string
}

export interface ISseProgressEvent extends ISseEvent {
    kind: EVENTS_KINDS.PROGRESS
    data: ISseProgressEventData
    type: string
}

export type IResolveSSE<R> = (result?: R) => void
export type IRejectSSE = (error?: any) => void

export type IOnEventCallback<R> = (
    event: ISseProgressEvent,
    resolve: IResolveSSE<R>,
    reject: IRejectSSE,
) => void

export interface ISseOptions<R> {
    feedbackId?: string
    url?: string
    contexts?: { [key: string]: string }
    stream?: STREAM_TYPES
    onOpen?: () => void
    onProgress?: (event: ISseProgressEvent) => void
    onCompleted?: IOnEventCallback<R>
    onError?: IOnEventCallback<R>
    onCancelled?: IOnEventCallback<R>
    onDiagnoses?: ($diagnoses: IDiagnoseRaw[]) => void
    onEnd?: ($diagnoses: IDiagnoseRaw[], event: ISseProgressEvent) => void
    onEvent?: (event: any, resolve: IResolveSSE<R>, reject: IRejectSSE) => void
    onClose?: () => void
    heartbeatTimeout?: number
}

const DEBUG = false

export default class Sse<R = void> {
    private readonly options: ISseOptions<R>
    private readonly url: string
    private readonly diagnoses: IDiagnoseRaw[]
    private eventSource: any

    private isOpened = false
    private isOpenCallbackCalled = false
    private isReconnectingWithNewToken = false

    public static contextsToString(contexts: { [key: string]: string }): string {
        const items: string[] = []
        _.forEach(contexts, (value, key) => {
            items.push(`"${key}:${value}"`)
        })
        return `[${items.join(",")}]`
    }

    constructor(options: ISseOptions<R> = {}) {
        this.options = options

        this.url =
            options.url || Url.join(EngineFacade.defaultFeedbackUrl, options.feedbackId || "")
        this.diagnoses = []
    }

    private _handlerProgress(
        event: ISseProgressEvent,
        resolve: IResolveSSE<R>,
        reject: IRejectSSE,
    ): void {
        const { data } = event
        const { status } = data
        const {
            onProgress,
            onCompleted = () => resolve(),
            onError = () => reject(),
            onCancelled,
            onDiagnoses,
            onEnd,
        } = this.options

        if (data.$diagnoses.length > 0) {
            this.diagnoses.push(...data.$diagnoses)
            onDiagnoses && onDiagnoses(data.$diagnoses)
        }

        if (status !== PROGRESS_SSE_STATUS.PENDING && onEnd) {
            onEnd(this.diagnoses, event)
        }

        switch (status) {
            case PROGRESS_SSE_STATUS.COMPLETED:
                onCompleted(event, resolve, reject)
                break
            case PROGRESS_SSE_STATUS.ERROR:
                onError(event, resolve, reject)
                break
            case PROGRESS_SSE_STATUS.CANCELLED:
                onCancelled?.(event, resolve, reject)
                break
            default:
                onProgress && onProgress(event)
                break
        }
    }

    private _handlerStandard(
        event: ISseStandardEvent<R>,
        resolve: IResolveSSE<R>,
        reject: IRejectSSE,
    ): void {
        const { onEvent } = this.options
        const { data } = event
        if (data.$diagnoses && data.$diagnoses.length > 0) {
            this.diagnoses.push(...data.$diagnoses)
        }

        onEvent && onEvent(event, resolve, reject)
    }

    public async open(): Promise<R> {
        if (this.isOpened) {
            throw new Error("Sse already opened! Use a new instance of Sse.")
        }
        this.isOpened = true

        return new Promise<R>(this.openConnection)
    }

    public close(): void {
        DEBUG && console.log("close sse (manually)")
        this.closeConnection()
    }

    public end(): void {
        DEBUG && console.log("close sse (end)")
        this.closeConnection()
    }

    private openConnection = async (
        _resolve: (result: R) => void,
        _reject: (err?: any) => void,
    ): Promise<void> => {
        const { url, options } = this
        const { contexts, stream, heartbeatTimeout } = options

        let feedbackUrl = url
        if (contexts) {
            feedbackUrl = Url.addQueryParams(feedbackUrl, {
                contexts: Sse.contextsToString(contexts),
            })
        }

        if (stream) {
            feedbackUrl = Url.addQueryParams(feedbackUrl, { stream })
        }

        const config: any = {
            // avoid to check server's identity (https certificate) so that it doesn't trigger a connection error
            https: { rejectUnauthorized: false },
            heartbeatTimeout: heartbeatTimeout,
        }

        if (AuthFacade.isAuthenticated()) {
            config.headers = {
                authorization: await AuthFacade.getToken(),
            }
        }

        this.eventSource = new window.EventSource(feedbackUrl, config)

        const resolve = (result: R): void => {
            DEBUG && console.log("close sse (resolve)")
            this.closeConnection()
            _resolve(result)
        }

        const reject = (err: any): void => {
            DEBUG && console.log("close sse (reject)")
            this.closeConnection()
            _reject(err)
        }

        this.eventSource.addEventListener("open", (): void => {
            DEBUG && console.log("open sse")
            if (options.onOpen && !this.isOpenCallbackCalled) {
                options.onOpen?.()
                this.isOpenCallbackCalled = true
            }

            if (this.isReconnectingWithNewToken) {
                this.isReconnectingWithNewToken = false
            }
        })

        this.eventSource.addEventListener("error", (err: any): void => {
            // An error can occur when reconnecting after a crash (ex: after a deployment) because
            // the reconnection is done with an old token. In this case, the sse channel is
            // re-open with a refreshed token
            if (
                this.eventSource.readyState === EventSource.CLOSED &&
                err.status === 403 &&
                AuthFacade.isAuthenticated()
            ) {
                if (!this.isReconnectingWithNewToken) {
                    DEBUG && console.log("reconnecting with refreshed token")
                    this.isReconnectingWithNewToken = true
                    setTimeout(() => this.openConnection(_resolve, _reject), 0)
                    return
                } else {
                    this.isReconnectingWithNewToken = false
                    DEBUG && console.log("reconnecting with refreshed token failed")
                }
            }

            console.error("sse connexion error", err)
        })

        this.eventSource.addEventListener("message", (rawEvent: { data: string }): void => {
            try {
                const parsedEvent = JSON.parse(rawEvent.data) as ISseEvent

                DEBUG && console.log(parsedEvent)

                const { kind } = parsedEvent

                if (kind === EVENTS_KINDS.PROGRESS) {
                    this._handlerProgress(parsedEvent as ISseProgressEvent, resolve, reject)
                } else if (kind === EVENTS_KINDS.STANDARD) {
                    this._handlerStandard(parsedEvent as ISseStandardEvent<R>, resolve, reject)
                }
            } catch (err) {
                reject(err)
            }
        })
    }

    private closeConnection(): void {
        this.eventSource?.close()
        this.options.onClose?.()
    }
}
