import React from "react"

import { classNames } from "common/utils/JSX"

import "./wrapper.less"

interface IWrapperProps {
    disabled?: boolean
    noFocus?: boolean
    withMargin?: boolean
}

const Wrapper: React.FunctionComponent<IWrapperProps> = ({
    children,
    disabled,
    noFocus,
    withMargin,
}) => (
    <div
        {...classNames(
            "wrapper",
            { "wrapper--withFocus": !noFocus },
            { "wrapper--disabled": disabled },
            { "wrapper--withMargin": withMargin },
        )}
        aria-disabled={disabled}
    >
        {children}
    </div>
)

export default Wrapper
