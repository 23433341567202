import _ from "lodash"

const DEFAULT_MAX_SIZE = 1000

interface ICache {
    [key: string]: Blob
}

class ProtectedImgCache {
    maxSize: number
    cache: ICache
    cacheKeys: Array<string>

    constructor(maxSize = DEFAULT_MAX_SIZE) {
        this.maxSize = maxSize
        this.cache = {}
        this.cacheKeys = []
    }

    get(key: string) {
        return _.get(this.cache, key, null)
    }

    set(key: string, value: Blob) {
        if (!this.cacheKeys.includes(key)) {
            if (this.cacheKeys.length === this.maxSize && this.maxSize > 0) {
                const firstKey = this.cacheKeys.shift()
                delete this.cache[firstKey!]
            }

            this.cacheKeys.push(key)
        }

        this.cache[key] = value
    }
}

export default ProtectedImgCache
