import React, { useEffect, useRef } from "react"

import Button, { ACTION_TYPE } from "common/components/button/Button"
import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import I18n from "common/services/i18n/I18n"
import { classNames } from "common/utils/JSX"

import "./fieldset.less"

interface IFieldset {
    showError: boolean
    loading?: boolean
    onClear?: () => void
    customErrorMessage?: string
    disabled?: boolean
    label?: string
    forLabel?: string
    withMargin?: boolean
    withFocus?: boolean
    required?: boolean
    rightIcon?: {
        iconId: IconList
        onClick(): void
        ariaLabel: string
    }
    childrenInRow?: boolean
}

const Fieldset: React.FunctionComponent<IFieldset> = ({
    loading,
    onClear,
    customErrorMessage,
    children,
    disabled,
    label,
    forLabel,
    withMargin,
    withFocus,
    rightIcon,
    showError,
    required,
    childrenInRow,
}) => {
    const fieldsetDisabled = disabled
    const componentRef = useRef<HTMLFieldSetElement>(null)

    useEffect(() => {
        const haveInput = !!componentRef.current?.querySelector("input, textarea, select")
        if (!haveInput) {
            throw new Error("Fieldset Children should contain select, textarea or input")
        }
    }, [children])

    return (
        <fieldset
            {...classNames(
                "fieldset",
                { "fieldset--withError": customErrorMessage },
                { "fieldset--disabled": fieldsetDisabled },
                { "fieldset--withMargin": withMargin },
                { "fieldset--loading": loading },
                { "fieldset--showError": showError },
                { "fieldset--withFocus": withFocus ?? true },
            )}
            aria-disabled={fieldsetDisabled}
            ref={componentRef}
        >
            <div className="fieldset__field">
                <div className="fieldset__content">
                    {label ? (
                        <label htmlFor={forLabel} className="fieldset__label label--normal">
                            {label}
                            {required && <span className="fieldset__required"> *</span>}
                        </label>
                    ) : null}
                    <div
                        {...classNames("fieldset__children", {
                            "fieldset__children--horizontal": childrenInRow,
                        })}
                    >
                        {children}
                    </div>
                </div>

                {loading ? (
                    <div className="fieldset__loader">
                        <Icon
                            id={IconList.info_loader_sm}
                            iconClassName={"fieldset__loaderIcon common-icon--small"}
                        />
                    </div>
                ) : null}
                {onClear && !disabled && !loading ? (
                    <Button
                        actionType={ACTION_TYPE.third}
                        iconId={IconList.func_clear_search_sm}
                        title={I18n.getString("common.form.emptyField")}
                        ariaAttributes={{
                            "aria-label": I18n.getString("common.form.emptyField"),
                        }}
                        onClick={onClear}
                    />
                ) : null}
                {rightIcon && (
                    <div className="fieldset__rightIcon">
                        <Button
                            actionType={ACTION_TYPE.third}
                            iconId={rightIcon.iconId}
                            disabled={fieldsetDisabled}
                            onClick={rightIcon.onClick}
                            ariaAttributes={{
                                "aria-label": rightIcon.ariaLabel,
                            }}
                        />
                    </div>
                )}
            </div>
            {/* aria-hidden=false to make aria-live working on screen reader*/}
            <span
                aria-hidden="false"
                aria-live="polite"
                {...classNames("fieldset__error", "label--normal", {
                    "fieldset__error--visible": !!customErrorMessage,
                })}
            >
                {customErrorMessage}
            </span>
        </fieldset>
    )
}

export default Fieldset
