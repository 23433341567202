import { IModalOptions, IModalProperties } from "common/components/modal/ModalTypes"
import { MODAL_TRANSITION_DURATION_IN_MS } from "common/constants/ANIMATION_TIME"
import { ICommonState } from "common/state/store/DefaultState"
import { IAction, IDispatch } from "common/state/StoreTypes"

export const SHOW_MODAL_ACTION = "SHOW_MODAL_ACTION"
export const SHOW_COVER_ACTION = "SHOW_COVER_ACTION"
export const CLOSE_ACTIVE_MODAL_ACTION = "CLOSE_ACTIVE_MODAL_ACTION"
export const REMOVE_MODAL_ACTION = "REMOVE_MODAL_ACTION"
export const UPDATE_ACTIVE_MODAL_ACTION = "UPDATE_ACTIVE_MODAL_ACTION"
export const SHOW_ASYNC_IMPORT_LOADER_ACTION = "SHOW_ASYNC_IMPORT_LOADER_ACTION"
export const HIDE_ASYNC_IMPORT_LOADER_ACTION = "HIDE_ASYNC_IMPORT_LOADER_ACTION"

export const showModal = (
    modalType: string,
    modalProps: object = {},
    modalOptions: IModalOptions = {},
): IAction<IModalProperties> => ({
    type: SHOW_MODAL_ACTION,
    modalType,
    modalProps,
    modalOptions,
})

export const updateActiveModal = (newProps: object): IAction<{ newProps: object }> => ({
    type: UPDATE_ACTIVE_MODAL_ACTION,
    newProps,
})

const closeActiveModal = (modalId: string): IAction<{ modalId: string }> => ({
    type: CLOSE_ACTIVE_MODAL_ACTION,
    modalId,
})

const removeModal = (modalId: string): IAction<{ modalId: string }> => ({
    type: REMOVE_MODAL_ACTION,
    modalId,
})

export const hideActiveModal = () => (dispatch: IDispatch, getState: () => ICommonState) => {
    return new Promise<void>((resolve) => {
        const modals = getState().modals
        let modalsLastIndex = modals.length - 1
        while (modals[modalsLastIndex]?.isClosing) {
            modalsLastIndex--
        }

        if (modals[modalsLastIndex]) {
            const modalId = modals[modalsLastIndex].id
            dispatch(closeActiveModal(modalId))

            window.setTimeout(() => {
                dispatch(removeModal(modalId))
                resolve()
            }, MODAL_TRANSITION_DURATION_IN_MS)
        } else {
            resolve()
        }
    })
}

export const showAsyncImportLoader = (): IAction => ({
    type: SHOW_ASYNC_IMPORT_LOADER_ACTION,
})

export const hideAsyncImportLoader = (): IAction => ({
    type: HIDE_ASYNC_IMPORT_LOADER_ACTION,
})

export const showCover = (show: boolean): IAction<{ show: boolean }> => ({
    type: SHOW_COVER_ACTION,
    show,
})
