import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { CSSTransition } from "react-transition-group"

import BackButtonCardHeader from "common/components/contextMenu/components/floatingCards/components/header/BackButtonCardHeader"
import { MenuContainer } from "common/components/menu/Menu"
import I18n from "common/services/i18n/I18n"
import Dom from "common/utils/Dom"
import { classNames } from "common/utils/JSX"

import "./floatingCards.less"

export interface IFloatingCard {
    id: string
    content: React.ReactNode
    header?: React.ReactNode
}

interface IFloatingCardsProps {
    visible: boolean
    currentId: string
    cards: Array<IFloatingCard>
    onClose(): void
    container?: Element
    onScroll?: React.UIEventHandler
}

interface IFloatingCardsState {
    displayedId: null | string
    previouslyDisplayedIds: string[]
}

const FloatingCards: React.FunctionComponent<IFloatingCardsProps> & {
    Header: typeof BackButtonCardHeader
} = ({ visible, currentId, cards, onClose, container, onScroll }) => {
    const [state, setState] = useState<IFloatingCardsState>({
        displayedId: null,
        previouslyDisplayedIds: [],
    })

    // Handle auto scroll
    useEffect(() => {
        const cardContainer = document.querySelector(".floatingCards__cardsContainer--current")
        cardContainer && cardContainer.scrollTo(0, 0)
    }, [state.displayedId])

    // Handle navigation "history"
    useEffect(() => {
        setState((prevState) => {
            const index = prevState.previouslyDisplayedIds.indexOf(currentId)
            let previouslyDisplayedIds: Array<string> = []
            if (prevState.displayedId) {
                previouslyDisplayedIds =
                    index === -1
                        ? prevState.previouslyDisplayedIds.concat(prevState.displayedId)
                        : prevState.previouslyDisplayedIds.slice(0, index)
            }

            return { displayedId: currentId, previouslyDisplayedIds }
        })
    }, [currentId])

    const cardContainerClassName = "floatingCards__cardContainer"

    const onContainerClick = (e) => Dom.hasClass(e.target, cardContainerClassName) && onClose()

    return ReactDOM.createPortal(
        <CSSTransition in={visible} timeout={300} mountOnEnter unmountOnExit>
            <div
                data-testid="floatingCards"
                {...classNames("floatingCards")}
                onClick={onContainerClick}
            >
                <div className="floatingCards__cover" />

                <div className="floatingCards__transitionContainer">
                    {cards.map(({ id, header, content }) => (
                        <div
                            key={id}
                            {...classNames("floatingCards__cardsContainer", {
                                "floatingCards__cardsContainer--current": id === state.displayedId,
                                "floatingCards__cardsContainer--previous":
                                    state.previouslyDisplayedIds.includes(id),
                            })}
                            onScroll={onScroll}
                        >
                            {/* cardContainer is necessary to have the scrollbar (https://github.com/philipwalton/flexbugs/issues/136#issuecomment-199641030)*/}
                            <div className={cardContainerClassName}>
                                <div className="floatingCards__card">
                                    {header && (
                                        <div className="floatingCards__cardHeader">{header}</div>
                                    )}

                                    <MenuContainer>{content}</MenuContainer>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="floatingCards__footer" onClick={onClose}>
                        <div className="floatingCards__footerTxt label--normal">
                            {I18n.getString("common.contextMenu.fullScreen.closeButton.label")}
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>,
        container || document.body,
    )
}

FloatingCards.Header = BackButtonCardHeader
export default FloatingCards
