import React from "react"
import _ from "lodash"

export const readFile = (file: Blob | File, asDataUrl: boolean = false): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => resolve((reader.result as string) || "")
        asDataUrl ? reader.readAsDataURL(file) : reader.readAsText(file)
    })
}

export const isAFileBeingDragged = (e: React.DragEvent | DragEvent): boolean =>
    _.some(e.dataTransfer?.types, (t) => ["Files", "text/uri-list", "text/x-moz-url"].includes(t))

export const computeFileWeightLabel = (weight: number): string | undefined => {
    const roundNumberToOneDecimal = (number: number, scale = 1) =>
        Math.round((number / scale) * 10) / 10

    enum MEDIA_WEIGHT {
        GO = 1000000000,
        MO = 1000000,
        KO = 1000,
    }

    if (weight >= MEDIA_WEIGHT.GO) {
        return `${roundNumberToOneDecimal(weight, MEDIA_WEIGHT.GO)} Go`
    } else if (weight >= MEDIA_WEIGHT.MO) {
        return `${roundNumberToOneDecimal(weight, MEDIA_WEIGHT.MO)} Mo`
    } else if (weight >= MEDIA_WEIGHT.KO) {
        return `${roundNumberToOneDecimal(weight, MEDIA_WEIGHT.KO)} ko`
    } else {
        return weight === 0 ? undefined : `${roundNumberToOneDecimal(weight)} o`
    }
}
