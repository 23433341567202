export default {
    read(key) {
        try {
            if (_.keys(window.sessionStorage).indexOf(key) !== -1) {
                return window.sessionStorage.getItem(key)
            } else {
                return undefined
            }
        } catch (e) {
            console.error("Session storage not available")
            return undefined
        }
    },
    write(key, value) {
        try {
            if (_.isUndefined(value)) {
                window.sessionStorage.removeItem(key)
            } else {
                window.sessionStorage.setItem(key, value)
            }
        } catch (e) {
            console.error("Session storage not available")
        }
    },
    clear() {
        try {
            window.sessionStorage.clear()
        } catch (e) {
            console.error("Session storage not available")
        }
    },
}
