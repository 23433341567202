import * as React from "react"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"
import I18n from "common/services/i18n/I18n"
import { classNames } from "common/utils/JSX"

import "./inlineInfoMessage.less"

export interface IInlineInfoMessageProps {
    title: string
    message?: string
    buttonLabel?: string
    className?: string
    onClose?: () => boolean | void // return boolean for preventDefault
    additionalButton?: {
        label: string
        onClick: () => boolean | void // return boolean for preventDefault
    }
}

const PREDEFINED_MESSAGE = {
    get ERROR() {
        return {
            title: I18n.getString("common.global.oops") as string,
            message: I18n.getString("common.message.error") as string,
        }
    },
}

const InlineInfoMessage: React.FunctionComponent<IInlineInfoMessageProps> = ({
    className,
    title,
    message,
    buttonLabel,
    onClose,
    additionalButton,
}) => {
    return (
        <div {...classNames("inlineInfoMessage", className)}>
            <div className="inlineInfoMessage__status">
                <span className="inlineInfoMessage__title title--strong">{title}</span>
                {message && (
                    <span className="inlineInfoMessage__message paragraph--normal">{message}</span>
                )}
            </div>

            {additionalButton && (
                <Button
                    className="inlineInfoMessage__altButton"
                    label={additionalButton.label}
                    onClick={(event) => additionalButton.onClick() && event.preventDefault()}
                    tag={BUTTON_TAG.success}
                />
            )}

            {onClose && (
                <Button
                    label={buttonLabel || I18n.getString("common.global.close")}
                    onClick={(event) => onClose() && event.preventDefault()}
                    tag={!additionalButton ? BUTTON_TAG.success : undefined}
                    actionType={additionalButton ? ACTION_TYPE.secondary : undefined}
                />
            )}
        </div>
    )
}

export default InlineInfoMessage
export { PREDEFINED_MESSAGE }
