import React from "react"

import { IOther } from "common/components/formV2/FormTypes"
import Wrapper from "common/components/formV2/other/wrapper/Wrapper"
import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import I18n from "common/services/i18n/I18n"
import { bemBlock } from "common/utils/Bem"

import "./checkbox.less"

export enum COLOR_CHECKBOX {
    STANDARD = "standard",
    CUSTOM = "custom",
}

export enum CLICK_ZONE {
    ALL = "ALL",
    ICON = "ICON",
}

export type ICheckboxType = "CHECKBOX" | "RADIO"

type IEventCheck =
    | React.MouseEvent<HTMLDivElement | Element>
    | React.KeyboardEvent<HTMLDivElement | Element>

export interface ICheckboxProps extends IOther {
    checked: boolean
    checkboxType?: ICheckboxType
    clickZone?: CLICK_ZONE
    onChange?: (checkStatus: boolean, e: IEventCheck) => void
    color?: COLOR_CHECKBOX
    htmlLabel?: boolean
}

const bem = bemBlock("checkbox")

const Checkbox: React.FunctionComponent<ICheckboxProps> = ({
    checked,
    checkboxType = "CHECKBOX",
    label,
    htmlLabel,
    onChange,
    disabled,
    ariaLabel,
    color = COLOR_CHECKBOX.STANDARD,
    focusDisabled,
    withMargin,
    children,
    clickZone = CLICK_ZONE.ALL,
}) => {
    const _onClick = (e: IEventCheck) => !disabled && onChange?.(!checked, e)

    const renderLabel = (label: string) => {
        if (htmlLabel || I18n.isStringWithHTML(label)) {
            return <span dangerouslySetInnerHTML={{ __html: label }} />
        } else {
            return label
        }
    }

    return (
        <Wrapper disabled={disabled} withMargin={withMargin} noFocus={!label}>
            <div
                className={bem({
                    [color]: true,
                    checked: checked,
                    noLabel: !label,
                    allZone: clickZone === CLICK_ZONE.ALL,
                })}
                aria-checked={checked}
                role="checkbox"
                onClick={clickZone === CLICK_ZONE.ALL ? _onClick : undefined}
                onKeyDown={(e) => {
                    if (e.key === " ") {
                        e.preventDefault()
                        _onClick(e)
                    }
                }}
                aria-label={ariaLabel}
                aria-hidden={focusDisabled}
                tabIndex={disabled || focusDisabled ? -1 : 0}
                aria-disabled={disabled}
                style={
                    clickZone === CLICK_ZONE.ICON
                        ? {
                              cursor: "auto",
                          }
                        : {}
                }
            >
                <Icon
                    iconClassName={bem("icon", ["common-icon--medium"])}
                    onClick={clickZone === CLICK_ZONE.ICON ? _onClick : undefined}
                    id={
                        checkboxType === "RADIO"
                            ? checked
                                ? IconList.func_radio_selected_md
                                : IconList.func_radio_not_selected_md
                            : checked
                              ? IconList.func_checkbox_checked_md
                              : IconList.func_checkbox_unchecked_md
                    }
                />
                {children}
                {label && <label className="label--normal">{renderLabel(label)}</label>}
            </div>
        </Wrapper>
    )
}

export default Checkbox
