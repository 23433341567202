import React from "react"
import _ from "lodash"

import GoToAlreadySubscribeOffer from "@/screens/cards/offersCard/goToAlreadySubscribeOffer/GoToAlreadySubscribeOffer"
import { I18n } from "@/services/i18n/I18n"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"
import FeatureList from "common/components/detailedOffer/components/featureList/FeatureList"
import { PRICE_SIZE } from "common/components/detailedOffer/components/offerPrice/OFFER_PRICE"
import OfferPrice from "common/components/detailedOffer/components/offerPrice/OfferPrice"
import { FEATURE_ID } from "common/components/detailedOffer/DETAILED_OFFER"
import { IOffer } from "common/types/OfferTypes"
import { classNames } from "common/utils/JSX"

import "./offer.less"

interface IOfferProps {
    offer: IOffer
    onOfferSelected: (offerPlan: string) => void
    goToDetailedOffer: (offerPlan: string) => void
    withoutTaxes: boolean
    urlAlreadySubscribeOffer?: string
    hidden?: boolean
}

export const Offer: React.FunctionComponent<IOfferProps> = ({
    onOfferSelected,
    goToDetailedOffer,
    offer,
    urlAlreadySubscribeOffer,
    withoutTaxes,
    hidden = false,
}) => {
    const { plan, priceInCents, features, options, img } = offer

    const userSlots = _.find(features, (feature) => feature.featureId === FEATURE_ID.USER_SLOTS)

    return (
        <div {...classNames("offer", { "offer--hidden": hidden })}>
            <div className="offer__header">
                {img && (
                    <img
                        src={img}
                        alt={I18n.getString("common.offer.img.alt")}
                        className={"offer__cover"}
                        data-testid={"cover"}
                    />
                )}
                <div className="offer__title title--weakest">
                    {I18n.getString(`common.offer.name.${plan}`)}
                </div>
            </div>
            <div className="offer__content">
                <div className="offer__top">
                    <OfferPrice
                        priceInCents={
                            userSlots && _.isNumber(userSlots.value) && userSlots.value > 1
                                ? priceInCents / userSlots.value
                                : priceInCents
                        }
                        withoutTaxes={withoutTaxes}
                        priceFrom={options.length > 0}
                        keepPriceFromSpace
                        priceSize={PRICE_SIZE.STRONGEST}
                        pricePerUser={
                            userSlots && _.isNumber(userSlots.value) && userSlots.value > 1
                        }
                    />
                    {urlAlreadySubscribeOffer ? (
                        <GoToAlreadySubscribeOffer
                            urlAlreadySubscribeOffer={urlAlreadySubscribeOffer}
                        />
                    ) : (
                        <Button
                            onClick={() => onOfferSelected(plan)}
                            label={I18n.getString("card.offer.button.offerSelected")}
                            actionType={ACTION_TYPE.primary}
                            tag={BUTTON_TAG.success}
                            tabIndex={2}
                        />
                    )}
                </div>
                <div className="offer__bottom">
                    <FeatureList featureList={features} addIcon withBottomMargin />
                    {options.length > 0 && (
                        <Button
                            onClick={() => goToDetailedOffer(plan)}
                            label={I18n.getString("card.offer.button.customize")}
                            actionType={ACTION_TYPE.secondary}
                            tabIndex={3}
                            className="offer__customize"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
