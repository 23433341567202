import React from "react"

import DeviceUtilities from "common/utils/Device"
import { classNames } from "common/utils/JSX"

import "./icon.less"

interface IIconProps {
    id: string
    iconClassName?: string
    style?: React.CSSProperties
    title?: string
    onClick?: React.MouseEventHandler
    dataTestId?: string
}

const Icon: React.FunctionComponent<IIconProps> = ({
    id,
    iconClassName,
    style,
    title,
    onClick,
    dataTestId,
}) => {
    // The key on the svg element is necessary to avoid a bug on some browsers (Firefox)
    // to avoid a bug that results in not updating the icon
    return (
        <div
            {...classNames("common-icon", iconClassName)}
            style={style}
            onClick={onClick}
            data-testid={dataTestId}
        >
            <svg key={id} focusable={false} aria-hidden={true}>
                {title && <title>{title}</title>}
                <use xlinkHref={`#${id}`} />
            </svg>
            {DeviceUtilities.isIE() && <div className="prevent-event" />}
        </div>
    )
}

export default Icon
