import Template from "common/components/standalonePage/template/StandalonePageTemplate"
import EngineFacade from "common/services/engine/EngineFacade"
import Url from "common/utils/Url"

import "./template/StandalonePageTemplate.less"

interface IStandalonePageRendererButton {
    label: string
    onClick(): void
}

export interface IStandalonePageRendererProps {
    pageName?: string
    title?: string
    message?: string
    button?: IStandalonePageRendererButton
}

const DEFAULT_PAGE_NAME = "Ubstream"

export default {
    render: (
        container: HTMLElement,
        { pageName, title, message, button }: IStandalonePageRendererProps,
    ) => {
        const baseEngineUrl = EngineFacade?.baseEngineUrl || "https://engine.ubstream.com"
        const backgroundUrl = Url.join(baseEngineUrl, "authAssets", "background", "portal.jpg")
        const logoUrl = Url.join(baseEngineUrl, "assets", "logos", "ubstream", "white.svg")

        document.body.style.overflow = "hidden"

        container.innerHTML = Template({
            backgroundUrl,
            title,
            message,
            logoUrl,
            pageName: pageName ?? DEFAULT_PAGE_NAME,
            buttonLabel: button?.label,
        })

        if (button) {
            const buttonElement = container.querySelector("button")
            buttonElement && (buttonElement.onclick = button.onClick)
        }
    },
}
