import React, { ReactNode } from "react"

import Button, { ACTION_TYPE } from "common/components/button/Button"
import DialogBoxCheckbox from "common/components/dialogBox/components/checkbox/DialogBoxCheckbox"
import DialogBoxParagraphs from "common/components/dialogBox/components/paragraphs/DialogBoxParagraphs"
import DialogBoxSelect from "common/components/dialogBox/components/select/DialogBoxSelect"
import DialogBoxTitle from "common/components/dialogBox/components/title/DialogBoxTitle"
import Form from "common/components/formV2/Form"
import Icon from "common/components/icon/Icon"
import { bem } from "common/utils/Bem"

import "common/components/icon/iconType.less"
import "./dialogBox.less"

export interface IDialogBoxButton {
    label: string
    action: () => void
    disabled?: boolean
}

export interface IDialogBoxIcon {
    id: string
    colorClass?: string // use an iconType class to set colors
    withBackground?: boolean
}

export interface IDialogBoxProps {
    title: string
    icon: IDialogBoxIcon
    mainButton: IDialogBoxButton
    secondaryButton?: IDialogBoxButton
    className?: string
    isForm?: boolean
    children?: ReactNode
    autofocus?: boolean
}

const CLASSNAME = "dialogBox"

const DialogBox: React.FunctionComponent<IDialogBoxProps> & {
    Paragraphs: typeof DialogBoxParagraphs
    Checkbox: typeof DialogBoxCheckbox
    Select: typeof DialogBoxSelect
} = ({
    title,
    icon,
    mainButton,
    secondaryButton,
    className,
    isForm,
    children,
    autofocus = true,
}) => {
    const renderContent = () => (
        <>
            {children}

            <div className={bem(CLASSNAME, "buttons")}>
                {secondaryButton && (
                    <Button
                        label={secondaryButton.label}
                        onClick={secondaryButton.action}
                        disabled={secondaryButton.disabled}
                        actionType={ACTION_TYPE.secondary}
                    />
                )}

                <Button
                    label={mainButton.label}
                    onClick={!isForm ? mainButton.action : undefined}
                    disabled={mainButton.disabled}
                    actionType={ACTION_TYPE.primary}
                    isSubmit={isForm}
                    autofocus={autofocus}
                />
            </div>
        </>
    )

    return (
        <div className={bem(CLASSNAME, [className])}>
            <div className={bem(CLASSNAME, "header")}>
                <div
                    className={bem(CLASSNAME, "iconContainer", {
                        withBackground: icon.withBackground,
                        withColor: !!icon.colorClass,
                    })}
                >
                    <Icon id={icon.id} iconClassName={icon.colorClass} />
                </div>

                <DialogBoxTitle value={title} />
            </div>

            {isForm ? <Form onSubmit={mainButton.action}>{renderContent()}</Form> : renderContent()}
        </div>
    )
}

DialogBox.Paragraphs = DialogBoxParagraphs
DialogBox.Checkbox = DialogBoxCheckbox
DialogBox.Select = DialogBoxSelect
export default DialogBox
