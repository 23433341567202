import { IDiagnoseRaw } from "common/data/diagnoses/DiagnoseTypes"

export enum ERROR_CODES {
    NOT_FOUND = 404,
    ACCESS_DENIED = 403,
    INTERNAL_ERROR = 500,
    ILLEGAL_STATE = 1010,
    REDIRECTION_ERROR = 3100,
    BLOCKED_HUB_NO_CONNECTED = 4020,
    BLOCKED_HUB_CONNECTED = 4021,
}

export interface IErrorPageRendererProps {
    code?: ERROR_CODES
    $diagnoses?: IDiagnoseRaw[]
    login?: () => void
    logout?: () => void
    goBack?: () => void
    disableButtons?: boolean
}
