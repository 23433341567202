import React, { useRef, useState } from "react"
import _ from "lodash"

import Fieldset from "common/components/formV2/field/fieldset/Fieldset"
import { IField } from "common/components/formV2/FormTypes"
import I18n from "common/services/i18n/I18n"
import Device from "common/utils/Device"

export interface INumberFieldProps extends IField {
    onChange: (value: [string, number], isValid: boolean) => void
    min?: number
    max?: number
    limit?: number | "any"
    value?: number | string
    hasClearButton?: boolean
    placeHolder?: string
}

const NumberField: React.FunctionComponent<INumberFieldProps> = ({
    min,
    max,
    value,
    limit,
    required,
    label,
    disabled,
    onChange,
    loading,
    hasClearButton,
    ariaLabel,
    customErrorMessage,
    autoFocus,
    withMargin,
    placeHolder,
}) => {
    const inputId = useRef(_.uniqueId("numberField_"))
    const [showError, setShowError] = useState(false)

    const _onChange = (value: string) =>
        !disabled && onChange([value, parseInt(value)], !isNaN(parseInt(value)))

    return (
        <Fieldset
            disabled={disabled}
            loading={loading}
            onClear={hasClearButton ? () => _onChange("0") : undefined}
            customErrorMessage={customErrorMessage}
            label={label}
            forLabel={inputId.current}
            withMargin={withMargin}
            showError={showError}
            required={required}
        >
            <input
                id={inputId.current}
                type="number"
                className="number paragraph--normal"
                placeholder={
                    placeHolder ||
                    (min && max
                        ? I18n.getString("common.form.numberField.placeholder", { min, max })
                        : I18n.getString("common.form.numberField.placeholder.alt"))
                }
                min={min}
                max={max}
                step={limit}
                required={!!required}
                tabIndex={disabled ? -1 : 0}
                onChange={(e) => _onChange(e.target.value)}
                value={value ?? ""}
                aria-label={ariaLabel}
                disabled={disabled}
                autoFocus={Device.isIOS() ? false : autoFocus}
                onBlur={() => setShowError(true)}
            />
        </Fieldset>
    )
}

export default NumberField
