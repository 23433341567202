import React from "react"

import { classNames } from "common/utils/JSX"

import "./menuTitle.less"

interface IMenuTitleProps {
    title: string
    className?: string
}

const MenuTitle: React.FunctionComponent<IMenuTitleProps> = ({ className, title }) => (
    <div {...classNames("menuTitle", className)}>
        <span className="menuTitle__text title--weakest">{title}</span>
    </div>
)

export default MenuTitle
