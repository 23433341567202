import _ from "lodash"

import Dom from "./Dom"

class DeviceUtilities {
    private readonly ios: boolean
    private readonly mobile: boolean
    private readonly tablet: boolean
    private readonly portrait: boolean
    private readonly android: boolean
    private readonly macos: boolean

    private touchScreen: undefined | boolean
    private ieVersion: undefined | number | false
    private positionSticky: undefined | boolean

    constructor() {
        const { device } = window

        this.ios = device.ios()
        this.mobile = device.mobile()
        this.tablet = device.tablet()
        this.portrait = device.portrait()
        this.android = device.android()
        this.macos = device.macos?.() ?? false

        // Fix desktop with touchscreen detected as tablet
        if (this.tablet && this.isIE()) {
            this.tablet = false
            Dom.removeClass(document.documentElement, "tablet")
            Dom.addClass(document.documentElement, "desktop")
        }

        // Fix an issue where the polyfill of process make device to be detected as "node-webkit" instead of "desktop"
        if (device.desktop() && !Dom.hasClass(document.documentElement, "desktop")) {
            Dom.addClass(document.documentElement, "desktop")
        }

        this.touchScreen = undefined
        this.ieVersion = undefined
        this.positionSticky = undefined
    }

    isTouchScreen(): boolean {
        if (_.isUndefined(this.touchScreen)) {
            this.touchScreen =
                this.isSmallScreen() ||
                !!window.ontouchstart ||
                window.navigator.maxTouchPoints > 0 ||
                (window.navigator as any).msMaxTouchPoints > 0
        }

        return this.touchScreen
    }

    getIEVersion(): number | false {
        if (_.isUndefined(this.ieVersion)) {
            const ua = window.navigator.userAgent

            const msie = ua.indexOf("MSIE ")
            const trident = ua.indexOf("Trident/")
            const edge = ua.indexOf("Edge/")

            if (msie > 0) {
                // IE 10 or older => return version number
                this.ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
            } else if (trident > 0) {
                // IE 11 => return version number
                const rv = ua.indexOf("rv:")
                this.ieVersion = parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
            } else if (edge > 0) {
                // Edge (IE 12+) => return version number
                this.ieVersion = parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10)
            } else {
                // other browser
                this.ieVersion = false
            }
        }

        return this.ieVersion
    }

    getIOSVersion() {
        const ua = window.navigator.userAgent

        const version = ua.match(/OS ((\d+_?){2,3})\s/)

        if (version) {
            return parseInt(version[1])
        }

        return -1
    }

    isIE(): boolean {
        return this.getIEVersion() !== false
    }

    isEdge(): boolean {
        return this.isIE() && (this.getIEVersion() as number) > 12
    }

    isIOS(): boolean {
        return this.ios
    }

    isFirefoxDesktop(): boolean {
        return navigator.userAgent.toLowerCase().indexOf("firefox") > -1 && !this.isSmallScreen()
    }

    isFirefoxMobile(): boolean {
        return navigator.userAgent.toLowerCase().indexOf("firefox") > -1 && this.isSmallScreen()
    }

    isChrome(): boolean {
        return navigator.userAgent.toLowerCase().indexOf("chrome") > -1
    }

    isChromeOnIOS(): boolean {
        return navigator.userAgent.indexOf("CriOS") > -1
    }

    isMobile(): boolean {
        return this.mobile
    }

    isTablet(): boolean {
        return this.tablet
    }

    isSmallScreen(): boolean {
        return this.mobile || this.tablet
    }

    isDesktop(): boolean {
        return !this.mobile && !this.tablet
    }

    isPortrait(): boolean {
        return this.portrait
    }

    isAndroid(): boolean {
        return this.android
    }

    isMacOs(): boolean {
        return this.macos
    }

    supportPositionSticky(): boolean {
        if (_.isUndefined(this.positionSticky)) {
            const el = document.createElement("A"),
                mStyle = el.style
            mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;"

            this.positionSticky = mStyle.position.includes("sticky")
        }

        return this.positionSticky
    }

    hasWebRTC(): boolean {
        return !!(
            window.RTCPeerConnection &&
            (navigator.mediaDevices?.getUserMedia ||
                (navigator as any).getUserMedia ||
                (navigator as any).webkitGetUserMedia ||
                (navigator as any).mozGetUserMedia)
        )
    }
}

export default new DeviceUtilities()
