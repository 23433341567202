import React from "react"

import InlineInfoMessage from "common/components/inlineInfoMessage/InlineInfoMessage"
import I18n from "common/services/i18n/I18n"

import "./statusPane.less"

export enum STATUS {
    PENDING = "pending",
    SUCCESS = "success",
    ERROR = "error",
}

export interface IStatusPaneWithoutStatusProps {
    title?: string
    successText?: string
    errorText?: string
    onClose: () => boolean | void
    buttonLabel?: string
    additionalButton?: { label: string; onClick: () => boolean | void }
}

interface IStatusPaneProps extends IStatusPaneWithoutStatusProps {
    status: STATUS
}

const StatusPane: React.FunctionComponent<IStatusPaneProps> = ({
    status,
    title,
    successText,
    errorText,
    onClose,
    buttonLabel,
    additionalButton,
}) => {
    const statusTile =
        title ||
        (status === STATUS.SUCCESS
            ? I18n.getString("common.global.thanks")
            : I18n.getString("common.global.oops"))
    const statusMessage =
        status === STATUS.SUCCESS
            ? successText
            : errorText || I18n.getString("common.message.error")

    return (
        <InlineInfoMessage
            className="statusPane"
            title={statusTile}
            message={statusMessage}
            buttonLabel={buttonLabel}
            onClose={onClose}
            additionalButton={status === STATUS.SUCCESS ? additionalButton : undefined}
        />
    )
}

interface IStatusPaneErrorProps {
    errorText?: string
    onClose: () => boolean | void
}

export const StatusPaneError: React.FunctionComponent<IStatusPaneErrorProps> = ({
    errorText,
    onClose,
}) => <StatusPane status={STATUS.ERROR} errorText={errorText} onClose={onClose} />

export default StatusPane
