import React from "react"

import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import I18n from "common/services/i18n/I18n"

import "./backButtonCardHeader.less"

interface IBackButtonCardHeaderProps {
    title?: string
    onClick(): void
}

const BackButtonCardHeader: React.FunctionComponent<IBackButtonCardHeaderProps> = ({
    title,
    onClick,
}) => {
    return (
        <div className="backButtonCardHeader" onClick={onClick}>
            <Icon id={IconList.navi_back_sm} iconClassName="common-icon--small" />

            <span className="label--strong">{title ?? I18n.getString("common.global.back")}</span>
        </div>
    )
}

export default BackButtonCardHeader
