import React from "react"

import { bemBlock } from "common/utils/Bem"

import "./dialogBoxParagraphs.less"

interface IDialogBoxText {
    text: string
    infoTitle?: string
    emphasized?: boolean
}

export interface IDialogBoxParagraphsProps {
    paragraph: Array<IDialogBoxText>
}

const bem = bemBlock("dialogBoxParagraphs")

const DialogBoxParagraphs: React.FunctionComponent<IDialogBoxParagraphsProps> = ({ paragraph }) => {
    return (
        <div className={bem()}>
            {paragraph.map((p, i) => (
                <p
                    key={i}
                    className={bem("paragraph", { emphasized: p.emphasized }, [
                        "paragraph--normal",
                    ])}
                >
                    {p.infoTitle ? (
                        <>
                            <span className={bem("infoTitle")}>{p.infoTitle} </span>
                            <span className={bem("infoText", ["subtitle--normal"])}>{p.text}</span>
                        </>
                    ) : (
                        <>{p.text}</>
                    )}
                </p>
            ))}
        </div>
    )
}

export default DialogBoxParagraphs
