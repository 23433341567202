import React from "react"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { IStepperProps } from "@/components/stepper/Stepper"
import { AbstractCard } from "@/screens/cards/AbstractCard"
import { I18n } from "@/services/i18n/I18n"
import { IHelpDialog } from "@/store/actions/ModalActions"

import TextField from "common/components/formV2/field/text/TextField"
import Form from "common/components/formV2/Form"

import "./chooseNameCard.less"

interface IChooseNameCardState {
    value: string
    hasErrors: boolean
}

interface IChooseNameCardProps {
    stepperProps: IStepperProps
    goBackToOffers: () => void
    onNameSelected: (name: string, hasError?: boolean) => void
}

const NAME_PATTERN = "^[a-zA-Z0-9-_]+$"

export class ChooseNameCard extends AbstractCard<IChooseNameCardProps, IChooseNameCardState> {
    private helpDialog: IHelpDialog
    constructor(props: IChooseNameCardProps) {
        super(props)

        this.helpDialog = {
            title: I18n.getString("card.chooseName.help.title"),
            message: I18n.getString("card.chooseName.help.message"),
        }

        this.state = {
            value: "",
            hasErrors: false,
        }
    }

    static get title(): string {
        return I18n.getString("card.chooseName.stepper.title")
    }

    isNameValid = (value: string): boolean => {
        return !value || new RegExp(NAME_PATTERN).test(value)
    }

    onNameSelected = (): void => {
        const { onNameSelected } = this.props
        const { value, hasErrors } = this.state

        value && !hasErrors && onNameSelected(value)
    }

    onChange = (value: string): void => {
        this.setState({ value, hasErrors: !this.isNameValid(value) })
    }

    // TODO: add portal help (question mark)
    render() {
        const { stepperProps, goBackToOffers } = this.props
        const { value, hasErrors } = this.state

        const customErrorMessage = hasErrors
            ? I18n.getString("card.chooseName.error.message")
            : undefined

        return (
            <PortalCard
                stepperProps={stepperProps}
                buttons={{
                    onPrevious: goBackToOffers,
                    onNext: this.onNameSelected,
                    onNextDisabled: !value || hasErrors,
                }}
                helpDialog={this.helpDialog}
                isMaxWidth
            >
                <div className="chooseNameCard">
                    <div className="chooseNameCard__title">
                        <h2 className="title--strong">{I18n.getString("card.chooseName.title")}</h2>
                        <p className="chooseNameCard__subTitle subtitle--weak">
                            {I18n.getString("card.chooseName.subTitle")}
                        </p>
                    </div>
                    <div className="chooseNameCard__input">
                        <Form
                            onSubmit={this.onNameSelected}
                            hasCustomErrors={hasErrors}
                            forceShowError
                        >
                            <TextField
                                value={value}
                                label={I18n.getString("card.chooseName.input.title")}
                                placeholder={I18n.getString("card.chooseName.input.placeholder")}
                                customErrorMessage={customErrorMessage}
                                onChange={this.onChange}
                                autoFocus
                            />
                        </Form>
                    </div>
                </div>
            </PortalCard>
        )
    }
}
