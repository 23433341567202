import PortalConfig from "@/services/config/PortalConfig"

declare let Keycloak: any

class KeycloakClient {
    init(): Promise<boolean> {
        const { keycloakJsUrl, keycloakConfig } = PortalConfig.config

        return new Promise((resolve, reject) => {
            const script = document.createElement("SCRIPT")
            script.setAttribute("src", keycloakJsUrl)
            script.onload = () => {
                window.keycloak = new Keycloak(keycloakConfig)
                window.keycloak.init({ onLoad: keycloakConfig.mode }).then(resolve).catch(reject)
            }

            document.head.appendChild(script)
        })
    }
}

export default new KeycloakClient()
