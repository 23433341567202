import React from "react"

import { I18n } from "@/services/i18n/I18n"
import IconList from "@/styles/IconList"

import ContextMenuContainer from "common/components/contextMenu/ContextMenuContainer"
import Icon from "common/components/icon/Icon"
import { BasicMenuItem } from "common/components/menu/Menu"
import { OFFER_GROUPS_IDS } from "common/types/OfferTypes"
import { classNames } from "common/utils/JSX"

import "./offersCardHeader.less"

interface IOffersCardHeaderProps {
    currentGroupOffersId: OFFER_GROUPS_IDS
    switchOffersGroup: (id: OFFER_GROUPS_IDS) => void
    onChangeTaxes: (withoutTaxes: boolean) => void
    withoutTaxes: boolean
}

const OffersCardHeader: React.FunctionComponent<IOffersCardHeaderProps> = ({
    currentGroupOffersId,
    switchOffersGroup,
    onChangeTaxes,
    withoutTaxes,
}) => {
    const offersTypeButtonList: { id: OFFER_GROUPS_IDS; title: string }[] = [
        {
            id: OFFER_GROUPS_IDS.individual,
            title: I18n.getString("card.offers.tabs.individual"),
        },
        {
            id: OFFER_GROUPS_IDS.business,
            title: I18n.getString("card.offers.tabs.business"),
        },
    ]

    return (
        <div className="offersCardHeader">
            <div className="offersSwitch">
                {offersTypeButtonList.map((offersTypeButton) => (
                    <button
                        key={offersTypeButton.id}
                        {...classNames(
                            "offersSwitch__button offersSwitch__button--large label--strong",
                            {
                                "offersSwitch__button--active":
                                    currentGroupOffersId === offersTypeButton.id,
                            },
                        )}
                        onClick={() => switchOffersGroup(offersTypeButton.id)}
                    >
                        {offersTypeButton.title}
                    </button>
                ))}

                <ContextMenuContainer
                    trigger={{
                        element: (
                            <button
                                className="offersSwitch__button offersSwitch__button--square"
                                data-testid="offersSwitch__button"
                            >
                                <Icon
                                    iconClassName={"offersSwitch__icon"}
                                    id={IconList.navi_more_sm}
                                />
                            </button>
                        ),
                        activeClassName: "offersSwitch__button--active",
                    }}
                >
                    <BasicMenuItem
                        highlighted={withoutTaxes}
                        label={I18n.getString("card.offers.switch.withoutTaxes")}
                        onClick={() => onChangeTaxes(true)}
                    />
                    <BasicMenuItem
                        highlighted={!withoutTaxes}
                        label={I18n.getString("card.offers.switch.withTaxes")}
                        onClick={() => onChangeTaxes(false)}
                    />
                </ContextMenuContainer>
            </div>
            <p className="offersCard__subTitle subtitle--weak">
                {I18n.getString("card.offers.subTitle")}
            </p>
        </div>
    )
}
export default OffersCardHeader
