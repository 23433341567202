import React from "react"

import ProfilePicture, {
    PROFILE_PICTURE_SIZE,
} from "common/components/profilePicture/ProfilePicture"
import I18n from "common/services/i18n/I18n"

import "./connectedUserCard.less"

export interface IConnectedUserCardProps {
    userId: string
    displayName: string
}

const ConnectedUserCard: React.FunctionComponent<IConnectedUserCardProps> = ({
    userId,
    displayName,
}) => {
    return (
        <div className="connectedUserCard">
            <div className="connectedUserCard__profilePicture">
                <ProfilePicture userId={userId} size={PROFILE_PICTURE_SIZE.lg} />
            </div>
            <div className="connectedUserCard__userName">
                <span className="connectedUserCard__userLabel label--weakest">
                    {I18n.getString("common.card.user.label")}
                </span>
                <span className="connectedUserCard__userLabel label--strongest">{displayName}</span>
            </div>
        </div>
    )
}

export default ConnectedUserCard
