import React from "react"

import { STEPPER_CIRCLE_CONFIG } from "@/components/stepper/StepperConfig"
import { I18n } from "@/services/i18n/I18n"

import "./stepper.less"

export interface IStepperProps {
    currentStep: number
    totalSteps: number
    currentStepTitle?: string
    nextStepTitle?: string
    optionalStep?: boolean
}

const Stepper: React.FunctionComponent<IStepperProps> = ({
    currentStep,
    totalSteps,
    currentStepTitle,
    nextStepTitle,
    optionalStep,
}) => {
    const { radius, stroke, normalizedRadius, circumference } = STEPPER_CIRCLE_CONFIG

    const progress = (currentStep / totalSteps) * 100
    const fractionalStep = `${currentStep} / ${totalSteps}`

    const strokeDashoffset = circumference - (progress / 100) * circumference
    const optionalStrokeDashoffset = (circumference - strokeDashoffset) / 2 + strokeDashoffset

    const stepperSize = radius * 2

    return (
        <div className="stepper" data-testid="stepper">
            <div
                className="stepper__progressRing"
                style={{ width: stepperSize, height: stepperSize }}
            >
                <div className="stepper__svg">
                    <svg height={stepperSize} width={stepperSize} viewBox="0 0 40 40">
                        <circle
                            className="stepper__circle stepper__circle--primary"
                            strokeWidth={stroke * 0.9}
                            r={normalizedRadius}
                            cx={radius}
                            cy={radius}
                        />
                        <circle
                            className="stepper__circle stepper__circle--secondary"
                            strokeWidth={stroke}
                            strokeDasharray={`${circumference} ${circumference}`}
                            style={{ strokeDashoffset, opacity: optionalStep ? 0.5 : 1 }}
                            r={normalizedRadius}
                            cx={radius}
                            cy={radius}
                            data-testid="stepperCircle"
                        />
                        {optionalStep && (
                            <circle
                                className="stepper__circle stepper__circle--secondary"
                                strokeWidth={stroke}
                                strokeDasharray={`${circumference} ${circumference}`}
                                style={{ strokeDashoffset: optionalStrokeDashoffset }}
                                r={normalizedRadius}
                                cx={radius}
                                cy={radius}
                            />
                        )}
                    </svg>
                </div>
                <div className="stepper__fraction">
                    <span className="stepper__fractionText">{fractionalStep}</span>
                </div>
            </div>

            <div className="stepper__titles">
                <div className="stepper__mainTitle label--strongest">{currentStepTitle}</div>
                {nextStepTitle && (
                    <div className="stepper__subTitle label--weakest" data-testid="stepperSubtitle">
                        {I18n.getString("common.stepper.nextStep", { nextStep: nextStepTitle })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Stepper
