export enum FEATURE_ID {
    USER_SLOTS = "userSlots",
    TOTAL_FILE_SIZE_IN_GB = "totalFileSizeInGB",
    OFFICE_ALLOWED = "officeAllowed",
    EXTERNAL_CONTRIBUTORS = "externalContributors",
    VIEWS = "views",
    ANALYTICS_AND_REPORTING = "analyticsAndReporting",
    ALL_FILES_TYPE = "allFileType",
    BASIC_FILES_TYPE = "basicFilesType", // Front value only
}

type IFeatureOrder = FEATURE_ID[]
export const FEATURE_ORDER: IFeatureOrder = [
    FEATURE_ID.TOTAL_FILE_SIZE_IN_GB,
    FEATURE_ID.USER_SLOTS,
    FEATURE_ID.VIEWS,
    FEATURE_ID.BASIC_FILES_TYPE,
    FEATURE_ID.OFFICE_ALLOWED,
    FEATURE_ID.ALL_FILES_TYPE,
    FEATURE_ID.ANALYTICS_AND_REPORTING,
    FEATURE_ID.EXTERNAL_CONTRIBUTORS,
]

type IFeatureIgnoredWhenFalse = FEATURE_ID[]
export const FEATURE_IGNORED_WHEN_FALSE: IFeatureIgnoredWhenFalse = [
    FEATURE_ID.OFFICE_ALLOWED,
    FEATURE_ID.ALL_FILES_TYPE,
    FEATURE_ID.ANALYTICS_AND_REPORTING,
]

type IFeatureWithNullValuePossible = FEATURE_ID[]
export const FEATURE_WITH_NULL_VALUE_POSSIBLE: IFeatureWithNullValuePossible = [FEATURE_ID.VIEWS]

export type IFeatureWithQuotas = [
    FEATURE_ID.USER_SLOTS,
    FEATURE_ID.TOTAL_FILE_SIZE_IN_GB,
    FEATURE_ID.VIEWS,
]
export const FEATURE_WITH_QUOTAS: IFeatureWithQuotas = [
    FEATURE_ID.USER_SLOTS,
    FEATURE_ID.TOTAL_FILE_SIZE_IN_GB,
    FEATURE_ID.VIEWS,
]

export const MAX_OPTION_QTY_VALUE = 999
