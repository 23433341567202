import { cookUserProfile } from "common/data/userProfile/UserProfileCooked"
import { IUserProfileCooked, IUserProfileRaw } from "common/data/userProfile/UserProfileTypes"
import EngineFacade from "common/services/engine/EngineFacade"
import PersistenceFacade from "common/services/persistence/PersistenceFacade"
import { AuthenticatedHttp } from "common/utils/Http"
import Url from "common/utils/Url"

const REFRESH_TOKEN_MARGIN_IN_SEC = 5

class AuthFacade {
    private initialUserProfile: null | IUserProfileCooked
    async initialize() {
        this.initialUserProfile = null
        this._bindKeycloakEvents()
    }

    async initializeUser() {
        // initialUserProfile can only be used during init (RgpdFacade, store, etc...)
        if (this.isAuthenticated()) {
            const userProfileRaw = await this.fetchUserProfile()
            this.initialUserProfile = cookUserProfile(userProfileRaw)
        }
    }

    _bindKeycloakEvents() {
        window.keycloak.onAuthLogout = () => window.location.reload()
        window.keycloak.onAuthRefreshError = () => window.location.reload()
    }

    getInitialUserProfile() {
        return this.initialUserProfile || null
    }

    isAuthenticated(): boolean {
        return window.keycloak?.authenticated || false // TODO: fix tests to remove useless "?."
    }

    getUserId(): string | undefined {
        return this.isAuthenticated() ? window.keycloak.tokenParsed?.sub : undefined
    }

    async fetchUserProfile(): Promise<IUserProfileRaw> {
        this.initialUserProfile = null // initialUserProfile is invalidated on the first userProfile refresh

        const url = Url.join(
            EngineFacade.rootUserProfileUrl || EngineFacade.rootLibraryApiUrl,
            "userprofile",
        )
        return AuthenticatedHttp.get(url)
    }

    async forceRefreshToken() {
        await window.keycloak.updateToken(-1)
    }

    async refreshToken() {
        await window.keycloak.updateToken(REFRESH_TOKEN_MARGIN_IN_SEC)
    }

    async getToken(): Promise<string> {
        if (this.isAuthenticated()) {
            await this.refreshToken()
            return `Bearer ${window.keycloak.token}`
            // should we do something when refresh token error ?
            // handled by window.keycloak.onAuthRefreshError !
        }
        return ""
    }

    getTokenSync(): string {
        return this.isAuthenticated() ? `Bearer ${window.keycloak.token}` : ""
    }

    login(noIdpHint = false, redirectUri: string | null = null, loginHint = "") {
        redirectUri = redirectUri ? redirectUri : window.location.href
        const idpHint = noIdpHint ? "" : EngineFacade.keycloakConfig.idpHint
        window.keycloak.login({ idpHint, redirectUri, loginHint })
    }

    async loginAction({
        maxAgeSec,
        action,
        prompt,
    }: {
        maxAgeSec?: number
        action?: string
        prompt?: "none" | "login" | "consent"
    }) {
        return window.keycloak.login({
            action,
            maxAge: maxAgeSec,
            prompt: prompt,
        })
    }

    register(noIdpHint = false, redirectUri: string | null = null) {
        redirectUri = redirectUri ? redirectUri : location.href
        const idpHint = noIdpHint ? "" : EngineFacade.keycloakConfig.idpHint

        idpHint ? this.login() : window.keycloak.register({ idpHint, redirectUri })
    }

    hasIdentityProvider(): boolean {
        return !!window.keycloak.tokenParsed?.$idp || false
    }

    logout(redirectUri: string | undefined = undefined) {
        this.deleteTracking()
        window.keycloak.logout({ redirectUri })
    }

    deleteTracking() {
        PersistenceFacade.sessionStorage!.tracking.delete()
    }
}

export default new AuthFacade()
