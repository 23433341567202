import { I18n } from "@/services/i18n/I18n"

import { getMessageInParagraph } from "common/components/dialogBox/DialogBoxHelper"
import { DIALOG_BOX_ICON_BY_TYPE } from "common/components/dialogBox/DialogBoxTypes"
import { hideActiveModal, showModal } from "common/state/actions/ModalActions"
import { IDispatch } from "common/state/StoreTypes"

export interface IHelpDialog {
    title: string
    message: string
}

export const showHelpDialog = (helpDialog: IHelpDialog) => (dispatch: IDispatch) => {
    dispatch(
        showModal(
            "dialogBox",
            {
                icon: DIALOG_BOX_ICON_BY_TYPE.confirm,
                title: helpDialog.title,
                children: getMessageInParagraph(helpDialog.message),
                mainButton: {
                    label: I18n.getString("common.global.close"),
                    action: () => {
                        return dispatch(hideActiveModal())
                    },
                },
            },
            { preventCloseOnClickCover: true },
        ),
    )
}
