type IModifiers = { [key: string]: boolean | undefined | null }

type IExtraClasses = Array<string | null | undefined>

type IBlockArgs =
    | [string]
    | [string, IModifiers]
    | [string, IExtraClasses]
    | [string, IModifiers, IExtraClasses]

type IElementArgs =
    | [string, string]
    | [string, string, IModifiers]
    | [string, string, IExtraClasses]
    | [string, string, IModifiers, IExtraClasses]

const getModifiersClasses = (baseName: string, modifiers: IModifiers): string =>
    Object.entries(modifiers).reduce(
        (res, [key, value]) => (value ? `${res} ${baseName}--${key}` : res),
        "",
    )

const getExtraClasses = (classes: IExtraClasses): string => {
    const filteredClasses = classes.filter((c) => ![undefined, null, ""].includes(c))
    return filteredClasses.length > 0 ? ` ${filteredClasses.join(" ")}` : ""
}

/**
 * Examples (read the tests to learn more):
 * Block => bem("blockClassName")                                       // "blockClassName"
 * Block => bem("blockClassName", {disabled: true})                     // "blockClassName blockClassName--disabled"
 * Block => bem("blockClassName", {disabled: true}, ["toto"])           // "blockClassName blockClassName--disabled toto"
 *
 * Element => bem("blockClassName", "el")                               // "blockClassName__el"
 * Element => bem("blockClassName", "el", {disabled: true})             // "blockClassName__el blockClassName__el--disabled"
 * Element => bem("blockClassName", "el", {disabled: true}, , ["toto"]) // "blockClassName__el blockClassName__el--disabled toto"
 */
export const bem = (...args: IBlockArgs | IElementArgs): string => {
    let baseName = args[0]
    let modifiers = "",
        extra = ""

    const parseArg = (argIndex: number) => {
        const arg = args[argIndex]
        if (Array.isArray(arg)) {
            extra = getExtraClasses(arg)
        } else if (typeof arg === "object") {
            modifiers = getModifiersClasses(baseName, arg)
        }
    }

    // The second arg determines if it's either a block declaration or an element declaration
    if (typeof args[1] === "string") {
        baseName = `${baseName}__${args[1]}`
    } else {
        parseArg(1)
    }

    parseArg(2)
    parseArg(3)

    return `${baseName}${modifiers}${extra}`
}

type IBemBlockArgs = [IModifiers] | [IExtraClasses] | [IModifiers, IExtraClasses] | IBlockArgs

/**
 * Same as bem except that you don't need to set the block every time.
 * Need to be initialised with the main block.
 *
 * Example :
 * const bem = bemBlock("blockClassName")
 * bem()                                    // "blockClassName"
 * bem({disabled: true})                    // "blockClassName blockClassName--disabled"
 * bem("el")                                // "blockClassName__el"
 * bem("el", {disabled: true})              // "blockClassName__el blockClassName__el--disabled"
 */
export const bemBlock =
    (blockName: string) =>
    (...args: IBemBlockArgs | []) => {
        return bem(blockName, ...args)
    }
