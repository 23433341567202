interface IStandalonePageTemplateParams {
    backgroundUrl: string
    logoUrl: string
    pageName: string
    title?: string
    message?: string
    buttonLabel?: string
}

const getButton = (label: string): string => `
    <div class="standalonePage__buttonContainer">
        <button class="standalonePage__button">${label}</button>
    </div>
`

export default ({
    backgroundUrl,
    logoUrl,
    pageName,
    title,
    message,
    buttonLabel,
}: IStandalonePageTemplateParams): string => `
<div
    id="standalonePage"
    style="visibility: hidden; background: center / cover no-repeat url('${backgroundUrl}')"
>
    <div class="standalonePage__header">
        <div class="standalonePage__logoContainer">
            <img src="${logoUrl}" class="standalonePage__logo" />
        </div>
    </div>

    <div class="standalonePage__body">
        <div class="standalonePage__card">
            <div class="standalonePage__cardHeader">
                <h2>${pageName}</h2>
            </div>

            <div class="standalonePage__cardContent">
                <div class="standalonePage__message">
                    ${title ? `<h1>${title}</h1>` : ""}

                    <p>${message}</p>
                </div>

                ${buttonLabel ? getButton(buttonLabel) : ""}
            </div>
        </div>
    </div>
</div>`
