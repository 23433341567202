import React, { useState } from "react"

import SelectField, { ISelectFieldOption } from "common/components/formV2/field/select/SelectField"

export interface IDialogBoxSelectProps {
    items: ISelectFieldOption[]
    initialSelectedItemId: string
    onChange: (selectedValue: string | undefined) => void
    withMargin?: true
}

const DialogBoxSelect: React.FunctionComponent<IDialogBoxSelectProps> = ({
    items,
    initialSelectedItemId,
    onChange,
    withMargin,
}) => {
    const [selectedItemId, setSelectedItemId] = useState(initialSelectedItemId)

    return (
        <SelectField
            value={selectedItemId}
            groups={[{ items }]}
            onChange={(selectedValue) => {
                setSelectedItemId(selectedValue)
                onChange?.(selectedValue)
            }}
            withMargin={withMargin}
        />
    )
}

export default DialogBoxSelect
