import { RefObject, useEffect, useRef } from "react"

const DEFAULT_MAX_LINES = 10
const DEFAULT_PADDING = 0

export const useTextAreaAutoGrowing = (
    inputRef: RefObject<HTMLTextAreaElement>,
    inputValue: string | undefined,
    params?: { totalVerticalPadding?: number; maxLines?: number; disabled?: boolean },
): void => {
    const maxHeight = useRef<number>()

    useEffect(() => {
        const input = inputRef.current
        if (params?.disabled || !input) return

        input.style.height = "auto"
        input.style.minHeight = "auto"

        if (!maxHeight.current) {
            const maxLines = params?.maxLines ?? DEFAULT_MAX_LINES
            const padding = params?.totalVerticalPadding ?? DEFAULT_PADDING
            const lineHeight = input.clientHeight - padding
            maxHeight.current = lineHeight * maxLines + padding
        }

        input.style.overflowY = input.scrollHeight > maxHeight.current ? "auto" : "hidden"
        input.style.height = `${Math.min(input.scrollHeight, maxHeight.current)}px`
    }, [inputValue, params?.disabled, params?.totalVerticalPadding, params?.maxLines, inputRef])
}

export const useInputAutoGrowing = (label: string = "") => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const canvas = document.createElement("canvas")
        const context = canvas.getContext("2d")

        if (inputRef.current && context) {
            context.font = window.getComputedStyle(inputRef.current).font
            const width = context?.measureText(inputRef.current.value).width ?? 0
            inputRef.current.style.width = `${Math.ceil(width)}px`
        }
    }, [label])

    return inputRef
}
