import _ from "lodash"

import MalformedPersistenceValueError from "common/errors/MalformedPersistenceValueError"

const VALUE_TYPES = {
    STRING: "STRING",
    NUMBER: "NUMBER",
    ARRAY: "ARRAY",
    BOOLEAN: "BOOLEAN",
    OBJECT: "OBJECT",
}

const encodeValue = (type, value) => {
    switch (type) {
        case VALUE_TYPES.ARRAY:
        case VALUE_TYPES.OBJECT:
            return JSON.stringify(value)
        default:
            return value
    }
}

const decodeValue = (type, value) => {
    switch (type) {
        case VALUE_TYPES.NUMBER: {
            const decodedValueNumber = _.isNumber(value) ? value : +value
            if (!_.isNumber(decodedValueNumber)) {
                throw new MalformedPersistenceValueError()
            }
            return decodedValueNumber
        }
        case VALUE_TYPES.ARRAY: {
            const decodedValueArray = _.isArray(value) ? value : JSON.parse(value)
            if (!_.isArray(decodedValueArray)) {
                throw new MalformedPersistenceValueError()
            }
            return decodedValueArray
        }
        case VALUE_TYPES.OBJECT: {
            const decodedValueObject = _.isObject(value) ? value : JSON.parse(value)
            if (!_.isObject(decodedValueObject)) {
                throw new MalformedPersistenceValueError()
            }
            return decodedValueObject
        }
        case VALUE_TYPES.BOOLEAN: {
            const decodedValueBoolean = !_.includes([false, "false", "", "0"], value)
            if (!_.isBoolean(decodedValueBoolean)) {
                throw new MalformedPersistenceValueError()
            }
            return decodedValueBoolean
        }
        default:
            if (!_.isString(value)) {
                throw new MalformedPersistenceValueError()
            }
            return value
    }
}

const getSpecificMethods = ({ get, set }, type) => {
    return (
        {
            [VALUE_TYPES.STRING]: {
                isEqual: (string) => get() === string,
            },
            [VALUE_TYPES.NUMBER]: {},
            [VALUE_TYPES.BOOLEAN]: {},
            [VALUE_TYPES.ARRAY]: {
                push: (element) => set([...get(), element]),
                concat: (elements) => set(get().concat(elements)),
                includes: (element) => get().includes(element),
            },
        }[type] || {}
    )
}

export default _.merge(VALUE_TYPES, {
    encodeValue,
    decodeValue,
    getSpecificMethods,
})
