import React from "react"
import _ from "lodash"

import { IAccessibleAttributes } from "common/components/menu/components/menuItem/MenuItemTypes"
import { classNames } from "common/utils/JSX"

import MenuItem from "./MenuItem"

interface ICustomMenuItemProps {
    className?: string
    highlighted?: boolean
    dataTestId?: string
    onMouseEnter?: React.MouseEventHandler
    onMouseLeave?: React.MouseEventHandler
    withHover?: boolean
    noClose?: boolean
    onClick?(): void
    accessibleAttributes?: IAccessibleAttributes
}

const CustomMenuItem: React.FunctionComponent<ICustomMenuItemProps> = ({
    className,
    highlighted,
    dataTestId,
    onMouseEnter,
    onMouseLeave,
    withHover,
    children,
    noClose,
    onClick,
    accessibleAttributes,
}) => (
    <MenuItem
        {...classNames("menuItem--custom", className)}
        {...{
            highlighted,
            dataTestId,
            onMouseEnter,
            onMouseLeave,
            withHover,
            onClick,
            accessibleAttributes: _.assign(
                {
                    role: "button",
                    tabIndex: 0,
                    "aria-pressed": highlighted,
                },
                accessibleAttributes,
            ),
        }}
    >
        {children}
    </MenuItem>
)

export default CustomMenuItem
