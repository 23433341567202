import React from "react"
import _ from "lodash"

import {
    FEATURE_ID,
    FEATURE_IGNORED_WHEN_FALSE,
    FEATURE_ORDER,
    FEATURE_WITH_NULL_VALUE_POSSIBLE,
} from "common/components/detailedOffer/DETAILED_OFFER"
import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import I18n from "common/services/i18n/I18n"
import { IOfferFeature, IOfferFeatureValue, IOfferOptions } from "common/types/OfferTypes"

import "./featureList.less"

export type IComputeValue = (featureId: FEATURE_ID, value: IOfferFeatureValue) => IOfferFeatureValue

interface IFeatureListProps {
    featureList: IOfferFeature[]
    addIcon?: boolean
    withBottomMargin?: boolean
    addPlusPrefix?: boolean
    computeValue?: IComputeValue
    showOnlyEditableFeatures?: boolean
    options?: IOfferOptions[]
    featureAddedByFrontAllowed?: boolean
}

const getFeatureSpan = (
    featureId: FEATURE_ID,
    hasBoldClass: boolean = false,
    hasBoldExtension: boolean = false,
): React.ReactElement<{}, "span"> => {
    const classNameBold = hasBoldClass ? "label--strong" : ""
    return (
        <span className={classNameBold}>
            {I18n.getString(`common.offer.feature.${featureId}${hasBoldExtension ? ".bold" : ""}`)}
        </span>
    )
}

const getFeatureLabel = (
    featureId: FEATURE_ID,
    value: IOfferFeatureValue,
    addPlusPrefix: boolean = false,
): React.ReactNode => {
    let boldLabel = ""
    let simpleLabel = ""
    const plusPrefixTxt = addPlusPrefix ? "+ " : ""
    switch (featureId) {
        case FEATURE_ID.USER_SLOTS: {
            boldLabel = value!.toString()
            simpleLabel = I18n.getString(
                `common.offer.feature.${FEATURE_ID.USER_SLOTS}${
                    (value as number) > 1 ? "_plur" : ""
                }`,
            )
            return (
                <>
                    <span className="label--strong">{plusPrefixTxt + boldLabel}</span>{" "}
                    <span>{simpleLabel}</span>
                </>
            )
        }
        case FEATURE_ID.TOTAL_FILE_SIZE_IN_GB: {
            const valueAsNumber = value as number
            const unit =
                valueAsNumber >= 1000
                    ? I18n.getString("common.unit.tera")
                    : I18n.getString("common.unit.giga")
            const displayValue = valueAsNumber >= 1000 ? valueAsNumber / 1000 : valueAsNumber
            return (
                <>
                    <span className="label--strong">
                        {plusPrefixTxt + displayValue + " " + unit}
                    </span>{" "}
                    {getFeatureSpan(FEATURE_ID.TOTAL_FILE_SIZE_IN_GB)}
                </>
            )
        }
        case FEATURE_ID.OFFICE_ALLOWED:
            return getFeatureSpan(FEATURE_ID.OFFICE_ALLOWED, true)
        case FEATURE_ID.EXTERNAL_CONTRIBUTORS:
            return (
                <>
                    {getFeatureSpan(FEATURE_ID.EXTERNAL_CONTRIBUTORS)}{" "}
                    {getFeatureSpan(FEATURE_ID.EXTERNAL_CONTRIBUTORS, true, true)}
                </>
            )
        case FEATURE_ID.VIEWS:
            if (_.isNumber(value)) {
                boldLabel = value.toString()
                simpleLabel = I18n.getString(
                    `common.offer.feature.${FEATURE_ID.VIEWS}${value > 1 ? "_plur" : ""}`,
                )
                return (
                    <>
                        <span className="label--strong">{boldLabel}</span>{" "}
                        <span>{simpleLabel}</span>
                    </>
                )
            } else {
                return (
                    <>
                        <span className="label--strong">
                            {I18n.getString(`common.offer.feature.${FEATURE_ID.VIEWS}.unlimited`)}
                        </span>
                    </>
                )
            }
        case FEATURE_ID.ANALYTICS_AND_REPORTING:
            return getFeatureSpan(FEATURE_ID.ANALYTICS_AND_REPORTING, true)
        case FEATURE_ID.ALL_FILES_TYPE:
            return getFeatureSpan(FEATURE_ID.ALL_FILES_TYPE, true)
        case FEATURE_ID.BASIC_FILES_TYPE:
            return getFeatureSpan(FEATURE_ID.BASIC_FILES_TYPE, true)
    }
}

const isFeatureDisplayable = (
    feature: IOfferFeature,
    showOnlyEditableFeatures: boolean,
    options?: IOfferOptions[],
) => {
    let valueIsAcceptable = false

    if (feature.value === null && FEATURE_WITH_NULL_VALUE_POSSIBLE.includes(feature.featureId)) {
        valueIsAcceptable = true
    } else if (feature.value === true) {
        valueIsAcceptable = true
    } else if (feature.value === false && !FEATURE_IGNORED_WHEN_FALSE.includes(feature.featureId)) {
        valueIsAcceptable = true
    } else if (_.isNumber(feature.value) && feature.value !== 0) {
        valueIsAcceptable = true
    }

    let forceDisplayByOptions = false

    if (showOnlyEditableFeatures) {
        options?.forEach((option) => {
            const sameFeatureInOptions = _.find(
                option?.features,
                (featureInOption) => featureInOption.featureId === feature.featureId,
            )

            if (sameFeatureInOptions?.display) {
                forceDisplayByOptions = true
            }
        })
    }

    return (
        valueIsAcceptable &&
        _.includes(FEATURE_ID, feature.featureId) &&
        ((!showOnlyEditableFeatures && feature.display) ||
            (showOnlyEditableFeatures && forceDisplayByOptions))
    )
}

const formatFeatureList = (
    featureList: IOfferFeature[],
    featureAddedByFrontAllowed: boolean,
    showOnlyEditableFeatures: boolean,
    options?: IOfferOptions[],
): IOfferFeature[] => {
    // OFFICE_ALLOWED AND ALL_FILE_TYPE are two features but for same option

    const findFeatures = (
        featureList: IOfferFeature[],
        featureIds: FEATURE_ID[],
    ): (IOfferFeature | undefined)[] => {
        let remainingFeatureIds = featureIds
        const result: (IOfferFeature | undefined)[] = _.times(
            featureIds.length,
            _.constant(undefined),
        )
        _.forEach(featureList, (feature) => {
            remainingFeatureIds.forEach((featureId) => {
                if (feature.featureId === featureId) {
                    result[featureIds.indexOf(featureId)] = feature
                    remainingFeatureIds = _.without(remainingFeatureIds, featureId)
                }
            })
            return remainingFeatureIds.length > 0
        })
        return result
    }

    const [officeAllowed, allFilesType, basicFilesType] = findFeatures(featureList, [
        FEATURE_ID.OFFICE_ALLOWED,
        FEATURE_ID.ALL_FILES_TYPE,
        FEATURE_ID.BASIC_FILES_TYPE,
    ])

    // File type managed by FRONT
    if (officeAllowed && _.isString(officeAllowed.value)) {
        officeAllowed.value = true
    }

    if (officeAllowed?.value && allFilesType?.value === true) {
        officeAllowed.value = false
    } else if (
        featureAddedByFrontAllowed &&
        (!officeAllowed || officeAllowed.value === false) &&
        (!allFilesType || allFilesType?.value === false)
    ) {
        basicFilesType
            ? (basicFilesType.value = true)
            : featureList.push({
                  featureId: FEATURE_ID.BASIC_FILES_TYPE,
                  value: true,
                  display: true,
              })
    }

    return featureList
        .sort((feature1: IOfferFeature, feature2: IOfferFeature) =>
            FEATURE_ORDER.indexOf(feature1.featureId) < FEATURE_ORDER.indexOf(feature2.featureId)
                ? -1
                : 1,
        )
        .filter((feature: IOfferFeature) =>
            isFeatureDisplayable(feature, showOnlyEditableFeatures, options),
        )
}

const FeatureList: React.FunctionComponent<IFeatureListProps> = ({
    featureList,
    addIcon = false,
    withBottomMargin = false,
    addPlusPrefix = false,
    computeValue,
    featureAddedByFrontAllowed = true,
    showOnlyEditableFeatures = false,
    options,
}) => (
    <div className="featureList">
        {_.map(
            formatFeatureList(
                featureList,
                featureAddedByFrontAllowed,
                showOnlyEditableFeatures,
                options,
            ),
            (feature: IOfferFeature) => (
                <div
                    className={`featureList__item ${
                        withBottomMargin ? "featureList__item--margin" : ""
                    } label--normal`}
                    key={feature.featureId}
                >
                    {addIcon && <Icon id={IconList.info_success_sm} dataTestId="featureIcon" />}
                    <span className="featureList__txt label--normal" data-testid="featureTxt">
                        {getFeatureLabel(
                            feature.featureId,
                            computeValue?.(feature.featureId, feature.value) || feature.value,
                            addPlusPrefix,
                        )}
                    </span>
                </div>
            ),
        )}
    </div>
)

export default FeatureList
