import React from "react"

import { PortalCard } from "@/components/portalCard/PortalCard"
import { I18n } from "@/services/i18n/I18n"

import Button, { ACTION_TYPE, BUTTON_TAG } from "common/components/button/Button"
import ConnectedUserCard from "common/components/connectedUserCard/ConnectedUserCard"
import { IUserProfileCooked } from "common/data/userProfile/UserProfileTypes"

import "./userCard.less"

interface IUserCardProps {
    userProfile: IUserProfileCooked
    continueWithAccount: () => void
    useAnotherAccount: () => void
}

export const UserCard: React.FunctionComponent<IUserCardProps> = ({
    userProfile,
    continueWithAccount,
    useAnotherAccount,
}) => {
    return (
        <PortalCard mainTitle={I18n.getString("card.user.title")} isMaxWidth>
            <div className={"userCard"}>
                <ConnectedUserCard
                    userId={userProfile.userId}
                    displayName={userProfile.displayName}
                />
                <div className={"userCard__buttons"}>
                    <Button
                        className={"userCard__button"}
                        onClick={continueWithAccount}
                        label={I18n.getString("card.user.button.continueWithAccount")}
                        actionType={ACTION_TYPE.primary}
                        tag={BUTTON_TAG.success}
                        tabIndex={1}
                    />
                    <Button
                        className={"userCard__button"}
                        onClick={useAnotherAccount}
                        label={I18n.getString("card.user.button.useAnotherAccount")}
                        actionType={ACTION_TYPE.secondary}
                        tabIndex={2}
                    />
                </div>
            </div>
        </PortalCard>
    )
}
