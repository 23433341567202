import React from "react"

import { classNames } from "common/utils/JSX"

import MenuSeparator from "../menuSeparator/MenuSeparator"

import "./menuContainer.less"

const getFlattenChildren = (
    children: (React.ReactElement | null)[],
): {
    flattenChildren: (React.ReactElement | null)[]
    firstFirstIndex?: number
    lastChildIndex?: number
} => {
    let childIndex = 0
    let firstFirstIndex: undefined | number = undefined
    let lastChildIndex: undefined | number = undefined

    const flatChildrenRecursively = (children: (React.ReactElement | null)[]) => {
        let flattenChildren: (React.ReactElement | null)[] = []

        React.Children.forEach(children, (child) => {
            if (child) {
                if (firstFirstIndex === undefined) {
                    firstFirstIndex = flattenChildren.length
                }

                lastChildIndex = childIndex
            }

            if (child && child.type === React.Fragment) {
                flattenChildren = flattenChildren.concat(
                    flatChildrenRecursively(child.props.children),
                )
            } else {
                flattenChildren.push(child)
                childIndex++
            }
        })

        return flattenChildren
    }

    return { flattenChildren: flatChildrenRecursively(children), firstFirstIndex, lastChildIndex }
}

const getUnwantedSeparatorIndexes = (
    flattenChildren: (React.ReactElement | null)[],
    firstFirstIndex?: number,
    lastChildIndex?: number,
) => {
    const badSeparatorIndexes: number[] = []

    let hasSeparatorBefore = false
    let lastBadSeparatorIndex = -1

    flattenChildren.forEach((child, index) => {
        if (child && child.type === MenuSeparator) {
            if (index === firstFirstIndex || index === lastChildIndex || hasSeparatorBefore) {
                badSeparatorIndexes.push(index)
            } else {
                lastBadSeparatorIndex = index
            }

            hasSeparatorBefore = true
        } else if (child) {
            lastBadSeparatorIndex = -1
            hasSeparatorBefore = false
        }
    })

    if (lastBadSeparatorIndex !== -1) {
        badSeparatorIndexes.push(lastBadSeparatorIndex)
    }

    return badSeparatorIndexes
}

const removeUnwantedSeparators = (
    children: (React.ReactElement | null)[],
    unwantedSeparatorIndexes: number[],
) => {
    let i = -1

    const removeSeparatorsRecursively = (children: (React.ReactElement | null)[]) => {
        return React.Children.map(children, (child) => {
            i++

            if (child && child.type === React.Fragment) {
                i--
                return React.cloneElement(
                    child,
                    child.props,
                    removeSeparatorsRecursively(child.props.children),
                )
            } else if (
                child &&
                child.type === MenuSeparator &&
                unwantedSeparatorIndexes.includes(i)
            ) {
                return null
            } else {
                return child
            }
        })
    }

    return removeSeparatorsRecursively(children)
}

// remove separator at the beginning/end of the menu + remove duplicated separator
export const getFilteredChildren = (children: (React.ReactElement | null)[]) => {
    const { flattenChildren, firstFirstIndex, lastChildIndex } = getFlattenChildren(children)
    const unwantedSeparatorIndexes = getUnwantedSeparatorIndexes(
        flattenChildren,
        firstFirstIndex,
        lastChildIndex,
    )
    return removeUnwantedSeparators(children, unwantedSeparatorIndexes)
}

interface IMenuContainerProps {
    isReversed?: boolean
    className?: string
}

const MenuContainer: React.FunctionComponent<IMenuContainerProps> = ({
    children,
    className,
    isReversed = false,
}) => {
    const filteredChildren = getFilteredChildren(children as React.ReactElement[])
    return (
        <div {...classNames("menuContainer", className, { "menuContainer--reverse": isReversed })}>
            {filteredChildren}
        </div>
    )
}

export default MenuContainer
