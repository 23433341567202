import React from "react"

import SelectField from "common/components/formV2/field/select/SelectField"
import I18n from "common/services/i18n/I18n"

import "./chooseOffer.less"

export type IOnOfferChange = (id: string) => void
export type IOfferLabel = { id: string; label: string }

interface IChooseOfferProps {
    offersLabel: IOfferLabel[]
    initialOfferIndex: number
    onChange: IOnOfferChange
}

const ChooseOffer: React.FunctionComponent<IChooseOfferProps> = ({
    offersLabel,
    initialOfferIndex,
    onChange,
}) => (
    <div className="chooseOffer">
        <div className="chooseOffer__title label--strongest">
            {I18n.getString("common.offer.chooseOffer")}
        </div>

        <div className="chooseOffer__list">
            <SelectField
                value={offersLabel[initialOfferIndex].id}
                groups={[{ items: offersLabel }]}
                required={true}
                onChange={onChange}
            />
        </div>
    </div>
)

export default ChooseOffer
