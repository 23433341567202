import React from "react"
import { Provider } from "react-redux"

import { Portal } from "@/screens/portal/Portal"
import { IExistingSelfManagedHub } from "@/services/api/PortalAPI"
import { store } from "@/store/Store"

import ModalRouter from "common/components/modal/modalRouter/ModalRouter"
import { IOffer } from "common/types/OfferTypes"

interface IAppProps {
    onError: (err: any) => void
    offers: IOffer[]
    userMyUbList: IExistingSelfManagedHub[]
}

export const App: React.FunctionComponent<IAppProps> = ({ onError, offers, userMyUbList }) => {
    return (
        <Provider store={store}>
            <Portal onError={onError} offers={offers} userMyUbList={userMyUbList} />
            <ModalRouter />
        </Provider>
    )
}
