import { Store } from "redux"

import CommonRootReducer from "common/state/reducers/RootReducer"
import CommonDefaultState from "common/state/store/DefaultState"
import CommonStore from "common/state/store/Store"

export let store: Store
export const initialize = () => {
    const defaultState = CommonDefaultState()
    const rootReducer = (state: any = defaultState, action: any) => CommonRootReducer(state, action)

    store = CommonStore.createStore(rootReducer)
}
