import React from "react"

import Checkbox from "common/components/formV2/other/checkbox/Checkbox"
import Toggle from "common/components/formV2/other/toggle/Toggle"
import MenuItem from "common/components/menu/components/menuItem/MenuItem"

interface IInteractiveMenuItemProps {
    label: string
    value: boolean
    disabled?: boolean
    toggleButton?: boolean
    highlighted?: boolean
    dataTestId?: string
    onMouseEnter?: React.MouseEventHandler
    onMouseLeave?: React.MouseEventHandler
    noClose?: boolean
    onClick?(): void
}

const InteractiveMenuItem: React.FunctionComponent<IInteractiveMenuItemProps> = ({
    label,
    disabled,
    toggleButton,
    value,
    highlighted,
    dataTestId,
    onMouseEnter,
    onMouseLeave,
    noClose,
    onClick,
}) => {
    return (
        <MenuItem
            className="menuItem--interactive"
            {...{
                disabled,
                highlighted,
                dataTestId,
                onClick,
                onMouseEnter,
                onMouseLeave,
                withHover: true,
                accessibleAttributes: {
                    role: toggleButton ? "switch" : "checkbox",
                    tabIndex: 0,
                    "aria-checked": value,
                },
            }}
        >
            {toggleButton ? (
                <Toggle toggled={value} label={label} disabled={disabled} focusDisabled />
            ) : (
                <Checkbox checked={value} label={label} disabled={disabled} focusDisabled />
            )}
        </MenuItem>
    )
}

export default InteractiveMenuItem
