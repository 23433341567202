export default {
    read(key) {
        try {
            if (_.keys(window.localStorage).indexOf(key) !== -1) {
                return window.localStorage.getItem(key)
            } else {
                return undefined
            }
        } catch (e) {
            console.error("Local storage not available")
            return undefined
        }
    },
    write(key, value) {
        try {
            if (_.isUndefined(value)) {
                window.localStorage.removeItem(key)
            } else {
                window.localStorage.setItem(key, value)
            }
        } catch (e) {
            console.error("Local storage not available")
        }
    },
    clear() {
        try {
            window.localStorage.clear()
        } catch (e) {
            console.error("Local storage not available")
        }
    },
}
