import React, { useRef, useState } from "react"

import { IButtonWithMenuProps } from "common/components/entityBlock/buttonWithMenu/ButtonWithMenu"
import EntityBlockImage from "common/components/entityBlock/components/image/EntityBlockImage"
import Icon from "common/components/icon/Icon"
import IconList from "common/components/icon/IconList"
import ProfilePicture from "common/components/profilePicture/ProfilePicture"
import { DOCUMENT_TYPE, ENTITIES, ENTITIES_ICONS, MEDIA_TYPE } from "common/constants/Entities"
import { useInputAutoGrowing } from "common/hooks/InputHooks"
import { bem } from "common/utils/Bem"
import { classNames } from "common/utils/JSX"

import "./entityBlock.less"

export interface IEntityBlockLine {
    label?: string
    labelIconBefore?: string
    labelIcon?: string
    path?: string[]
    title?: string
    fontStyle?: string // to replace default font type (ex: label-strong)
    fontColorAlt?: boolean // to replace default color (alt or not)
    placeholder?: boolean // ugly grey block
    placeholderText?: string // real placeholder
    className?: string
    input?: IEntityBlockLineInput
    permanentIcon?: boolean
}

type IEntityBlockLineInput = {
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export interface IEntityBlockPictureProps {
    icon?: {
        iconId?: string
        entity?: ENTITIES | DOCUMENT_TYPE
        className?: string
        square?: boolean
    }
    mediaType?: string
    userId?: string
    thumbnail?: {
        url: string
        width: number
        imgAlt?: string
        rounded?: boolean
    }
}

export interface IEntityBlockProps extends IEntityBlockPictureProps {
    firstLine?: IEntityBlockLine | string
    secondLine?: IEntityBlockLine | string
    children?: React.FunctionComponentElement<IButtonWithMenuProps> // use children instead of a menu props for better TS support
    onClick?: () => void
    allowClickInMenu?: boolean
    disabled?: boolean
    lowOpacity?: boolean
    dataTestId?: string
    isSelected?: boolean
    noPadding?: boolean
    truncateLineLabels?: boolean
}

const getEntityIconId = (
    entity?: ENTITIES | DOCUMENT_TYPE,
    mediaType?: string,
): IconList | undefined =>
    mediaType === MEDIA_TYPE.BEEVIRTUA
        ? ENTITIES_ICONS[mediaType]
        : entity
          ? ENTITIES_ICONS[entity]
          : undefined

const EntityBlock: React.FunctionComponent<IEntityBlockProps> = ({
    icon,
    userId,
    thumbnail,
    mediaType,
    firstLine,
    secondLine,
    children: button,
    onClick,
    allowClickInMenu,
    disabled,
    lowOpacity,
    dataTestId,
    isSelected,
    noPadding,
    truncateLineLabels,
}) => {
    const entityBlockRef = useRef<HTMLDivElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const [isFirstLineInputFocused, setIsFirstLineInputFocused] = useState<boolean>(false)
    const [isSecondLineInputFocused, setIsSecondLineInputFocused] = useState<boolean>(false)

    const computedIconId = icon?.iconId || getEntityIconId(icon?.entity, mediaType)
    const computedMediaType = icon ? icon.entity : mediaType

    const onQuickAction: React.MouseEventHandler | undefined = onClick
        ? (event) => {
              const target = event.target as HTMLElement
              if (
                  entityBlockRef.current!.contains(target) &&
                  (allowClickInMenu || !menuRef.current?.contains(target))
              ) {
                  onClick()
              }
          }
        : undefined

    const computeLine = (line: string | IEntityBlockLine = "") => {
        return typeof line === "string" ? { label: line } : line
    }

    const firstLineRef = useInputAutoGrowing(computeLine(firstLine).label)
    const secondLineRef = useInputAutoGrowing(computeLine(secondLine).label)

    const setFirstLineFocus = () => {
        if (firstLineRef.current) {
            firstLineRef.current.focus()
        }
    }

    const setSecondLineFocus = () => {
        if (secondLineRef.current) {
            secondLineRef.current.focus()
        }
    }
    const [hiddenEditIcon, setHiddenEditIcon] = useState<boolean>(false)

    const renderLine = (
        _line: IEntityBlockLine | string,
        isFirstLine: boolean,
    ): React.ReactNode => {
        const defaultFontStyle = isFirstLine ? "label--strong" : "label--normal"
        const defaultFontColorAlt = !isFirstLine

        const line = computeLine(_line)

        if (
            (!line.path ? !line.label : line.path.length === 0) &&
            !line.placeholder &&
            !line.placeholderText
        ) {
            return null
        }

        return (
            <div className="entityBlock__line">
                {line.placeholder ? (
                    <div
                        {...classNames("entityBlock__linePlaceholder", {
                            "entityBlock__linePlaceholder--lighter": !isFirstLine,
                        })}
                    />
                ) : (
                    <>
                        {line.labelIconBefore && (
                            <div className="entityBlock__lineLabelIcon entityBlock__lineLabelIcon--before">
                                <Icon id={line.labelIconBefore} iconClassName={line.className} />
                            </div>
                        )}
                        <div
                            {...classNames(
                                "entityBlock__label",
                                line.className,
                                line.fontStyle || defaultFontStyle,
                                {
                                    "entityBlock__label--label":
                                        !line.path && line.label && !truncateLineLabels,
                                    "entityBlock__label--alt":
                                        line.fontColorAlt ?? defaultFontColorAlt,
                                    "entityBlock__label--truncated": truncateLineLabels,
                                    "entityBlock__label--inputFocused": isFirstLine
                                        ? isFirstLineInputFocused
                                        : isSecondLineInputFocused,
                                },
                            )}
                            title={line.title}
                        >
                            {line.input ? (
                                <>
                                    <input
                                        ref={isFirstLine ? firstLineRef : secondLineRef}
                                        className="entityBlock__input"
                                        placeholder={line.placeholderText}
                                        type="text"
                                        enterKeyHint={"done"}
                                        value={line.label}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                event.currentTarget.blur()
                                            }
                                        }}
                                        onBlur={(event) => {
                                            line.input?.onBlur?.(event)
                                            isFirstLine
                                                ? setIsFirstLineInputFocused(false)
                                                : setIsSecondLineInputFocused(false)
                                            setHiddenEditIcon(false)
                                        }}
                                        title={line.label}
                                        onFocus={(e) => {
                                            isFirstLine
                                                ? setIsFirstLineInputFocused(true)
                                                : setIsSecondLineInputFocused(true)
                                            e.target.select()
                                            setHiddenEditIcon(true)
                                        }}
                                        onChange={(event) => {
                                            line.input?.onChange?.(event)
                                        }}
                                    />
                                    <Icon
                                        iconClassName={bem(
                                            "entityBlock",
                                            hiddenEditIcon ? "editIconPermanentHidden" : "editIcon",
                                            {
                                                permanent: line.permanentIcon,
                                            },
                                        )}
                                        id={IconList.func_edit_sm}
                                        onClick={
                                            isFirstLine ? setFirstLineFocus : setSecondLineFocus
                                        }
                                    />
                                </>
                            ) : (
                                line.path?.map((pathLabel, index) => (
                                    <React.Fragment key={index}>
                                        {pathLabel}
                                        {index < line.path!.length - 1 && <> &#9656; </>}
                                    </React.Fragment>
                                )) || line.label
                            )}
                        </div>
                        {line.labelIcon && (
                            <div className="entityBlock__lineLabelIcon">
                                <Icon id={line.labelIcon} />
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    const renderPicture = (): React.ReactNode => {
        if (computedIconId || userId) {
            return (
                <div
                    {...classNames("entityBlock__picture", {
                        "entityBlock__picture--withIcon": computedIconId,
                        [`entityBlock__picture--${computedMediaType}`]: computedMediaType,
                        "entityBlock__picture--withSquareBackground": !!icon?.square,
                    })}
                >
                    {computedIconId ? (
                        <Icon id={computedIconId} iconClassName={icon?.className} />
                    ) : (
                        <ProfilePicture userId={userId!} />
                    )}
                </div>
            )
        } else if (thumbnail) {
            const style = { width: `${thumbnail.width}px` }
            return (
                <div
                    {...classNames("entityBlock__picture", {
                        "entityBlock__picture--withThumbnail": !thumbnail.rounded,
                        "entityBlock__picture--withRoundedThumbnail": thumbnail.rounded,
                    })}
                    style={style}
                >
                    <EntityBlockImage url={thumbnail.url} imgAlt={thumbnail.imgAlt} />
                </div>
            )
        }
    }

    const renderButton = (): React.ReactNode => {
        if (disabled) {
            return React.cloneElement(React.Children.only(button!), { disabled: true })
        } else {
            return button!
        }
    }

    return (
        <div
            ref={entityBlockRef}
            {...classNames("entityBlock", {
                "entityBlock--withQuickAction": onQuickAction,
                "entityBlock--disabled": disabled,
                "entityBlock--lowOpacity": lowOpacity,
                "entityBlock--selected": isSelected,
                "entityBlock--noPadding": noPadding,
            })}
            onClick={onQuickAction}
            data-testid={dataTestId}
        >
            {renderPicture()}

            <div className="entityBlock__mainInfo">
                {firstLine && renderLine(firstLine, true)}
                {secondLine && renderLine(secondLine, false)}
            </div>

            {button && (
                <div ref={menuRef} className="entityBlock__button">
                    {renderButton()}
                </div>
            )}
        </div>
    )
}

export default EntityBlock
