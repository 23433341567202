import _ from "lodash"

import {
    IAcknowledgementsByLib,
    IUserProfileCooked,
    IUserProfileRaw,
} from "common/data/userProfile/UserProfileTypes"
import { getUserPictureUrl } from "common/services/backApi/UserProfileAPI"
import { RGPD_ITEMS_BY_FEATURE_IDS } from "common/services/rgpd/RgpdItems"

const _filterOldAcknowledgements = (
    acknowledgements: IAcknowledgementsByLib[],
): IAcknowledgementsByLib[] => {
    return acknowledgements.map((libAck) => ({
        ...libAck,
        libAcknowledgements: libAck.libAcknowledgements.filter(
            (ack) => !!RGPD_ITEMS_BY_FEATURE_IDS[ack.feature],
        ),
    }))
}

export const cookUserProfile = (userProfileRaw: IUserProfileRaw): IUserProfileCooked => {
    const {
        userId,
        firstName,
        lastName,
        username,
        email,
        picture: pictureResourceId,
        librariesWithAccessPermission = [],
        librariesWithAtLeastOneCollectionWithEditPermission = [],
        personalLibrary,
        acknowledgements = [],
        subscriptions = [],
        periodical = [],
        userSettings = {},
        externalIdentity,
    } = userProfileRaw

    const fullName = firstName && lastName ? `${firstName} ${lastName}` : ""

    const address = _.merge(
        { country: "", address: "", zipCode: "", city: "", company: "", phoneNumber: "" },
        userProfileRaw.address,
    )

    return {
        userId,
        email,
        username,
        firstName: firstName || "",
        lastName: lastName || "",
        fullName,
        displayName: fullName || username,
        address,
        pictureUrl: getUserPictureUrl(userId, pictureResourceId),
        librariesWithAccessPermission,
        librariesWithAtLeastOneCollectionWithEditPermission,
        personalLibrary,
        acknowledgements: _filterOldAcknowledgements(acknowledgements),
        subscriptions,
        periodical,
        userSettings,
        externalIdentity,
    }
}
