import DialogBox from "common/components/dialogBox/DialogBox"
import { MODAL_CONTAINER_SIZE } from "common/components/modal/modalContainer/ModalContainerTypes"
import { IModalComponentDefinitions } from "common/components/modal/modalRouter/ModalRouterTypes"

export const COMMON_COMPONENTS_DEFINITION: IModalComponentDefinitions = {
    dialogBox: {
        size: MODAL_CONTAINER_SIZE.SMALL,
        component: DialogBox,
    },
}
