import { ENTITY_TYPE } from "common/constants/Entities"

export type IAccessibleResourceType =
    | ENTITY_TYPE.LIBRARY_COLLECTION
    | ENTITY_TYPE.MEDIA
    | ENTITY_TYPE.PRINTER
export type IResourceType = ENTITY_TYPE.ORGANISATION | ENTITY_TYPE.LIBRARY | IAccessibleResourceType

export type IViewOrMediaResourceType = ENTITY_TYPE.LIBRARY_COLLECTION | ENTITY_TYPE.MEDIA

export const SYSTEM_USER_IDS = ["system", "anonymous", "unknown"]

export enum AUTHZ_UPDATE_STRATEGY_IDS {
    PROPAGATE = "propagate",
    PROPAGATE_WITH_UNION = "propagate_with_union",
    PROPAGATE_AND_OVERWRITE = "propagate_and_overwrite",
    DO_NOT_PROPAGATE = "do_not_propagate",
}

export enum MOVE_RESOURCE_AUTHORIZATION_UPDATE_STRATEGY {
    KEEP_ORIGIN = "keep_origin",
    APPLY_DESTINATION = "apply_destination",
    UNION = "union",
}

export interface IAuthzPolicyItemRuleParams {
    startAt?: string
    endAt?: string
}

export type IAuthzPolicyItemAndRule = { $and: IAuthzPolicyItemRule[] }
export type IAuthzPolicyItemOrRule = { $or: IAuthzPolicyItemRule[] }
export type IAuthzPolicyItemRule =
    | IAuthzPolicyItemRuleParams
    | IAuthzPolicyItemAndRule
    | IAuthzPolicyItemOrRule

// INPUT
export interface IAuthzPolicyItemScopes {
    scopes: string[]
}

export interface IAuthzPolicyItem extends IAuthzPolicyItemScopes {
    rule?: IAuthzPolicyItemRule
}

export interface IAuthzPolicyItems {
    items: IAuthzPolicyItem[]
}

export interface IAuthzMandatoryPolicyItem extends IAuthzPolicyItem {
    visible: boolean
    inherited: boolean
    mandatory: boolean
}

export interface IAuthzMandatoryPolicyItems {
    items: IAuthzMandatoryPolicyItem[]
}

type IAuthzEverybodyPolicy = IAuthzPolicyItems

interface IAuthzOrganizationPolicy extends IAuthzPolicyItems {
    orgId: string
    orgDisplayName: string
    stopPropagation?: true
}

export interface IAuthzGroupPolicy extends IAuthzMandatoryPolicyItems {
    id: string
    name: string
    displayName: string
}

interface IAuthzOwnerPolicy extends IAuthzPolicyItemScopes {
    id: string
    name: string
    displayName: string
    email?: string
}

export interface IAuthzUserPolicy extends IAuthzMandatoryPolicyItems {
    id: string
    email: string
    username: string
    displayName: string
    belongToOrg?: boolean
    belongToGroups?: string[]
}

export interface IPolicyScope {
    name: string
    title: string
    description: string
    dependencies: {
        name: string
    }[]
}

export interface IAuthzSchema {
    type: string
    title: string
    description: string
    properties: {
        scopes: {
            type: string
            title: string
            items: IPolicyScope[]
        }
    }
}

export interface IUpdatedBy {
    userDisplayName: string
    isCurrentUser: boolean
}

export interface IAuthz {
    id: string
    hash: string
    type: IResourceType
    owner: IAuthzOwnerPolicy
    everybody: IAuthzEverybodyPolicy
    organization: IAuthzOrganizationPolicy
    groups: IAuthzGroupPolicy[]
    users: IAuthzUserPolicy[]
    parents?: string[]
    $schema: IAuthzSchema
    $updatedAt: string
    $updatedBy: IUpdatedBy
    grantedScopes: {
        default: string[]
        everybody: string[]
    }
}

// OUTPUT
export interface IAuthzPolicyItemScopesParams {
    scopes: string[]
}

export interface IAuthzPolicyItemParams extends IAuthzPolicyItemScopesParams {
    rule?: IAuthzPolicyItemRule
}

interface IAuthzPolicyItemsParams {
    items: IAuthzPolicyItemParams[]
}

export interface IAuthzMandatoryPolicyItemParams extends IAuthzPolicyItemParams {
    mandatory: boolean
    visible: boolean
}

interface IAuthzMandatoryPolicyItemsParams {
    items: IAuthzMandatoryPolicyItemParams[]
}

export type IAuthzEverybodyPolicyParams = IAuthzPolicyItemsParams

export interface IAuthzOrganizationPolicyParams extends IAuthzPolicyItemsParams {
    orgId: string
    stopPropagation?: true
}

export interface IAuthzGroupPolicyParams extends IAuthzMandatoryPolicyItemsParams {
    id: string
}

export interface IAuthzUserPolicyParams extends IAuthzMandatoryPolicyItemsParams {
    id: string
}

export interface IAuthzParams {
    hash: string
    id: string
    type: string
    everybody: IAuthzEverybodyPolicyParams
    organization: IAuthzOrganizationPolicyParams
    users: IAuthzUserPolicyParams[]
    groups: IAuthzGroupPolicyParams[]
}

export const READ_PRESET_ALIAS = "__read__"
export const DOWNLOAD_PRESET_ALIAS = "__download__"

export interface IAuthzEverybody {
    isPublic: boolean
    isPubliclyDownloadable: boolean
}
